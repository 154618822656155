import React, { useContext, useEffect, useRef, useState } from "react";
import api from "api";
import { Modal, Button } from "react-bootstrap";
import { AppContext } from "context/app-context";
import ImagePlaceHolder from "components/ImagePlaceHolder";
import InputField from "components/InputField/InputField";
import { SPECIMEN_CONTAINER_TYPE } from "constant";
import { capitalizeLetter } from "../../../utils";
import { CONFIG } from "constant";
import Loader from "components/Loader/Loader";

const SpecimanContainerModal = ({ handleClose, handleSaveChange, handelUpdate, selectedValue, modalTitle }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileRef = useRef(null);
  const appContext = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState({});

  const [specimenImage, setSpecimenImage] = useState("");

  useEffect(async () => {
    if (selectedValue) {
      setOption(selectedValue);
      try {
        if (selectedValue.image) {
          const imageToRender = await api.getImageFile(selectedValue.image);
          setSpecimenImage(imageToRender);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [selectedValue]);
  const handleFileChange = () => {
    const file = fileRef.current.files[0];
    if (
      !file.name.toLowerCase().endsWith(".jpg") &&
      !file.name.toLowerCase().endsWith(".jpeg") &&
      !file.name.toLowerCase().endsWith(".png")
    ) {
      appContext.showErrorMessage("Please upload a JPEG file");
      return;
    }
    setSelectedFile(file);
    const previewURL = URL.createObjectURL(file);
    setSpecimenImage(previewURL);
  };
  const handleSave = async () => {
    setLoading(true);
    if (!option.name) {
      appContext.showErrorMessage("Enter Container Name");
      setLoading(false);

      return;
    }
    if (!option.type) {
      appContext.showErrorMessage("Enter Container Type");
      setLoading(false);

      return;
    }
    try {
      let imagePath;
      if (selectedFile) {
        imagePath = await api.saveImageFile(CONFIG.clientID, selectedFile);
      }
      handleSaveChange({
        ...option,
        image: imagePath || selectedValue?.image || "",
        name: capitalizeLetter(option.name),
        type: capitalizeLetter(option.type),
      });
    } catch (err) {
      console.log("Error in Uploading image:", err);
    }
    setLoading(false);
  };
  const onRemoveImage = async () => {
    if (!selectedValue.image) {
      setSpecimenImage(null);
      return;
    }
    await api.deleteImageFile(selectedValue.image);
    setSpecimenImage(null);
    handelUpdate({
      ...option,
      image: "",
      name: capitalizeLetter(option.name),
      type: capitalizeLetter(option.type),
    });
  };
  return (
    <>
      <Modal
        show
        animation={true}
        onHide={() => handleClose()}
        backdrop="static"
        centered
        size={"md"}
        keyboard={true}
        className="second-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {modalTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ paddingTop: 0 }}>
          {loading && <Loader />}
          <div className="row mb-3">
            <div className="col-lg-12 col-12 mb-3">
              <label className="modalLineHeaders mt-0">
                Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                value={option.name}
                className="modalInput"
                placeholder="Enter Container Name"
                style={{
                  width: "100%",
                }}
                onChange={(e) => {
                  setOption({ ...option, name: e.target.value });
                }}
              />

              <div className="col-lg-12 col-12 mt-3">
                <label>
                  Type<span style={{ color: "red" }}>*</span>
                </label>
                <InputField
                  type="dropDown"
                  groupWrapper="w-100"
                  options={SPECIMEN_CONTAINER_TYPE}
                  labelStyle="text-capitalize"
                  value={SPECIMEN_CONTAINER_TYPE.find((opt) => opt.value === option.type) || null}
                  index="Name"
                  isClearable={true}
                  placeholder="Select Container Type:"
                  handleChange={(selectedOption) => {
                    setOption({ ...option, type: selectedOption?.value });
                  }}
                />
              </div>
              <div className="col-lg-12 col-12 mt-3">
                {specimenImage ? (
                  <div
                    className="w-100 relative"
                    style={{
                      objectFit: "contain",
                      overflow: "hidden",
                      border: "1px solid rgb(73, 70, 70)",
                      borderRadius: "5px",
                    }}
                  >
                    {specimenImage && (
                      <button onClick={() => onRemoveImage()} className="cancel-btn">
                        ×
                      </button>
                    )}
                    <img src={specimenImage} className="w-100" height={300} />
                  </div>
                ) : (
                  <ImagePlaceHolder ref={fileRef} handleFileChange={handleFileChange} />
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="headerButton" onClick={() => handleClose()}>
            Close
          </Button>
          <Button variant="primary" className="headerButton" onClick={handleSave}>
            {selectedValue?.name || selectedValue?.type || selectedValue?.image ? "Update" : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SpecimanContainerModal;
