import api from "api";
import AutoCompleteAddress from "components/AutoCompleteAddress";
import SignaturePad from "components/SignaturePad/SignaturePad";
import fetchSignature from "api";
import FilledCheckbox from "components/CheckBox/FilledCheckBox/FilledCheckBox";
import EmployeeSearchRecord from "components/EmployeeSearchRecord";
import InputField from "components/InputField/InputField";
import Loader from "components/Loader/Loader";
import ErrorMessage from "components/Message/ErrorMessage";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import { v4 as uuidv4 } from "uuid";
import { ORDER } from "constant";
import {
  CONFIG,
  countryListAllIsoData,
  CUSTOM_INS,
  IGNORE_INS,
  INSURANCE_PROVIDER,
  NEW_DISCOVERY_ID,
  stringConstant,
  UN_RESULT,
  SEROCLINIX_ID,
  SEROCLINIX_TEST,
} from "constant";
import countriesOption from "countries";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { userGivenPermission } from "store/features/authentication/authenticationSelectors";
import { providerOptionList } from "store/features/providers/providersSelectors";
import { fetchAllProviders } from "store/features/providers/providersSlice";
import { selectedCompanySetting } from "store/features/companySetting/companySettingSelectors";
import { selectCountryCode } from "store/features/authentication/authenticationSelectors";
import RadioButton from "components/RadioButton/RadioButton";

import { customCloneDeep } from "util/customLodash";
import {
  convertToLower,
  convertToUpper,
  formatPhoneWithOutSpaces,
  formatZip,
  getPhoneNo,
  isValidEmail,
  isValidName,
  isValidPhoneWithCode,
  makeAlphabetInput,
  makeBarcode,
  sortByAlphaOrder,
  toTitleCase,
  validSpace,
  zipCodeValidationUS,
  parseJSONObject,
  getClientSpecificString,
  formatOrder,
  getTestDates,
  SYSTEM_TIME_ZONE,
} from "utils";
import GQAPI from "views/GraphQL/gqApi";
import OrderTestHeader from "views/Order/OrderTestHeader";
import OrderTestItem from "views/Order/OrderTestItem";
import DiagnosisAccordionView from "views/TestTypeSetting/TestDiagnosisView/DiagnosisAccordionView";
import OrderDiagnosisView from "./OrderDiagnosisView";
import { updateOrderData } from "utils";
import { getDiagListFromOrderTest, getTestListFromOrderTest } from "utils";
import { formatTestForOrder } from "utils";
import TabPills from "components/Tabs/TabPills";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import { compareAndReturnNewValues } from "utils";
import { getUpdatedFields } from "utils";
import { ALTA_NEW_ID } from "constant";
import SourceSiteModal from "./SourceSiteModal";
import { selectClient } from "store/features/authentication/authenticationSelectors";
import { hasAtLeastOneDiagnosis } from "utils";
import { LAB_ID } from "../../constant";

const getFormattedEmployeeObject = (record, cCode) => {
  const {
    zip,
    state,
    sex,
    fetchFrom,
    city,
    whiteGlove,
    isVaccinated,
    employeeType,
    street,
    phone_number,
    phoneNumber,
    dob,
    last,
    first,
    firstName,
    lastName,
    email,
    id_number,
    id,
    country,
    countryCode,
    clientID,
    status,
    insurance_name,
    insuranceCompanyCode,
    insurance_number,
  } = record;

  const [ccCode, phno] = getPhoneNo(phone_number || phoneNumber, countryCode);
  const val = countryListAllIsoData.find((f) => f.name === country) || { code: cCode };

  const defaultCountry = {
    CA: "Canada",
    US: "United States",
  };

  const obj = {
    employee_demographics: {
      email: email,
      firstName: first || firstName,
      lastName: last || lastName,
      dob,
      phoneNumber: `${ccCode}${phno}`,
      countryCode: ccCode,
      address: "",
      employeeType: employeeType,
      isVaccinated: isVaccinated || false,
      whiteGlove: whiteGlove,
      city: city,
      country: country || defaultCountry[cCode] || "United States",
      isoCode: val.code || cCode,
      state: state,
      street: street,
      street2: "",
      zip: zip,
      isLucira: false,
      sex: sex,
      testerDes: "",
      idNumber: id_number,
      insurance_name,
      insuranceCompanyCode,
      insurance_number,
      status: status,
    },
    phoneNumber: phone_number || phoneNumber,
    email: email,
    employeeID: CONFIG.clientID === clientID ? id : "",
  };

  return obj;
};

const OrderTestModal = (props) => {
  const { order, handleCloseCreateTest, clients, sites, labs, isNew, users, appContext } = props;
  const providers = useSelector(providerOptionList);
  const dispatch = useDispatch();

  const [showSourceSite, setShowSourceSite] = useState(false);
  const [sourceSiteIndex, setSourceSiteIndex] = useState(null);
  const [orderEmployee, setOrderEmployee] = useState(null);
  const permission = useSelector(userGivenPermission);

  const [selectedReferringProvider, setSelectedReferringProvider] = useState();

  const parseOrder = (cCode) => {
    if (order.isNew) {
      if (order?.selectedPatient) {
        const obj = getFormattedEmployeeObject(order.selectedPatient, cCode);

        const newObj = {
          ...order,
          employee_demographics: obj.employee_demographics,
          email: obj.email,
          phoneNumber: obj.phoneNumber,
          employeeID: obj.employeeID,
          createdAt: moment().format("YYYY-MM-DDTHH:mm"),
          orderDate: moment().format("YYYY-MM-DDTHH:mm"),
          ...(order.selectedPatient?.referringProvider && {
            providerID: order.selectedPatient?.referringProvider,
            providerName: order.selectedPatient?.referringPyhsician,
          }),
        };

        delete newObj.selectedPatient;
        return newObj;
      }

      const getSiteDefaultProvider = providers?.filter((s) => s.associatedClient === order.siteID);

      let providerObject = getSiteDefaultProvider?.length > 0 ? getSiteDefaultProvider[0] : null;

      return {
        ...order,
        ...(providerObject && {
          providerID: providerObject.id,
          providerName: providerObject.name,
        }),
        orderDate: moment().format("YYYY-MM-DDTHH:mm"),
      };
    }

    const { phone_number, phoneNumber, countryCode } = order?.employee_demographics;
    const [ccCode, phno] = getPhoneNo(phone_number || phoneNumber, countryCode);
    const providerID = order?.provider?.ref_id;
    const providerName = order?.provider?.ref_name;
    return {
      ...order,
      ...(providerID && { providerID, providerName }),
      employee_demographics: { ...order?.employee_demographics, phoneNumber: `${ccCode}${phno}` },
    };
  };

  const labCountryCode = useSelector(selectCountryCode);

  const [loading, setLoading] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [showPopOver, setShowPopOver] = useState(false);
  const [selectSearchdata, setSelectSearchData] = useState(null);
  const [newTestCreate, setTestCreate] = useState(parseOrder(labCountryCode || "US"));

  const [show, setShow] = useState("");
  const [error, setError] = useState("");
  const [disableVaccination, setDisabledVaccination] = useState(false);
  const [onSelectedTester, setOnSelectedTester] = useState({});
  const [resultTypes, setResultTypes] = useState([]);
  const childNode = useRef();
  const [lab, setLabs] = useState([]);
  const [clientInsurances, setClientInsurances] = useState(INSURANCE_PROVIDER);
  const [testOptions, setTestOptions] = useState();

  const [showConfirmation, setShowConfirmation] = useState(false);

  const [orderData, setOrderData] = useState({});
  const [ictList, setIctList] = useState(null);
  const [editIndexIct, setEditIndexIct] = useState(null);
  const companySettings = useSelector(selectedCompanySetting);

  const userClientData = useSelector(selectClient);

  // Order Diag Functionalty Start

  const getSite = (siteId, sites) => {
    const siteData = sites?.find((item) => item.id === siteId);
    return siteData?.setting?.tests || null;
  };

  const labClientTest = useMemo(() => getSite(newTestCreate.siteID, sites), [newTestCreate.siteID, sites]);

  const defaultConfig = useMemo(() => {
    return getClientSpecificString();
  }, []);

  const [orderIcdData, setOrderIcdData] = useState([
    {
      title: "A",
      id: 1,
      description: null,
      orderData:
        defaultConfig.isTestCreationFromEmployee && newTestCreate.isNew === true
          ? [
              {
                cpt: "",
                refCharge: "",
                source: "",
                sampleContainer: "",
                test: "",
                unit: "",
              },
            ]
          : [],
    },
    {
      title: "B",
      id: 2,
      description: null,
      orderData: [],
    },
    {
      title: "C",
      id: 3,
      description: null,
      orderData: [],
    },
    {
      title: "D",
      id: 4,
      description: null,
      orderData: [],
    },
    {
      title: "E",
      id: 5,
      description: null,
      orderData: [],
    },
    {
      title: "F",
      id: 6,
      description: null,
      orderData: [],
    },
    {
      title: "G",
      id: 7,
      description: null,
      orderData: [],
    },
    {
      title: "H",
      id: 8,
      description: null,
      orderData: [],
    },
  ]);


  const getOrderPatient = async () => {
    if (!order?.employeeID || orderEmployee) {
      return;
    }

    const dbEmployee = await api.getTestByID(order.employeeID, "employee");
    if (dbEmployee) {
      setOrderEmployee(dbEmployee);
    }
  };

  useEffect(() => {
    const formatedTest = formatTestForOrder(order, orderIcdData);

    setOrderIcdData(formatedTest);
  }, []);

  const getSiteOptions = useMemo(() => {
    if (!newTestCreate?.clientID) return [];
    return sites
      .filter((s) => s.clientID === newTestCreate.clientID)
      .map((l) => {
        return { value: l.id, label: l.name };
      });
  }, [newTestCreate?.clientID]);

  const handeTestFormat = () => {
    const formatedTest = formatTestForOrder(order, orderIcdData);

    setOrderIcdData(formatedTest);
  };

  useEffect(() => {
    const { updatedArray, hasUpdated } = updateOrderData(orderIcdData, defaultConfig.isTestCreationFromEmployee);

    if (hasUpdated) {
      setOrderIcdData(updatedArray);
    }
  }, [orderIcdData]);

  // Order Diag Functionalty End

  const loadDefaultInsurances = async () => {
    try {
      const res = await api.getClientInsurances({ list: "y" });

      setClientInsurances(res);
    } catch (ex) {
      console.log("Ex", ex);
    }
  };

  const loadTestOptions = async () => {
    const options = await api.getTestTypesOptions();
    setTestOptions(options);
  };

  const getProviders = async (test) => {
    dispatch(fetchAllProviders());
  };

  useEffect(() => {
    loadDefaultInsurances();
    loadTestOptions();
    getProviders();
    appContext.resetSites();
    if (!order.isNew) {
      getOrderPatient();
    }
  }, []);

  const getLinkedClientsArray = (linkedClient) => linkedClient?.split(",") || [];

  const getProviderOption = useMemo(() => {
    const allowSites = [...(CONFIG.allowSites || [])];

    if (allowSites && CONFIG.allowSites.length > 0) {
      return providers?.filter(
        (s) =>
          CONFIG.allowSites.includes(s.associatedClient) ||
          allowSites.some((site) => getLinkedClientsArray(s.linkedClient).includes(site))
      );
    }

    if (CONFIG.siteID) {
      return providers?.filter(
        (s) => s.associatedClient === CONFIG.siteID || getLinkedClientsArray(s.linkedClient).includes(CONFIG.siteID)
      );
    }

    const allAssociatedClients = order?.selectedPatient?.provider?.map((p) => p.value) || [];
    const selectedEmployeeClients = orderEmployee?.provider?.map((p) => p.value) || [];

    const clientsToCheck = [...selectedEmployeeClients, ...allAssociatedClients];

    const clientMatchesAllowSites = (s) =>
      allowSites.includes(s.associatedClient) ||
      allowSites.some((site) => getLinkedClientsArray(s.linkedClient).includes(site));

    const clientMatchesSiteID = (s) =>
      s.associatedClient === CONFIG.siteID || getLinkedClientsArray(s.linkedClient).includes(CONFIG.siteID);

    const clientMatchesOrders = (s) =>
      clientsToCheck.includes(s.associatedClient) ||
      clientsToCheck.some((client) => getLinkedClientsArray(s.linkedClient).includes(client));

    if (allowSites.length > 0) {
      return providers?.filter(clientMatchesAllowSites);
    }

    if (CONFIG.siteID) {
      return providers?.filter(clientMatchesSiteID);
    }

    return providers?.filter((s) => clientMatchesSiteID(s) || clientMatchesOrders(s));
  }, [newTestCreate?.siteID, orderEmployee]);

  const getCliaNumber = useMemo(() => {
    if (!companySettings.cliaNumber) return [];
    return companySettings.cliaNumber.map((l) => {
      return { value: l.cliCode, label: l.label || l.cliCode };
    });
  }, [companySettings]);

  useEffect(() => {
    if (appContext?.clients?.length > 0) {
      const client = appContext.clients.find((f) => f.id === CONFIG.clientID);
      if (client && client.resultTypes) {
        setResultTypes(
          sortByAlphaOrder(
            [
              ...(client.resultTypes.results.map((m) => ({ value: m, label: m })) || []),
              { value: UN_RESULT, label: UN_RESULT },
            ],
            "label"
          )
        );
      }
    }
  }, [appContext.clients]);

  const usersData = useMemo(() => {
    if (!users) return;
    return users
      .filter((u) => u.roles?.includes("Tester") || u.roles?.includes("Admin"))
      .map((u) => {
        return {
          value: u.userID,
          label: `${u.firstName} ${u.lastName}`,
          phone_number: u.phone_number?.replace(/ /g, ""),
        };
      });
  }, [users]);

  useEffect(() => {
    let updates = {};

    if (clients.length === 1 && !newTestCreate.clientName) {
      const { id, name } = clients[0];
      updates = {
        ...updates,
        clientName: name,
      };
    }

    if (labs.length === 1 && !newTestCreate.labID) {
      const { id, name } = labs[0];
      updates = {
        ...updates,
        labID: id,
        labName: name,
      };
    }

    if (Object.keys(updates).length > 0) {
      setTestCreate((prev) => ({
        ...prev,
        ...updates,
      }));
    }
  }, [clients, labs, newTestCreate]);

  useEffect(() => {
    if (selectedReferringProvider || order?.selectedPatient?.referringProvider) {
      const providerId = selectedReferringProvider || order?.selectedPatient?.referringProvider;
      const siteID = providers?.find((s) => s.id === providerId)?.associatedClient || "";
      const siteobj = getSiteOptions?.find((f) => f.value === siteID);
      if (siteobj?.value && siteobj?.label) {
        setTestCreate({ ...newTestCreate, siteID: siteobj?.value, site_name: siteobj?.label });
      }
    }
  }, [selectedReferringProvider, order, getSiteOptions]);

  useEffect(() => {
    if (newTestCreate?.test_type) {
      const TEST_TYPE_VALUE = {
        Lucira: "Lucira",
        PCR: "PCR",
        Molecular: "Molecular",
        Rapid_PCR: "Other",
        Rapid_Antigen: "Antigen",
        Rapid_Flu: "RapidFlu",
      };

      let type = TEST_TYPE_VALUE[newTestCreate?.test_type.replace(/ /g, "_")];
      if (type === undefined) {
        type = newTestCreate?.test_type;
      }

      setTestCreate({
        ...newTestCreate,
        test_type: type,
        employee_demographics: {
          ...newTestCreate.employee_demographics,
          country: newTestCreate.employee_demographics?.country
            ? newTestCreate.employee_demographics?.country
            : "United States",
          isoCode: newTestCreate.employee_demographics?.isoCode ? newTestCreate.employee_demographics?.isoCode : "US",
          phoneNumber: formatPhoneWithOutSpaces(
            newTestCreate.employee_demographics.phoneNumber,
            newTestCreate.employee_demographics.countryCode
          ),
        },
      });
    } else {
      setTestCreate({
        ...newTestCreate,
      });
    }
    if (newTestCreate?.tester_name) {
      setOnSelectedTester({ label: newTestCreate?.tester_name, value: newTestCreate?.tester_name });
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShow("");
    }, 4000);
  }, [show]);

  useEffect(() => {
    // setSite(appContext.sites);
    setLabs(appContext.labs);
  }, [appContext.sites, appContext.labs]);

  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setTestCreate({
        ...newTestCreate,
        employee_demographics: { ...newTestCreate.employee_demographics, street: val },
      });
    } else {
      let addressObj = {
        street: val.street,
        country: val.country || "United States",
        city: val.city,
        zip: val.zipcode,
        county: val.county,
        state: val.state,
        isoCode: val.isoCode || val.isCode || "US",
      };
      setTestCreate({
        ...newTestCreate,
        employee_demographics: { ...newTestCreate.employee_demographics, ...addressObj },
      });
    }
  };

  const orderComparison = (ord, list) => {
    const orderDate = ord.createdAt ? moment(ord.createdAt).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
    const matchingRecord = list.find((f) => moment(f.createdAt).format("YYYY-MM-DD") === orderDate);

    if (matchingRecord) {
      const currentTestsToDo = ord.testsToDo || {};
      const currentTests = currentTestsToDo.test || [];

      const existingTestsToDo = matchingRecord.testsToDo || {};
      const existingTestIds = new Set((existingTestsToDo.test || []).filter((t) => !t.isDone).map((t) => t.id));

      return currentTests.some((test) => existingTestIds.has(test.id));
    }

    return false;
  };

  const handlePhoneNo = (e) => {
    setTestCreate({
      ...newTestCreate,
      employee_demographics: { ...newTestCreate.employee_demographics, phoneNumber: e },
      phoneNumber: e,
    });
  };

  const searchPreReg = async (data, type) => {
    if (!data) return;
    setLoading(true);
    let phoneNo = data;
    let countryCode = "";
    if (phoneNo && type === "Phone") {
      const [cc, ph] = getPhoneNo(data);
      phoneNo = ph;
      countryCode = cc;
    }
    let env = CONFIG.isLive ? "remote" : "localhost";

    let uniqueData = [];
    try {
      let siteID = null;

      if (sites.length > 0) {
        siteID = newTestCreate.siteID;
      }

      let record = await api.getPreRegisterData(phoneNo, env, type, countryCode, siteID);

      uniqueData = Array.from(new Map(record.map((item) => [item["id"], item])).values());

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }

    if (uniqueData && uniqueData.length === 1) {
      populate(uniqueData[0]);
      return;
    }
    if (uniqueData && uniqueData.length > 1) {
      setRecordData(
        uniqueData.map((e) => {
          return { ...e, first: toTitleCase(e.first), last: toTitleCase(e.last) };
        })
      );
      setShowPopOver(!showPopOver);
    }
  };

  const populate = (record) => {
    setShowPopOver(false);
    setSelectSearchData(null);
    const { isVaccinated, referringProvider, referringPyhsician } = record;
    if (!selectedReferringProvider) {
      setSelectedReferringProvider(referringProvider);
    }
    const obj = getFormattedEmployeeObject(record);
    setTestCreate({
      ...newTestCreate,
      employee_demographics: obj.employee_demographics,
      email: obj.email,
      phoneNumber: obj.phoneNumber,
      employeeID: obj.employeeID,
      ...(referringProvider && {
        providerID: referringProvider,
        providerName: referringPyhsician,
      }),
    });

    setDisabledVaccination(isVaccinated || false);
  };

  const createTest = async (newTestCreate) => {
    let newTestObj = { ...newTestCreate, createdAt: "" };
    if (newTestCreate.sequenceNo) {
      let sequenceData = await GQAPI.getTestBySequenceNoByAPI(newTestCreate.sequenceNo);
      if (sequenceData == newTestCreate.sequenceNo || sequenceData < newTestCreate.sequenceNo) {
        setError("Sequence No. already used");
        setLoading(false);
        return;
      }
      let barcode = makeBarcode(newTestObj.employee_demographics.idNumber, newTestCreate.sequenceNo);
      newTestObj = { ...newTestObj, barcode };
    }

    // if create new test allocate the new sequenec no
    let newSequenceNo;
    if (!newTestCreate?.sequenceNo) {
      // if bio lab then change the barcode format
      if (CONFIG.isWhiteLabel) {
        let dateOfTest = moment().format("YYMMDD");
        if (newTestObj.createdAt) {
          dateOfTest = moment(newTestObj.createdAt).format("YYMMDD");
        }
        newSequenceNo = await GQAPI.getLabTestAutoNumber(dateOfTest, newTestObj.labID);
      } else {
        newSequenceNo = await GQAPI.getTestAutoNumber();
      }

      const barcode = newSequenceNo.dailyCounter || newSequenceNo.counter;
      newTestObj = { ...newTestObj, sequenceNo: newSequenceNo.counter, barcode };
    }
    // create test
    let testData = {
      ...newTestObj,
      orderID: newTestObj.id,
      submittedByName: appContext?.user?.name,
      createdBy: appContext?.user?.sub,
      tester_name: appContext?.user?.name,
    };

    // Lab Check barcode is active or not
    const checkLab = labs?.find((item) => item.id === testData.labID && item.barCodeProvided);
    if (checkLab && checkLab.id) {
      let barcode = await api.getLabProvidedBarCode(checkLab.id);
      if (barcode) {
        testData = { ...testData, barcode };
      }
    }

    let newTest = await GQAPI.createTest(testData);
    if (JSON.stringify(order.employee_demographics) !== JSON.stringify(newTestCreate.employee_demographics)) {
      await onHandleSave();
    }
    setLoading(false);
    if (newTest) {
      appContext.showSuccessMessage("Test created successfully");
      handleCloseCreateTest();
    } else {
      appContext.showErrorMessage("Test can't be created");
    }
  };

  const getProviderDetails = (obj) => {
    const ref_id = obj.providerID || obj?.provider?.ref_id;

    if (!ref_id) return null;

    const ref_provider = providers.find((p) => p.id === ref_id);

    if (ref_provider) {
      const prevData = parseJSONObject(obj.provider);
      return {
        ...prevData,
        ref_id: ref_id,
        ref_npi: ref_provider.npi,
        ref_name: ref_provider.label,
        ref_firstName: ref_provider.firstName,
        ref_lastName: ref_provider.lastName,
      };
    }

    return obj.provider || null;
  };
  // delete Signatures
  const deleteSignature = useCallback(({ consent, title }) => {
    try {
      api.deleteSignatureFile(consent);
      switch (title) {
        case "Patient Consent":
          setTestCreate((prevTestCreate) => {
            return {
              ...prevTestCreate,
              employee_demographics: {
                ...prevTestCreate?.employee_demographics,
                patientConsent: null,
              },
            };
          });
          break;
        case "Physician Consent":
          setTestCreate((prevTestCreate) => {
            return {
              ...prevTestCreate,
              employee_demographics: {
                ...prevTestCreate?.employee_demographics,
                physicianConsent: null,
              },
            };
          });
          break;
        default:
      }
    } catch (err) {
      appContext.showErrorMessage("Failed to delete the file");
    }
  }, []);
  // trimmed signatures
  const setMatchedConsent = (title, consent) => {
    switch (title) {
      case "Patient Consent":
        setTestCreate((prevTestCreate) => {
          return {
            ...prevTestCreate,
            employee_demographics: {
              ...prevTestCreate?.employee_demographics,
              patientConsent: consent,
            },
          };
        });
        break;
      case "Physician Consent":
        setTestCreate((prevTestCreate) => {
          return {
            ...prevTestCreate,
            employee_demographics: {
              ...prevTestCreate?.employee_demographics,
              physicianConsent: consent,
            },
          };
        });
        break;
      default:
    }
  };
  const getValidSignature = useCallback(({ signCanvas, title }) => {
    if (signCanvas.current && !signCanvas.current.isEmpty()) {
      try {
        // Trim the canvas to remove empty space
        const trimmedCanvas = signCanvas.current.getTrimmedCanvas();
        // Convert the trimmed canvas to a Base64 PNG URL
        const signatureURL = trimmedCanvas.toDataURL("image/png");
        const id = uuidv4();
        const getSignatureFilePath = api.saveSignatureFile({ id, signatureURL });
        // Update state with the cropped signature
        setMatchedConsent(title, getSignatureFilePath);
      } catch (err) {
        appContext.showErrorMessage("Error in Signature Uploading");
      }
    }
  }, []);

  const onHandleSave = async () => {
    if (!newTestCreate.isNew && !permission[ORDER]?.archiveOrders) {
      appContext.showErrorMessage("User has not permission to edit order");
      return;
    }

    let newTestObj = _.cloneDeep(newTestCreate);

    const [ccCode, phoneNo, completePhone] = getPhoneNo(
      newTestCreate?.employee_demographics?.phoneNumber,
      newTestCreate?.employee_demographics?.countryCode
    );
    const { employee_demographics: emp } = newTestCreate;

    const diagData = getDiagListFromOrderTest(orderIcdData);

    let testList = getTestListFromOrderTest(orderIcdData, defaultConfig.isTestCreationFromEmployee);

    if (testList?.error) {
      setError(testList?.error);
      return;
    }

    const isAnyIctEmpty = testList.some((item) => item.ictCodes.length === 0);
    if (isAnyIctEmpty && !defaultConfig.isTestCreationFromEmployee) {
      setError("Fill diagnosis in all test fields.");
      return;
    }

    testList = testList.map((item) => ({ ...item, targetRanges: [] }));

    newTestObj = { ...newTestObj, ...diagData };



    if (!newTestObj.testsToDo) {
      newTestObj.testsToDo = {};
    }
    newTestObj.testsToDo["test"] = testList;

    if (Array.isArray(getProviderOption) && getProviderOption.length > 0 && !newTestObj.providerID) {
      const provObject = getProviderOption[0];
      newTestObj.providerID = provObject.id;
      newTestObj.providerName = provObject.name;
    }

    const selectedProvider = getProviderOption?.find((p) => p.id === newTestObj.providerID);

    const siteObject = getSiteOptions?.find((s) => s.value === selectedProvider?.associatedClient);

    if (siteObject) {
      newTestObj.siteID = siteObject.value;
      newTestObj.site_name = siteObject.label;
    } else if (newTestObj.siteID && !newTestObj.site_name && Array.isArray(getSiteOptions)) {
      const site = getSiteOptions.find((s) => s.value === newTestObj.siteID);
      newTestObj.site_name = site?.name || "";
    }

    if (!emp?.firstName) {
      setError("First name is required");
      return;
    }
    if (!isValidName(emp.firstName)) {
      setError("First name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }
    if (!emp.lastName) {
      setError("Last name is required");
      return;
    }
    if (!isValidName(emp.lastName)) {
      setError("Last name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }

    if (CONFIG.clientID !== ALTA_NEW_ID) {
      if (!emp.email) {
        setError("Email is required");
        return;
      }

      if (!isValidEmail(emp.email)) {
        setError("Invalid Email");
        return;
      }
    }

    if (!emp.dob) {
      setError("Date of birth is required");
      return;
    }

    if (!emp.phoneNumber) {
      setError("Phone number is required");
      return;
    }
    if (!isValidPhoneWithCode(emp.phoneNumber)) {
      setError("Phone number is invalid");
      return;
    }

    if (!emp?.sex) {
      setError("Gender is required");
      return;
    }

    if (!emp?.street?.trim()) {
      setError("Address is required");
      return;
    }
    if (!emp?.country) {
      setError("Country is Required");
      return;
    }
    if (emp?.zip === null || !emp?.zip) {
      setError("Zipcode is required");
      return;
    }

    // if (!zipCodeValidationUS(emp?.zip)) {
    //   setError("Invalid Postal Code");
    //   return;
    // }

    if (!emp?.state?.trim()) {
      setError("State is required");
      return;
    }

    if (!newTestObj.clientID) {
      setError("Client is required");
      return;
    }
    if (!newTestObj?.siteID) {
      setError(`${stringConstant.site} is required`);
      return;
    }

    if (!newTestObj.providerName) {
      setError("Ordering Physician is requried");
      return;
    }

    if (!emp.dob) {
      setError("Date of birth is required");
      return;
    }

    if (!newTestObj.testsToDo?.test?.length) {
      setError("Please select the test source");
      return;
    }

    if (!newTestObj.orderDate) {
      setError("Order Date is required");
      return;
    }

    if (moment(newTestObj.createdAt).isAfter(moment())) {
      setError("Order date and time should not be greater than the current date and time");
      return false;
    }

    if (moment(newTestObj.collectionDate).isBefore(moment(newTestObj.createdAt))) {
      setError("Collection Date cannot be earlier than Order Date");
      return false;
    }

    if (moment(newTestObj.labReceiveDate).isBefore(moment(newTestObj.createdAt))) {
      setError("Received Date cannot be earlier than Order Date");
      return false;
    }

    if (moment(newTestObj.orderDate, "YYYY-MM-DD").isBefore(moment(newTestObj.createdAt, "YYYY-MM-DD"))) {
      setError("Test Date cannot be earlier than Order Date");
      return false;
    }

    if (!hasAtLeastOneDiagnosis(newTestObj) && defaultConfig.isTestCreationFromEmployee === false) {
      setError("All diagnoses are null.");
      return false;
    }

    if (
      emp.insuranceCompanyCode &&
      !IGNORE_INS.includes(emp.insuranceCompanyCode) &&
      (!emp.insurance_number || !emp.insurance_number.trim())
    ) {
      setError(`Insurance Number required for ${emp.insurance_name} Insurance`);
      return;
    }

    newTestObj.testerPhone = appContext.user.phone_number;
    newTestObj.phoneNumber = phoneNo;
    newTestObj.employee_demographics.phoneNumber = phoneNo;
    newTestObj.employee_demographics.countryCode = ccCode;
    newTestObj.employee_demographics.idNumber = "000000000";
    newTestObj.provider = getProviderDetails(newTestObj);

    newTestCreate.providerID = !newTestCreate?.providerID ? getProviderOption?.[0]?.id : newTestCreate?.providerID;

    setLoading(true);

    const compareObjValues = !order.isNew ? compareAndReturnNewValues(newTestCreate, order) : null;

    if (compareObjValues) {
      const newOrderObj = getUpdatedFields(compareObjValues);

      let orderTestRes = await api.ordertestUpdate({ orderID: order.id, data: newOrderObj });
    }

    try {
      // save employee data using proxy contact api
      let record = await api.proxyContant({
        ...newTestObj,
        employee_demographics: {
          ...newTestObj.employee_demographics,
          ...(!newTestObj.employeeID && {
            referringPyhsician: newTestObj.providerName,
            referringProvider: newTestObj.providerID,
          }),
          dob: moment(newTestObj.employee_demographics.dob).format("MMDDYYYY"),
        },
        updatedBy: appContext.user.sub,
        updatedByName: appContext.user.name,
        clientID: newTestObj.clientID,
      });

      if (!record || record?.message) {
        setError(record?.message || "Fail to save the Employee Record");
        setLoading(false);
        return;
      }

      if (record && record?.eligibilityStatus) {
        record.eligibilityStatus = parseJSONObject(record.eligibilityStatus);
      }

      if (
        newTestObj &&
        newTestObj.employeeID &&
        record?.eligibilityStatus?.message !== "Eligibile" &&
        !IGNORE_INS.includes(newTestObj.insuranceCompanyCode) &&
        userClientData?.setting?.eligibility
      ) {
        // Check Eligibility When employee exist but eligibility not checked
        const EligRes = await api.checkEligibility({
          ...newTestObj.employee_demographics,
          mdID: record.id,
          updatedBy: appContext.user.sub,
          updatedByName: appContext.user.name,
        });

        if (EligRes) {
          newTestObj.eligibilityStatus = {
            message: EligRes.message,
            apiData: EligRes.apiData,
            createdAt: moment().toISOString(),
          };
        }
      } else if (!newTestObj.eligibilityStatus) {
        newTestObj.eligibilityStatus = record.eligibilityStatus;
      }

      if (record?.id || newTestObj.employeeID) {
        const orderList = await api.getOrderListThroughEmpID(record?.id || newTestObj.employeeID);

        const otherOrders = orderList?.filter((f) => f.id !== newTestObj.id);

        if (orderComparison(newTestObj, otherOrders)) {
          setError("An order for this patient for this date of service already exists");
          setLoading(false);
          return;
        }
      }

      // set the newly assigned employee id at test level and in demographics
      if (record?.id) {
        newTestObj.employeeID = record.id;
      }

      newTestObj.orderDate = moment(newTestObj.orderDate).toISOString();
      newTestObj.createdAt = moment(newTestObj.createdAt).toISOString();
      newTestObj.collectionDate = newTestObj.collectionDate ? moment(newTestObj.collectionDate).toISOString() : null;
      newTestObj.labReceiveDate = newTestObj.labReceiveDate ? moment(newTestObj.labReceiveDate).toISOString() : null;

      if (newTestObj.isNew) {
        newTestObj.createdBy = appContext.user.sub;
        newTestObj.createdByName = appContext.user.name;

        if (CUSTOM_INS.includes(newTestObj.insuranceCompanyCode)) {
          newTestObj.eligibilityStatus = { message: "Eligibile" };
        }
      }

      // if create new test allocate the new sequenec no

      let orderData;

      if (newTestObj.isNew) {
        orderData = await GQAPI.createOrderAPI(newTestObj);

        if (!orderData) {
          setLoading(false);
          appContext.showSuccessMessage("Order Creation Failed");
          return;
        }

        if (newTestObj.requestID) {
          await api.updateUploadRequest(newTestObj.requestID, {});
        }
        if (typeof orderData?.testsToDo === "string") {
          const parsedTestsToDo = JSON.parse(orderData.testsToDo);

          // Return the modified order object with parsed testsToDo
          orderData = {
            ...orderData,
            testsToDo: parsedTestsToDo,
          };
        }
        setOrderData(formatOrder(orderData));

        if (appContext?.user?.isSite()) {
          appContext.showSuccessMessage("Order Created Successfully");
          handleCloseCreateTest();
        } else {
          setShowConfirmation(true);
        }
        setLoading(false);
      } else {
        newTestObj.updatedBy = appContext.user.sub;
        newTestObj.updatedByName = appContext.user.name;
        orderData = await GQAPI.updateOrderAPI(newTestObj);

        //TODO::Need to Fixed this code
        //  if (newTestObj.testID) await api.updateTestFromOrder(newTestObj);
        if (orderData) appContext.showSuccessMessage("Order Updated Successfully");
        handleCloseCreateTest();
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
      appContext.showSuccessMessage("Order Creation Failed");
    }
  };

  const updateTestsToDo = (currentSelection, newSelection) => {
    // Convert the current testsToDo array to a map for easier lookup
    const currentMap = new Map(currentSelection.map((item) => [item.value, item]));

    // Iterate over newSelection and add items that are not in currentMap
    newSelection.forEach((item) => {
      if (!currentMap.has(item.value)) {
        currentMap.set(item.value, item);
      }
    });

    // Convert the map back to an array
    return Array.from(currentMap.values());
  };

  const handleTestType = (e) => {
    setTestCreate({
      ...newTestCreate,
      testsToDo: { test: updateTestsToDo(newTestCreate.testsToDo?.test || [], e) },
    });
  };

  const handleItemChange = (index, updatedItem) => {
    const updatedTests = newTestCreate.testsToDo.test.map((item, i) =>
      i === index ? { ...item, ...updatedItem } : item
    );

    setTestCreate({
      ...newTestCreate,
      testsToDo: { ...newTestCreate.testsToDo, test: updatedTests },
    });
  };

  const handleRemoveItem = (indexToRemove) => {
    const updatedTests = newTestCreate.testsToDo.test.filter((_, index) => index !== indexToRemove);
    setTestCreate({
      ...newTestCreate,
      testsToDo: { ...newTestCreate.testsToDo, test: updatedTests },
    });
  };
  const handleEditTest = (item, index) => {
    setIctList(item.ictCodes);
    setEditIndexIct(index);
  };

  const showSelectedTests = () => {
    if (!newTestCreate?.testsToDo?.test) {
      return "";
    }

    return (
      <div className="">
        <div className="form-group-wrapper" style={{ width: "40%" }}>
          <label className="modalLineHeaders">Tests </label>
          <Select
            className="w-100 siteSelector"
            options={testOptions}
            blurInputOnSelect={true}
            defaultValue={null}
            menuPlacement="auto"
            placeholder="Select Tests"
            isMulti
            value={null}
            onChange={(e) => handleTestType(e)}
          />
        </div>

        <table className="custom-separate-border-tr table w-100">
          <thead>
            <OrderTestHeader />
          </thead>
          <tbody>
            {newTestCreate.testsToDo?.test?.map((c, index) => (
              <OrderTestItem
                key={index}
                item={c}
                index={index}
                onItemChange={handleItemChange}
                showSelection={false}
                onItemRemove={handleRemoveItem}
                handleEditTest={handleEditTest}
                appContext={appContext}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const saveDiagnosisCodes = (item) => {
    const copyTestCreate = customCloneDeep(newTestCreate);

    if (
      copyTestCreate &&
      copyTestCreate.testsToDo &&
      copyTestCreate.testsToDo.test &&
      copyTestCreate.testsToDo.test[editIndexIct]
    ) {
      copyTestCreate.testsToDo.test[editIndexIct].ictCodes = item;
    }

    setTestCreate(copyTestCreate);
    setIctList(null);
    setEditIndexIct(null);
  };

  const handelChangeReferringProvider = (event) => {
    const { id, label, firstName, lastName, name, associatedClient } = event || {};
    const siteObject = getSiteOptions?.find((s) => s.value === associatedClient);

    setTestCreate({
      ...newTestCreate,
      providerName: label,
      providerID: id,
      ...(siteObject && {
        siteID: siteObject.value,
        site_name: siteObject.label,
      }),
    });
  };

  const handelChangeCliaNumber = (event) => {
    const { label, value } = event;

    setTestCreate({
      ...newTestCreate,
      location: value,
    });
  };

  const handelShowSourceSiteModal = (tableIndex, rowIndex) => {
    const sourceSiteIndexObj = {
      tableIndex,
      rowIndex,
    };
    setSourceSiteIndex(sourceSiteIndexObj);
    setShowSourceSite(true);
  };

  const isSiteUser = useMemo(() => {
    return !!appContext?.user?.isSite();
  }, [appContext]);

  const tabOptions = [
    {
      title: defaultConfig.isTestCreationFromEmployee ? "Details" : "Diagnosis",
      component: (
        <OrderDiagnosisView
          orderIcdData={orderIcdData}
          setOrderIcdData={(value) => setOrderIcdData(value)}
          appContext={appContext}
          showSourceSiteModal={handelShowSourceSiteModal}
          labClientTest={labClientTest}
          isSiteUser={isSiteUser}
          defaultConfig={defaultConfig}
        />
      ),
    },
    // {
    //   title: "Tests",
    //   component: showSelectedTests(),
    // },
  ];

  const handleTestViewChange = (view) => {
    if (view.title === "Tests") {
      let newTestObj = _.cloneDeep(newTestCreate);
      const diagData = getDiagListFromOrderTest(orderIcdData);
      const testList = getTestListFromOrderTest(orderIcdData, defaultConfig.isTestCreationFromEmployee);
      newTestObj = { ...newTestObj, ...diagData };
      if (!newTestObj.testsToDo) {
        newTestObj.testsToDo = {};
      }
      newTestObj.testsToDo["test"] = testList;

      setTestCreate(newTestObj);
    } else {
      console.log("Call Function convert test todo to diag");
    }
  };

  const createTestNow = async (order) => {
    try {
      const { testCreatedAt, testDate } = getTestDates(order);

      const { eligibilityStatus } = order;

      const { user = {}, userLabName } = appContext;

      let isCreatedByLab = "provider";

      let tests = order.testsToDo.test.map((t) => ({ ...t, isSelected: true }));

      const params = {
        labName: userLabName,
        order: {
          ...order,
          testsToDo: { test: tests },
          employee_demographics: {
            ...order.employee_demographics,
            dob: moment(order.employee_demographics.dob).format("MMDDYYYY"),
            worker: "",
            possibleexposure: "",
            confirmedexposure: "",
            relatedsymptoms: "",
            symptoms: "",
            othercomment: "",
            comment: "",
            docUploaded: false,
            isReview: false,
            venipuncture: false,
            distance: 0,
            isEligible: eligibilityStatus?.status?.toLowerCase() === "eligibile",
            stat: false,
          },
          labID: CONFIG.whiteLabelID,
          labName: userLabName,
          createSource: "portal",
          timezone: SYSTEM_TIME_ZONE,
          createdByPhone: user?.phone_number,
          testCreatedAt,
          testDate,
          createdBy: user?.sub,
          createdByName: user?.name,
          tester_name: user?.name,
          isCreatedByLab: isCreatedByLab,
          ...(CONFIG.clientID === SEROCLINIX_ID ||
            (CONFIG.clientID === SEROCLINIX_TEST && {
              submittedBy: user?.sub,
              submittedByName: user?.name,
              sentToLabDate: moment().toISOString(),
            })),
        },
      };

      setLoading(true);

      const res = await api.createMulipleTests(params);

      const testForPrinting = tests.map((test) => {
        const processedTest = res[test.label].test;
        return {
          ...test,
          ...processedTest,
          dob: order.dob,
          testStatus: test.testStatus || processedTest.status,
          testTypeID: test.id,
          testDate: order.orderDate,
          collectedDate: processedTest.createdAt,
          orderDate: order.createdAt,
        };
      });

      setLoading(false);

      // // Print barcodes for newly processed tests
      testForPrinting.forEach((test) => {
        if (test.barcode) {
          appContext.printBarcode(test);
        }
      });
    } catch (ex) {
      console.log("createTestNow", ex);
    }
    setLoading(false);
  };

  const handleConfirmation = async (isConfirm) => {
    setShowConfirmation(false);

    if (!isConfirm) {
      handleCloseCreateTest();
      return;
    }

    const paramObject = {
      ...orderData,
      isComingFromOrderCreation: true,
      orderDate: moment(orderData.orderDate).format("YYYY-MM-DDTHH:mm"),
      ...orderData.employee_demographics,
      employee_demographics: {
        ...orderData.employee_demographics,
        phoneNumber: `${orderData?.employee_demographics?.countryCode || "+1"}${
          orderData?.employee_demographics?.phoneNumber
        }`,
      },
    };

    if (CONFIG.clientID === SEROCLINIX_ID || CONFIG.clientID === SEROCLINIX_TEST) {
      await createTestNow(paramObject);
    } else {
      appContext.openPatientTestViewModal({
        order: paramObject,
        type: "order",
        uiType: "testManagmentUi",
      });
    }

    handleCloseCreateTest();
  };

  const handleAddSourceSite = (value) => {
    const { tableIndex, rowIndex } = sourceSiteIndex;

    let cloneOrderIcd = customCloneDeep(orderIcdData);
    cloneOrderIcd[tableIndex].orderData[rowIndex]["sourceSite"] = value;

    setOrderIcdData(cloneOrderIcd);

    setShowSourceSite(false);
  };

  return (
    <>
      {ictList && (
        <DiagnosisAccordionView
          handleClose={() => setIctList(null)}
          handelSaveDiagnosisPanel={saveDiagnosisCodes}
          ictList={ictList}
          setIctList={setIctList}
          isNew={false}
        />
      )}

      {showSourceSite && (
        <SourceSiteModal
          handleClose={() => setShowSourceSite(false)}
          clients={getSiteOptions}
          rowData={
            orderIcdData[0]?.orderData?.[sourceSiteIndex.rowIndex]?.sourceSite ||
            orderIcdData[0]?.orderData?.[sourceSiteIndex.rowIndex]?.test?.sourceSite
          }
          handleAddSourceSite={handleAddSourceSite}
        />
      )}

      <Modal
        show
        animation={true}
        onHide={handleCloseCreateTest}
        size="3xl"
        style={{ paddingLeft: "0" }}
        centered
        keyboard={false}
        className="seperateModal"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            {!newTestCreate.isNew ? "Update Order" : "Create Order"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            paddingTop: 0,
          }}
        >
          <div className="tset-clent-edit createClientsWrapper">
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">First Name: </label>
              <input
                className="modalInput"
                placeholder="First Name"
                style={{
                  width: "100%",
                  flexShrink: "initial",
                }}
                value={newTestCreate?.employee_demographics?.firstName}
                onChange={(e) => {
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      firstName: makeAlphabetInput(e.target.value),
                    },
                  });
                }}
              />
              <i
                ref={childNode}
                className="fas fa-search cursor-pointer"
                style={{ cursor: "pointer" }}
                onClick={() => searchPreReg(newTestCreate?.employee_demographics?.firstName, "FirstName")}
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Last Name: </label>
              <input
                className="modalInput"
                placeholder="Last Name"
                style={{
                  width: "100%",
                  flexShrink: "initial",
                }}
                value={newTestCreate?.employee_demographics?.lastName}
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      lastName: makeAlphabetInput(e.target.value),
                    },
                  })
                }
              />
              <i
                ref={childNode}
                className="fas fa-search cursor-pointer"
                style={{ cursor: "pointer" }}
                onClick={() => searchPreReg(newTestCreate?.employee_demographics?.lastName, "LastName")}
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Email: </label>
              <input
                className="modalInput"
                placeholder="Email"
                style={{
                  width: "100%",
                  flexShrink: "initial",
                }}
                value={newTestCreate?.employee_demographics?.email}
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      email: convertToLower(e.target.value),
                    },
                    email: convertToLower(e.target.value),
                  })
                }
              />
              <i
                ref={childNode}
                className="fas fa-search cursor-pointer"
                style={{ cursor: "pointer" }}
                onClick={() => searchPreReg(newTestCreate?.employee_demographics?.email, "Email")}
              />
            </div>
            <div className="form-group-wrapper dateWrapper">
              <label className="modalLineHeaders">DoB: </label>
              <input
                className="modalInput"
                type="date"
                style={{
                  width: "100%",
                  flexShrink: "initial",
                }}
                value={newTestCreate.employee_demographics?.dob}
                placeholder="Date Of Birth"
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      dob: moment(e.target.value).format("YYYY-MM-DD"),
                    },
                  })
                }
                min={moment("1900-01-01").format("YYYY-MM-DD")}
                max={moment().format("YYYY-MM-DD")}
              />
              <i
                ref={childNode}
                className="fas fa-search cursor-pointer"
                style={{ cursor: "pointer" }}
                onClick={() => searchPreReg(newTestCreate?.employee_demographics?.dob, "DOB")}
              />
              {/* <SingleDatePicker
                onApply={(event, picker) => {
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      dob: moment(picker.startDate).format("MMDDYYYY"),
                    },
                  });
                }}
                value={formatDateOfBirthDOB(newTestCreate.employee_demographics?.dob)}
                startDate={formatDateOfBirthDOB(newTestCreate.employee_demographics?.dob)}
                currentDate={true}
              /> */}
            </div>

            <div className="form-group-wrapper phoneInput">
              <label className="modalLineHeaders">Phone #: </label>
              <PhoneNoInput handleChange={handlePhoneNo} value={newTestCreate?.employee_demographics?.phoneNumber} />
              <i
                ref={childNode}
                className="fas fa-search cursor-pointer"
                style={{ cursor: "pointer" }}
                onClick={() => searchPreReg(newTestCreate?.employee_demographics?.phoneNumber, "Phone")}
              />
            </div>

            <div className="form-group-wrapper phoneInput">
              <label className="modalLineHeaders">Gender</label>
              <div className="d-flex justify-content-between w-100 flex-wrap row-gap-10">
                <RadioButton
                  name="sex"
                  id={`male${Math.random()}`}
                  label="Male"
                  checked={newTestCreate?.employee_demographics?.sex == "M"}
                  handleRadioButtonChange={(e) =>
                    setTestCreate({
                      ...newTestCreate,
                      employee_demographics: {
                        ...newTestCreate.employee_demographics,
                        sex: e.target.checked && "M",
                      },
                    })
                  }
                />
                <RadioButton
                  name="sex"
                  id={`female${Math.random()}`}
                  checked={newTestCreate?.employee_demographics?.sex == "F"}
                  label="Female"
                  handleRadioButtonChange={(e) =>
                    setTestCreate({
                      ...newTestCreate,
                      employee_demographics: {
                        ...newTestCreate.employee_demographics,
                        sex: e.target.checked && "F",
                      },
                    })
                  }
                />
                <RadioButton
                  id={`other${Math.random()}`}
                  checked={newTestCreate?.employee_demographics?.sex == "X"}
                  name="sex"
                  label="Not Specified"
                  handleRadioButtonChange={(e) =>
                    setTestCreate({
                      ...newTestCreate,
                      employee_demographics: {
                        ...newTestCreate.employee_demographics,
                        sex: e.target.checked && "X",
                      },
                    })
                  }
                />
                <RadioButton
                  id={`other${Math.random()}`}
                  checked={newTestCreate?.employee_demographics?.sex == "N"}
                  name="sex"
                  label="Not Binary"
                  handleRadioButtonChange={(e) =>
                    setTestCreate({
                      ...newTestCreate,
                      employee_demographics: {
                        ...newTestCreate.employee_demographics,
                        sex: e.target.checked && "N",
                      },
                    })
                  }
                />
              </div>
            </div>

            <div className="form-group-wrapper">
              <label className="modalLineHeaders">DL/Passport #: </label>
              <input
                className="modalInput"
                placeholder="License/Passport"
                style={{
                  width: "100%",
                  flexShrink: "initial",
                }}
                maxLength={16}
                value={newTestCreate?.employee_demographics?.idNumber}
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      idNumber: e.target.value,
                    },
                  })
                }
              />
              <i
                ref={childNode}
                className="fas fa-search cursor-pointer"
                style={{ cursor: "pointer" }}
                onClick={() => searchPreReg(newTestCreate?.employee_demographics?.idNumber, "License")}
              />
            </div>

            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Address: </label>
              <AutoCompleteAddress
                value={newTestCreate?.employee_demographics?.street}
                cssClass={"modalInput"}
                handleChange={handleUpdateAddress}
              />
              {/* <input
                ref={ref}
                value={newTestCreate?.employee_demographics?.street}
                placeholder="Enter Address"
                className="modalInput"
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      street: validSpace(e.target.value),
                    },
                  })
                }
              /> */}
            </div>
            {defaultConfig.isShowRoom && (
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">{defaultConfig.roomno} : </label>
                <input
                  className="modalInput"
                  placeholder={defaultConfig.roomno}
                  value={newTestCreate?.employee_demographics?.street2}
                  onChange={(e) =>
                    setTestCreate({
                      ...newTestCreate,
                      employee_demographics: {
                        ...newTestCreate.employee_demographics,
                        street2: validSpace(e.target.value),
                      },
                    })
                  }
                />
              </div>
            )}
            {defaultConfig.isShowFloor && (
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Floor No:</label>
                <input
                  className="modalInput"
                  placeholder="Floor No"
                  value={newTestCreate?.floorNo}
                  onChange={(e) =>
                    setTestCreate({
                      ...newTestCreate,
                      floorNo: validSpace(e.target.value),
                    })
                  }
                />
              </div>
            )}

            <div className="form-group-wrapper">
              <label className="modalLineHeaders">City: </label>
              <input
                className="modalInput"
                placeholder="City "
                value={newTestCreate?.employee_demographics?.city}
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      city: validSpace(e.target.value),
                    },
                  })
                }
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Zip: </label>
              <input
                className="modalInput"
                placeholder="Zip"
                maxLength={10}
                value={newTestCreate?.employee_demographics?.zip}
                onChange={(e) => {
                  let val = e.target.value;
                  if (val) val = val.toUpperCase();
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      zip: newTestCreate.employee_demographics.isoCode === "US" ? formatZip(val) : validSpace(val),
                    },
                  });
                }}
              />
            </div>
            {loading && <Loader />}
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">State: </label>
              <input
                className="modalInput"
                placeholder="State"
                maxLength={35}
                value={newTestCreate?.employee_demographics?.state}
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      state: e.target.value.toUpperCase(),
                    },
                  })
                }
              />
            </div>
            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Country: </label>
              <Select
                options={countriesOption}
                blurInputOnSelect={true}
                defaultValue={{ label: "United States", value: "United States" }}
                menuPlacement="auto"
                className="w-100 siteSelector"
                placeholder="Select Country"
                value={
                  newTestCreate.employee_demographics.country
                    ? {
                        label: newTestCreate.employee_demographics.country,
                        value: newTestCreate.employee_demographics.country,
                      }
                    : null
                }
                onChange={(e) => {
                  const country = countryListAllIsoData.find((f) => f.name === e.value) || {
                    code: newTestCreate.employee_demographics.isoCode || "US",
                  };
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      country: e.value,
                      isoCode: country.code,
                    },
                  });
                }}
              />
            </div>

            {clients.length > 1 && (
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Client </label>
                <Select
                  className="w-100 siteSelector"
                  options={clients.map((l) => {
                    return { value: l.id, label: l.name };
                  })}
                  blurInputOnSelect={true}
                  defaultValue={null}
                  menuPlacement="auto"
                  value={
                    newTestCreate.clientID
                      ? {
                          label: newTestCreate.clientName,
                          value: newTestCreate.clientID,
                        }
                      : null
                  }
                  placeholder="Select Client"
                  onChange={(e) =>
                    setTestCreate({
                      ...newTestCreate,
                      clientID: e.value,
                      clientName: e.label,
                      siteID: null,
                      site_name: null,
                    })
                  }
                />
              </div>
            )}
            {/* {getSiteOptions.length > 1 && (
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">{stringConstant.site} </label>
                <Select
                  className="w-100 siteSelector"
                  options={getSiteOptions}
                  blurInputOnSelect={true}
                  defaultValue={null}
                  isDisabled={newTestCreate.employeeID}
                  menuPlacement="auto"
                  placeholder={`Select ${stringConstant.site}`}
                  value={newTestCreate.siteID ? getSiteOptions.find((f) => f.value === newTestCreate.siteID) : null}
                  onChange={(e) =>
                    setTestCreate({
                      ...newTestCreate,
                      siteID: e.value,
                      site_name: e.label,
                      providerID: null,
                      providerName: null,
                      provider: null,
                    })
                  }
                />
              </div>
            )} */}
            {labs.length > 1 && (
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Lab </label>
                <Select
                  className="w-100 siteSelector"
                  options={labs?.map((l) => {
                    return { value: l.id, label: l.name };
                  })}
                  blurInputOnSelect={true}
                  defaultValue={null}
                  menuPlacement="auto"
                  placeholder="Select Lab"
                  value={
                    newTestCreate.labID
                      ? {
                          label: newTestCreate.labName,
                          value: newTestCreate.labID,
                        }
                      : null
                  }
                  onChange={(e) => setTestCreate({ ...newTestCreate, labID: e.value, labName: e.label })}
                />
              </div>
            )}

            <div className="form-group-wrapper">
              <label className="modalLineHeaders">Payer Information: </label>
              <Select
                className="w-100 siteSelector"
                options={clientInsurances}
                blurInputOnSelect={true}
                defaultValue={null}
                menuPlacement="auto"
                value={
                  newTestCreate.employee_demographics?.insurance_name
                    ? {
                        label: newTestCreate.employee_demographics?.insurance_name,
                        value: newTestCreate.employee_demographics?.insurance_number,
                      }
                    : ""
                }
                placeholder="Select Payer"
                onChange={(e) =>
                  setTestCreate({
                    ...newTestCreate,
                    employee_demographics: {
                      ...newTestCreate.employee_demographics,
                      insurance_name: e.label,
                      insuranceCompanyCode: e.value,
                    },
                  })
                }
              />
            </div>

            <div className="form-group-wrapper w-100">
              <label className="modalLineHeaders">Instructions: </label>
              <div className="d-flex justify-content-between align-items-center w-100 borderless-checkbox">
                <FilledCheckbox
                  label={"STAT"}
                  id={"stat"}
                  isChecked={newTestCreate.employee_demographics?.stat}
                  handleChange={(e) =>
                    setTestCreate({
                      ...newTestCreate,
                      employee_demographics: {
                        ...newTestCreate.employee_demographics,
                        stat: e.target.checked,
                      },
                    })
                  }
                />
                <FilledCheckbox
                  label={"FAX"}
                  id={"fax"}
                  isChecked={newTestCreate.employee_demographics?.fax}
                  handleChange={(e) =>
                    setTestCreate({
                      ...newTestCreate,
                      employee_demographics: {
                        ...newTestCreate.employee_demographics,
                        fax: e.target.checked,
                      },
                    })
                  }
                />
                <FilledCheckbox
                  label={"CALL"}
                  id={"call"}
                  isChecked={newTestCreate.employee_demographics?.call}
                  handleChange={(e) =>
                    setTestCreate({
                      ...newTestCreate,
                      employee_demographics: {
                        ...newTestCreate.employee_demographics,
                        call: e.target.checked,
                      },
                    })
                  }
                />
                <FilledCheckbox
                  label={"FASTING"}
                  id={"fasting"}
                  isChecked={newTestCreate.employee_demographics?.fasting}
                  handleChange={(e) =>
                    setTestCreate({
                      ...newTestCreate,
                      employee_demographics: {
                        ...newTestCreate.employee_demographics,
                        fasting: e.target.checked,
                      },
                    })
                  }
                />
                <FilledCheckbox
                  label={"RANDOM"}
                  id={"random"}
                  isChecked={newTestCreate.employee_demographics?.random}
                  handleChange={(e) =>
                    setTestCreate({
                      ...newTestCreate,
                      employee_demographics: {
                        ...newTestCreate.employee_demographics,
                        random: e.target.checked,
                      },
                    })
                  }
                />
              </div>
            </div>

            {!CUSTOM_INS.includes(newTestCreate?.employee_demographics?.insuranceCompanyCode) && (
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">Member ID: </label>
                <input
                  className="modalInput"
                  placeholder="Member ID"
                  value={newTestCreate?.employee_demographics?.insurance_number}
                  onChange={(e) => {
                    let val = e.target.value;
                    setTestCreate({
                      ...newTestCreate,
                      employee_demographics: {
                        ...newTestCreate.employee_demographics,
                        insurance_number: convertToUpper(val),
                      },
                    });
                  }}
                />
              </div>
            )}

            {!appContext?.user?.isSite() && (
              <div className="form-group-wrapper">
                <label className="modalLineHeaders">External Reference ID: </label>
                <input
                  className="modalInput"
                  placeholder="External Reference ID"
                  value={newTestCreate?.externalID}
                  onChange={(e) => setTestCreate({ ...newTestCreate, externalID: e.target.value })}
                />
              </div>
            )}

            <InputField
              type="dropDown"
              inputStyle="referring-provider-select w-100"
              labelStyle="mt-0 modalLineHeaders text-capitalize"
              label="Ordering Physician"
              options={getProviderOption}
              value={
                newTestCreate?.providerID
                  ? providers?.find((item) => item.id === newTestCreate?.providerID)
                  : getProviderOption?.[0] ?? null
              }
              index="referringProvider"
              placeholder="Select Ordering Physician"
              isClearable={true}
              handleChange={handelChangeReferringProvider}
              required={true}
            />

            {/* {!appContext?.user?.isSite() && getCliaNumber.length > 1 && (
              <InputField
                type="dropDown"
                inputStyle="referring-provider-select w-100"
                labelStyle="mt-0 modalLineHeaders text-capitalize"
                label="location"
                options={getCliaNumber}
                value={
                  newTestCreate?.location ? getCliaNumber.find((item) => item.value === newTestCreate?.location) : null
                }
                index="location"
                placeholder="Select location"
                isClearable={true}
                handleChange={handelChangeCliaNumber}
              />
            )} */}

            <InputField
              type="text"
              inputStyle="referring-provider-select w-100"
              labelStyle="mt-0 modalLineHeaders text-capitalize"
              label="Clinical Comments"
              index="clinical_comments"
              placeholder="Enter clinical comments"
              value={newTestCreate.clincialComments}
              isClearable={true}
              handleChange={(e) =>
                setTestCreate({
                  ...newTestCreate,
                  clincialComments: e.target.value,
                })
              }
            />

            <InputField
              type="text"
              inputStyle="referring-provider-select w-100"
              labelStyle="mt-0 modalLineHeaders text-capitalize"
              label="Lab Comments"
              index="cancelledReason"
              placeholder="Enter Lab comments"
              value={newTestCreate.cancelledReason}
              isClearable={true}
              handleChange={(e) =>
                setTestCreate({
                  ...newTestCreate,
                  cancelledReason: e.target.value,
                })
              }
            />

            {showPopOver && recordData.length > 1 && (
              <EmployeeSearchRecord
                data={recordData}
                handleSelect={populate}
                handleClose={() => {
                  setShowPopOver(false), setSelectSearchData(null);
                }}
                setSelectSearchData={setSelectSearchData}
                selectSearchdata={selectSearchdata}
              />
            )}
          </div>

          {/* <div className={isSiteUser ? "create-order-3-item" : "create-order-4th-item"}> */}
          <div className="tset-clent-edit createClientsWrapper">
            <div className="form-group-wrapper dateWrapper">
              <label className="modalLineHeaders">Order Date: </label>

              <input
                className="modalInput"
                type="datetime-local"
                value={
                  newTestCreate?.createdAt
                    ? moment(newTestCreate?.createdAt).format("YYYY-MM-DDTHH:mm")
                    : moment().format("YYYY-MM-DDTHH:mm")
                }
                style={{ width: "100%", marginBottom: "10px" }}
                max={moment().format("YYYY-MM-DDTHH:mm")}
                onChange={(e) => setTestCreate({ ...newTestCreate, createdAt: e.target.value })}
              />
            </div>

            <div className="form-group-wrapper dateWrapper">
              <label className="modalLineHeaders">Collection Date: </label>

              <input
                className="modalInput"
                type="datetime-local"
                value={
                  newTestCreate?.collectionDate
                    ? moment(newTestCreate?.collectionDate).format("YYYY-MM-DDTHH:mm")
                    : null
                }
                max={moment().format("YYYY-MM-DDTHH:mm")}
                style={{ width: "100%", marginBottom: "10px" }}
                onChange={(e) => setTestCreate({ ...newTestCreate, collectionDate: e.target.value })}
              />
            </div>

            {!isSiteUser && (
              <div className="form-group-wrapper dateWrapper">
                <label className="modalLineHeaders">Received Date: </label>
                <input
                  className="modalInput"
                  type="datetime-local"
                  value={
                    newTestCreate?.labReceiveDate
                      ? moment(newTestCreate?.labReceiveDate).format("YYYY-MM-DDTHH:mm")
                      : null
                  }
                  style={{ width: "100%", marginBottom: "10px" }}
                  max={moment().format("YYYY-MM-DDTHH:mm")}
                  onChange={(e) => setTestCreate({ ...newTestCreate, labReceiveDate: e.target.value })}
                />
              </div>
            )}

            {/* <div className="form-group-wrapper dateWrapper">
              <label className="modalLineHeaders">Future Test Date: </label>

              <input
                className="modalInput"
                type="date"
                value={newTestCreate?.orderDate ? moment(newTestCreate?.orderDate).format("YYYY-MM-DD") : null}
                min={moment(newTestCreate?.createdAt).format("YYYY-MM-DD")}
                style={{ width: "100%", marginBottom: "10px" }}
                onChange={(e) => setTestCreate({ ...newTestCreate, orderDate: e.target.value })}
              />
            </div> */}
          </div>

          <div style={{ border: "1px solid #EAEAEA", padding: "15px", borderRadius: "10px" }}>
            {/* <p className="font-bold" style={{ fontSize: "20px" }}>
              Diagnosis
            </p> */}

            <TabPills tabs={tabOptions} handleTabClick={handleTestViewChange} />
          </div>

          {/* {showSelectedTests()} */}
        </Modal.Body>
        <div className="px-4" style={{ height: "3vh" }}>
          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </div>
        <Modal.Footer>
          <Button
            style={{ marginBottom: 10 }}
            variant="secondary"
            className="modalButtons"
            onClick={() => {
              handleCloseCreateTest();
            }}
          >
            Close
          </Button>
          {/* <Button style={{ marginBottom: 10 }} variant="secondary" className="modalButtons" onClick={handeTestFormat}>
            Test
          </Button> */}
          <div>
            {/* {!newTestCreate.isNew && newTestCreate.status === "Pending" && (
              <Button
                style={{ marginBottom: 10 }}
                variant="primary"
                disabled={loading}
                className="modalButtons mx-2"
                onClick={() => createTest(newTestCreate)}
              >
                Create Test
              </Button>
            )} */}
            <SignaturePad
              appContext={appContext}
              title="Patient Consent"
              consent={newTestCreate?.employee_demographics?.patientConsent}
              getValidSignature={getValidSignature}
              deleteSignature={deleteSignature}
            />
            <SignaturePad
              appContext={appContext}
              title="Physician Consent"
              consent={newTestCreate?.employee_demographics?.physicianConsent}
              getValidSignature={getValidSignature}
              deleteSignature={deleteSignature}
            />
            <Button
              style={{ marginBottom: 10 }}
              variant="primary"
              disabled={loading}
              className="modalButtons"
              onClick={onHandleSave}
            >
              {!newTestCreate.isNew ? "Update" : "Create"}
            </Button>
          </div>
          {showConfirmation && (
            <ConfirmationModal
              show={showConfirmation}
              title="Confirmation"
              message={
                CONFIG.clientID === SEROCLINIX_ID || CONFIG.clientID === SEROCLINIX_TEST
                  ? "Do you want to create the tests now and print labels?"
                  : "Do you also want to accession the associated tests now?"
              }
              handleConfirm={handleConfirmation}
              cssClass="seperateModal"
              isPreRegister
            />
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrderTestModal;
