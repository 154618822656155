import React, { useState, forwardRef, useImperativeHandle } from "react";
import { Storage } from "aws-amplify";
import { saveAs } from "file-saver";
import api from "api";
import MainTable from "components/Table/MainTable";
import { PATIENT_DOCUMENT_COLUMNS } from "constants/personalization";
import { TABLE_QUICK_TOOLS, BUCKET_EXTERNAL_IMAGES } from "constant";
import { getContentType } from "utils";
import DocumentPreviewModal from "components/Modal/DocumentPreviewModal";
import JSZip from "jszip";

const DocumentList = forwardRef((props, ref) => {
  const { list, handleEdit, setDocuments, appContext, setLoading } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [fileType, setFileType] = useState("");
  const [checkboxes, setCheckboxes] = useState([]);

  const handleCheckboxChange = (e, doc) => {
    const filteredList = checkboxes.filter((c) => c !== doc.id);

    if (e.target.checked) {
      filteredList.push(doc.id);
    }

    setCheckboxes(filteredList);
  };

  const handleDownloadDoc = async (doc) => {
    try {
      let name = doc.fileName;
      if (!doc.fileName) return;
      setLoading(true);
      const result = await Storage.get(doc.fileName, {
        bucket: BUCKET_EXTERNAL_IMAGES,
      });
      const response = await fetch(result);
      const blob = await response.blob();

      setLoading(false);
      saveAs(blob, `${name}`);
    } catch (err) {
      setLoading(false);
      console.log("Error", err);
    }
  };

  const handleMultiDownload = async () => {
    const filteredList = list.filter((item) => checkboxes.some((checkboxId) => checkboxId === item.id));

    if (!filteredList || filteredList.length === 0) return;
    setLoading(true);
    const zip = new JSZip();

    for (const doc of filteredList) {
      if (!doc.fileName) continue;
      try {
        const result = await Storage.get(doc.fileName, {
          bucket: BUCKET_EXTERNAL_IMAGES,
        });
        const response = await fetch(result);
        const blob = await response.blob();
        zip.file(doc.fileName, blob);
      } catch (err) {
        console.log(`Error downloading file ${doc.fileName}:`, err);
      }
    }

    const zipBlob = await zip.generateAsync({ type: "blob" });

    saveAs(zipBlob, "downloaded_files.zip");

    setCheckboxes([]);

    setLoading(false);
  };

  useImperativeHandle(ref, () => ({
    handleMultiDownload,
  }));

  const handleDocDeleted = async (param) => {
    try {
      setLoading(true);
      const response = await api.deleteDocumentAPI(param);
      setLoading(false);
      if (response && response.status) {
        appContext.showSuccessMessage("Document Archived Successfully");
        setDocuments((prev) => prev.filter((f) => f.id !== param.id));
        return;
      }
      appContext.showErrorMessage(response?.error || "Document Archived Failed");
    } catch (err) {
      setLoading(false);
      console.log("Error:", err);
    }
  };

  const handleViewDoc = async (doc) => {
    try {
      if (!doc.fileName) return;

      // Determine the content type based on the file extension
      const fileExtension = doc.fileName.split(".").pop().toLowerCase();

      const contentType = getContentType(fileExtension);

      // Fetch the file from S3 with inline content disposition
      const result = await Storage.get(doc.fileName, {
        bucket: BUCKET_EXTERNAL_IMAGES,
        download: false,
        contentDisposition: "inline",
        contentType: contentType,
      });

      // Update state to show in modal
      setFileUrl(result);
      setFileType(fileExtension === "pdf" ? "pdf" : "image");
      setIsModalOpen(true);
    } catch (err) {
      console.log("Error", err);
    }
  };

  const handleDropDownOptions = (type, item) => {
    switch (type) {
      case "Archive":
        handleDocDeleted(item);
        break;
      case "Download":
        handleDownloadDoc(item);
        break;
      case "View":
        handleViewDoc(item);
        break;
      default:
        break;
    }
  };

  return (
    <div
      className={`documents-update-patient-table  ${
        list.length === 1 || list.length === 2 ? "documents-done-table" : ""
      }`}
    >

      <MainTable
        columns={PATIENT_DOCUMENT_COLUMNS}
        rows={list}
        tools={[TABLE_QUICK_TOOLS.checkbox, TABLE_QUICK_TOOLS.edit]}
        selectedRows={checkboxes}
        dropDownOptions={[
          { value: "Download", title: "Download" },
          { value: "Archive", title: "Archive" },
          { value: "View", title: "View" },
        ]}
        handleDropDownClick={handleDropDownOptions}
        handleCellClick={(key, row) => {
          if (key === TABLE_QUICK_TOOLS.edit) {
            handleEdit(row);
          }
          if (key === TABLE_QUICK_TOOLS.checkbox) {
            handleCheckboxChange(event, row);
          }
        }}
        isDocList
        strippedTable
      />
      <DocumentPreviewModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        fileUrl={fileUrl}
        fileType={fileType}
      />
    </div>
  );
});

export default DocumentList;
