import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MESSAGE_MODES } from "constant";
import { NEW_PROVIDER_OBJECT, CONFIG } from "constant";
import { updateCompanySetting } from "store/features/companySetting/companySettingSlice";
import { callAmplifyAPI, callAmplifyAPIPut, callAmplifyAPIWithError } from "views/GraphQL/gqApi";
import api from "../../../api";
import { setMessage } from "../general/generalAction";
import {
  CREATE_PROVIDER,
  DELETE_PROVIDER,
  PROVIDER,
  PROVIDER_FETCH,
  UPDATE_PROVIDER,
  CREATE_PORTAL_PROVIDER,
  UPDATE_PROVIDER_CLIENT,
  FETCH_RENDERING_PROVIDER,
} from "./providersContants";
import { toTitleCase } from "utils";

// Async thunk to fetch all providers
export const fetchAllProviders = createAsyncThunk(PROVIDER_FETCH, async () => {
  const response = await callAmplifyAPI("/providers/list");

  if (response && response?.rows) {
    const providers = response.rows.map((item) => {
      return { ...item, label: toTitleCase(item.name), value: item.id };
    });
    return providers;
  }
  return {};
});

export const createPortalUserAsync = createAsyncThunk(CREATE_PORTAL_PROVIDER, async (provider, { dispatch }) => {
  try {
    const roles = [
      {
        role: "Provider",
        relatedId: provider.associatedClient,
        clientID: CONFIG.clientID,
        labID: CONFIG.whiteLabelID,
        title: CONFIG.basename,
      },
    ];
    console.log('Provider - createPortalUserAsync', provider);
    const userObject = {
      id: provider.id,
      groupname: "Providers",
      username: provider.phoneNumber,
      phone_number: provider.phoneNumber,
      email: provider.email,
      first: provider.firstName,
      last: provider.lastName,
      clientID: CONFIG.clientID,
      labID: CONFIG.whiteLabelID,
      connectedID: provider.associatedClient,
      roles: roles,
      note: CONFIG.basename,
      labName: provider?.labName,
      provider: provider.provider,
    };

    const response = await callAmplifyAPIWithError("/providers/user", userObject);

    if (provider.isShowMessage) {
      dispatch(setMessage(response?.message, MESSAGE_MODES?.success));
    }
    return { ...response, id: provider.id };
  } catch (error) {
    dispatch(setMessage(error.response.data.message, MESSAGE_MODES?.error));
  }
});

// Async thunk to create a provider
export const createProviderAsync = createAsyncThunk(
  CREATE_PROVIDER,
  async ({ provider, defaultSetting }, { dispatch }) => {
    let providerDefaultSetting = defaultSetting;
    console.log('Provider - createProviderAsync', provider);

    const response = await callAmplifyAPI("/providers", {
      name: provider.name,
      clientID: provider.clientID,
      firstName: provider.firstName,
      middleName: provider.middleName,
      lastName: provider.lastName,
      code: provider.code,
      email: provider.email,
      npi: provider.npi,
      street: provider.street,
      city: provider.city,
      state: provider.state,
      zip: provider.zip,
      phoneNumber: provider.phoneNumber,
      fax: provider.fax,
      isActive: provider.isActive,
      taxid: provider.taxid,
      speciality: provider.speciality,
      npiType: provider.npiType,
      setting: provider.setting,
      associatedClient: provider.associatedClient,
      linkedClient: provider.linkedClient,
    });
    Object.keys(defaultSetting).map((m) => {
      providerDefaultSetting[m] = response?.rows?.id;
    });

    const providerObject = response?.rows;

    if (provider?.isCheckedUser === "active" && providerObject) {
      dispatch(
        createPortalUserAsync({
          ...providerObject,
          isShowMessage: true,
          provider: provider.provider,
          labName: provider.labName,
        })
      );
    }

    //TODO:: Functionality Pending to add in Default Setting
    //dispatch(updateCompanySetting(providerDefaultSetting));
    return response?.rows;
  }
);

// Async thunk to update a provider
export const updateProviderAsync = createAsyncThunk(
  UPDATE_PROVIDER,
  async ({ provider, defaultSetting }, { dispatch }) => {
    const response = await callAmplifyAPIPut("/providers", {
      id: provider.id,
      name: provider.name,
      clientID: provider.clientID,
      firstName: provider.firstName,
      middleName: provider.middleName,
      lastName: provider.lastName,
      code: provider.code,
      email: provider.email,
      npi: provider.npi,
      street: provider.street,
      city: provider.city,
      state: provider.state,
      zip: provider.zip,
      phoneNumber: provider.phoneNumber,
      fax: provider.fax,
      isActive: provider.isActive,
      taxid: provider.taxid,
      speciality: provider.speciality,
      npiType: provider.npiType,
      setting: provider.setting,
      associatedClient: provider.associatedClient,
      linkedClient: provider.linkedClient,
    });
    // dispatch(updateCompanySetting(defaultSetting));
    return response?.rows;
  }
);
export const updateProviderClientAsync = createAsyncThunk(UPDATE_PROVIDER_CLIENT, async (provider, { dispatch }) => {
  // let providerDefaultSetting = defaultSetting;
  const response = await api.updateProviderClient(provider.pId, provider.uId);
  // Object.keys(defaultSetting).map((m) => {
  //   providerDefaultSetting[m] = response.id;
  // });
  // dispatch(updateCompanySetting(providerDefaultSetting));
  return response;
});
export const updateProviderNoteAsync = createAsyncThunk(UPDATE_PROVIDER, async (provider, { dispatch }) => {
  const response = await callAmplifyAPI("/providers/note", {
    id: provider.id,
    note: provider.note,
  });
  dispatch(setMessage("Provider Note Add SuccessFully!", MESSAGE_MODES.success));
  return response?.rows;
});

// Async thunk to delete a provider
export const deleteProviderAsync = createAsyncThunk(DELETE_PROVIDER, async (providerId) => {
  const response = await callAmplifyAPI("/providers/remove", {
    id: providerId,
  });
  return response?.rows;
});

// Async fetch Rendering Provider
export const renderingProviderAsync = createAsyncThunk(FETCH_RENDERING_PROVIDER, async (providerId) => {
  const response = await callAmplifyAPI("/providers/rendering", {});
  return response?.rows;
});

const providerSlice = createSlice({
  name: PROVIDER,
  initialState: {
    providers: [],
    filteredProviders: [],
    openCreator: false,
    renderingProviders: [],
    newUser: NEW_PROVIDER_OBJECT,
  },
  reducers: {
    setFilteredProviders: (state, action) => {
      state.filteredProviders = action.payload;
    },
    setOpenCreatorProviderModal: (state, action) => {
      state.openCreator = action.payload;
    },
    setNewUserProvider: (state, action) => {
      state.newUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchAllProviders.fulfilled, (state, action) => {
        state.providers = action.payload;
        state.filteredProviders = action.payload;
      })
      .addCase(createProviderAsync.fulfilled, (state, action) => {
        state.providers.unshift(action.payload);
        state.filteredProviders.unshift(action.payload);
      })
      .addCase(renderingProviderAsync.fulfilled, (state, action) => {
        state.renderingProviders = action.payload;
      })
      .addCase(deleteProviderAsync.fulfilled, (state, action) => {
        const deletedproviderId = action.payload.id;
        const deletedUserSubId = action.payload.subID;
        if (deletedproviderId) {
          if (deletedUserSubId) {
            api.deleteUser(deletedUserSubId, deletedUserSubId);
          }
          state.providers = state.providers.filter((provider) => provider.id !== deletedproviderId);
          state.filteredProviders = state.filteredProviders.filter((provider) => provider.id !== deletedproviderId);
        }
      })
      .addCase(createPortalUserAsync.fulfilled, (state, action) => {
        const providerId = action.payload.id; // Assuming the payload contains `id` of the provider
        const subID = action.payload.subID; // Assuming the payload contains the `subId` to update

        if (providerId) {
          // Find index of provider with the matching providerId
          const providerIndex = state.providers.findIndex((provider) => provider.id === providerId);

          if (providerIndex !== -1) {
            // Update subId within the found provider
            state.providers[providerIndex].subID = subID;
            // If you need to do the same for `filteredProviders`, repeat the same logic
            const filteredProviderIndex = state.filteredProviders.findIndex((provider) => provider.id === providerId);

            if (filteredProviderIndex !== -1) {
              state.filteredProviders[filteredProviderIndex].subID = subID;
            }
          }
        }
      })
      .addCase(updateProviderClientAsync.fulfilled, (state, action) => {
        console.log(state.providers);
        // const deletedproviderId = action.payload;
        // state.providers = state.providers.filter((provider) => provider.id !== deletedproviderId);
        // state.filteredProviders = state.filteredProviders.filter((provider) => provider.id !== deletedproviderId);
      })
      .addMatcher(
        (action) =>
          [updateProviderAsync, updateProviderNoteAsync].some((thunk) => action.type.startsWith(thunk.fulfilled.type)),
        (state, action) => {
          const updatedprovider = action.payload;

          if (updatedprovider) {
            const existingproviderIndex = state.providers.findIndex((provider) => provider.id === updatedprovider.id);
            if (existingproviderIndex !== -1) {
              state.providers[existingproviderIndex] = updatedprovider;
            }
            const existingFilteredProvidersIndex = state.filteredProviders.findIndex(
              (provider) => provider.id === updatedprovider.id
            );
            if (existingFilteredProvidersIndex !== -1) {
              state.filteredProviders[existingFilteredProvidersIndex] = updatedprovider;
            }
          }
        }
      );
  },
});

export const { setFilteredProviders, setNewUserProvider, setOpenCreatorProviderModal } = providerSlice.actions;

export default providerSlice.reducer;
