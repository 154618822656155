import { TEST_SOURCE_OPTIONS } from "constant";
import { DEFAULT_WHITE_LABEL_USRERS_PERMISSIONS } from "constant";
import {
  AMAZON_CLIENT_RESULT,
  CONFIG,
  CUSTOM_INS,
  ELIGIBILITY_STATUS_COLOR,
  ELIGIBILITY_STATUS_VALUE,
  EXPORT_TO_CALREDIE,
  EXPORT_TO_CALREDIE_RESULT,
  LAB_ORDER_STATUS,
  NOT_INC_KEYS,
  PRE_REG_PERSONALIZE,
  RECEIVED_BYLAB,
  RESULT_COLOR,
  RESULT_PDF,
  TEST_TYPE_VALUE,
  defaultPersonalization,
  ALTA_ID,
  ALTA_NEW_ID,
  NEW_DISCOVERY_ID,
  FUTURE_GENERATION_ID,
  TAB_NAMES,
} from "constant";
import { PROVIDER_PERSONALIZE } from "constants/personalization";
import { ORDER_PERSONALIZE, TEST_DONE_MODAL_COLUMN_DETAILS } from "constants/personalization";
import { COMPLETED_RESULT_PERSONALIZE } from "constants/personalization";
import { PENDING_RESULT_PERSONALIZE } from "constants/personalization";
import { LAB_CLIENT_PERSONALIZE } from "constants/personalization";
import { LAB_MEMBER_PERSONALIZE } from "constants/personalization";
import FileSaver from "file-saver";
import parsePhoneNumber, { AsYouType, isValidNumber } from "libphonenumber-js";
import moment from "moment";
import Papa from "papaparse";
import postalCodes from "postal-codes-js";
import { jsonToCSV } from "react-papaparse";
import { formatPhoneNumberIntl, isPossiblePhoneNumber } from "react-phone-number-input";
import { customKeyBy } from "util/customLodash";
import { customCloneDeep } from "util/customLodash";
import { customPick } from "util/customLodash";
import { customIsJsonString } from "util/customLodash";
import {
  PROFILE_REQUESTED,
  INDIVIDUAL_REQUESTED,
  MICROBIOLOGY,
  MICROBIOLOGY_CEROCLINIX,
  CYTOLOGY,
  BIOPSY,
  PRESUMPTIVE_ELIGIBILITY,
} from "biolabRequisitionFormConstant";
import { TYPE_OF_TEST } from "constant";
import { PANEL_RESULT_TEST_PERSONALIZE } from "constants/personalization";
import { TEST_PANEL_TYPES } from "constant";
import { TEST_HISTORY_MODAL_COLUMN } from "constants/personalization";
import { CALIBER_ID } from "constant";
import { SEROCLINIX_ID, ANTIBIOTIC_RESISTANCE_GENES_TYPE, SEROCLINIX_TEST } from "constant";
import {
  findBestMatchingRange,
  genderOBJ,
  getGroupMatchScore,
  getMatchScore,
  isWithinAgeRange,
} from "./util/rangeUtil";
import { ANTIBIOTIC_RESISTANCE_TYPE } from "./constant";
import { customGroupBy } from "util/customLodash";
import { customFlatMap } from "util/customLodash";
import { customFind } from "util/customLodash";

const { v4: uuidv4 } = require("uuid");

export const SYSTEM_TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const formatPhoneNumber = (phone_number_value, ccCode, viewAbel) => {
  try {
    let phone_number = phone_number_value;
    if (!phone_number?.includes("+")) {
      phone_number = ccCode ? `${ccCode}${phone_number}` : `+1${phone_number}`;
    }
    const phone = formatPhoneNumberIntl(phone_number);
    if (viewAbel) return phone;
    return phone.replace(/\s/g, "");
  } catch (err) {
    console.log("Error", err);
  }
  return phone_number_value;
};

export const getValidSpaces = (name) => {
  if (!name) return "";
  return name.replace(/  +/g, " ");
};

export const formatPhone = (phone_number_value, ccCode) => {
  try {
    if (!phone_number_value) return "";

    let phone_number = phone_number_value?.replace(/[^0-9+]/g, "");
    if (!phone_number?.includes("+")) {
      phone_number = ccCode ? `${ccCode}${phone_number}` : `+1${phone_number}`;
    }
    const phone = parsePhoneNumber(phone_number);
    return phone?.formatNational() || "";
  } catch (err) {
    console.log("Error", err);
  }
  return phone_number_value;
};

export const formatPhoneWithOutSpaces = (phone_number_value, ccCode) => {
  try {
    let phone_number = phone_number_value;
    if (!phone_number?.includes("+")) {
      phone_number = ccCode ? `${ccCode}${phone_number}` : `+1${phone_number}`;
    }
    return phone_number;
  } catch (err) {
    console.log("Error", err);
  }
  return phone_number_value;
};

export const processAndAddColumnsIntoCsvFiles = async (files, additionalObj, uploadStatus) => {
  return await Promise.all(
    files.map((file, index) => {
      return new Promise((resolve, reject) => {
        Papa.parse(file, {
          complete: (results) => {
            const modifiedData = results.data
              .filter((row) => Object.keys(row).length > 0) // Filter out completely empty rows
              .map((row) => {
                let newRow = {
                  ...row,
                  ...additionalObj,
                  localID: uuidv4(),
                  fileID: uploadStatus[index].id,
                };
                return newRow;
              });
            resolve({ index, items: modifiedData });
          },
          header: true,
        });
      });
    })
  );
};

export const phoneFormatter = (phone) => {
  if (phone) {
    const d = setPhoneNo(phone);
    return new AsYouType("US").input(d);
  }
  return phone;
};

export const getValidName = (name) => {
  if (!name) return "";
  return capitalizeLetter(name.replace(/  +/g, " "));
};

export const isValidPhone = (phone) => isValidNumber(phone, "US");

export const isValidPhoneWithCode = (phone) => {
  if (phone) return isPossiblePhoneNumber(phone);
  return false;
};
export const isValidZipCode = (code, zip) => {
  if (!zip) return false;
  const validate = postalCodes.validate(`${code}`, `${zip}`);

  return typeof validate !== "string" ? validate : false;
};
export const zipCodeValidationUS = (zip) => {
  if (!zip || !zip.trim()) return false;
  const zipCode = zip.replace(/[^0-9]/g, "");
  if (zipCode.length === 5 || zipCode.length === 9) return true;
  return false;
};

export const getPageNumbers = (items) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(items.length / usersPerPage); i++) {
    pageNumbers.push(i);
  }
  return pageNumbers;
};

export const parseBooleanValue = (val) => (val ? "Yes" : "No");

export const parseIntValue = (val) => {
  if (typeof variable === "undefined" || variable === null) {
    return "0";
  }
  parseInt(val).toString();
};

export const parseBooleanVal = (val) => {
  if (val) {
    if (typeof val === "string" && val === "false") return false;
    return true;
  }
  return false;
};

export const isValidIDNumber = (val) => {
  if (!val) return false;
  if (new RegExp(/^(?=.*[0-9])([a-zA-Z0-9]{8,})+$/).test(val)) {
    return true;
  }
  return false;
};

export const convertToUpper = (val) => {
  if (val) return val.toUpperCase();
  return val;
};

export const convertToLower = (val) => {
  const trimmedVal = val.trim();
  return trimmedVal ? trimmedVal.toLowerCase() : "";
};

export const formattedString = (str) => {
  if (typeof str !== "string" || !str.trim()) {
    return "";
  }

  if (str.length <= 3) {
    return str.toUpperCase();
  }

  return str.replace(/(\b\w{1,3}\b|\b\w{4,}\b)/g, (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
};

export const capitalizeLetter = (str) => {
  if (typeof str !== "string" || !str.trim()) {
    return "";
  }

  return str.replace(/(\b\w{1,3}\b|\b\w{4,}\b)/g, (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
};

export const getCrewsName = (empDemos) => {
  // console.log(typeof empDemos);
  if (!empDemos) return "";
  let parsed = typeof empDemos === "string" ? JSON.parse(empDemos) : empDemos;
  let first = parsed.firstName.charAt(0).toUpperCase() + parsed.firstName.slice(1).toLowerCase();
  let last = parsed.lastName.charAt(0).toUpperCase() + parsed.lastName.slice(1).toLowerCase();
  return `${first} ${last}`;
};

export const testAppVersion = (test) => {
  if (test.appVer) return test.appVer;

  const val = test.sr_no;
  if (!val) return "-";
  if (!isNaN(val)) return "-";
  try {
    const obj = typeof val === "string" ? JSON.parse(val) : val;
    if (obj.version) {
      return obj.version;
    }
  } catch (error) {
    console.log("Error ", error);
  }
  return "-";
};

export const removeSpaceIns = (name) => {
  if (!name) return "";
  return name.toLowerCase().replace(/\s/g, "");
};

export const setProceduresFromDate = (claimToBeEdited, date) => {
  const claim = { ...claimToBeEdited };
  const proc_arr_to_save = claim.proc_array || [];
  for (let i = 0; i < proc_arr_to_save.length; i++) {
    const obj = { ...proc_arr_to_save[i] };
    obj.from_date = date;
    proc_arr_to_save.splice(i, 1, obj);
  }
  Object.assign(claimToBeEdited, {
    proc_array: proc_arr_to_save,
    from_date_1: date,
  });
  return claimToBeEdited;
};

export const parseErrorMessageString = (input) => {
  if (!input) return [];
  const errorMessages = input.split("|");
  let parsedData = [];

  errorMessages.forEach((errorMessage) => {
    const [code, _, field, message] = errorMessage.split(":");
    const obj = { code, field, message };
    if (errorMessage.includes("(resolve)")) {
      Object.assign(obj, { resolve: "(resolve)" });
    }
    parsedData.push(obj);
  });

  if (parsedData.length === 1 && !parsedData[0].field && !parsedData[0].messages) {
    const errorMessages = input.split(",");
    const nestedPushed = [];
    errorMessages.forEach((errorMessage) => {
      const [code, _, field, message] = errorMessage.split(":");
      const obj = { code, field, message };
      if (errorMessage.includes("(resolve)")) {
        Object.assign(obj, { resolve: "(resolve)" });
      }
      nestedPushed.push(obj);
    });
    parsedData = nestedPushed;
  }

  return parsedData;
};

export const createErrorMessageString = (input) => {
  if (!input || !Array.isArray(input) || input.length === 0) return "";

  const errorMessages = input.map((error) => {
    if (!error) return "";
    const { code, field, message, resolve } = error;
    return `${code ? `${code}:` : ""}${field ? `${field}:` : ""}${message || ""}${resolve || ""}`;
  });

  return errorMessages.join("|");
};

export const isJSONString = (string) => {
  if (!string || !string.trim()) return false;
  try {
    JSON.parse(string);
    return true;
  } catch (error) {
    return false;
  }
};

export const getDiagonosisCode = (str) => {
  return isJSONString(str) ? JSON.parse(str).code : str || "";
};

export const getDiagonosisInternalCodeValue = (str) => {
  return isJSONString(str) ? JSON.parse(str).internalCode || JSON.parse(str).code : str || "";
};

export const getIntVal = (val) => {
  if (!val) return 0;
  try {
    return parseInt(val);
  } catch (err) {
    return 0;
  }
};

export const parseStringIntoFloat = (str) => {
  if (!str || !str.trim()) return;
  return str.replace(/[^0-9.]/g, "");
};

export const formatTest = (testData, clients, employees) => {
  let demos = parseJSONObject(testData.employee_demographics);

  let resultDetails = parseStringToJSON(testData.resultDetails);

  let name;
  let firstName;
  let lastName;
  let dob;
  let isVaccinated;
  let employeeType;
  let preRegTest;
  let labStatusTest;
  let transitionStatus;
  let note;
  let clinicalComments;
  let labComments;
  let orderDate;
  let orderNo;
  const { providerID, providerName } = demos || {};

  if (demos) {
    name = demos.firstName + " " + demos.lastName;
    firstName = capitalizeLetter(demos.firstName);
    lastName = capitalizeLetter(demos.lastName);
    dob = formatDateOfBirthDOB(demos.dob);
    isVaccinated = demos?.isVaccinated;
    employeeType = demos?.employeeType;
    clinicalComments = demos?.clinicalcomment;
    labComments = demos?.comment;
    orderDate = demos?.orderDate;
    orderNo = demos?.orderNo;
  }
  testData.labOrderDetail = parseStringToJSON(testData.labOrderDetail);

  if (employees) {
    const emp = employees.find((e) => e.id === testData.employeeID);

    if (emp) {
      employeeType = emp.employeeType;
    } else {
      console.log("Employee not found ", testData);
    }
  }

  if (typeof employeeType === "undefined") {
    if (testData?.employeeType) {
      employeeType = testData?.employeeType;
    }
  }

  const test = { ...testData };

  preRegTest = test.createSource === "PR" ? true : false;
  labStatusTest = LAB_ORDER_STATUS[test.labOrderDetail?.status] || "0";
  Object.assign(test, { preRegTest, labStatusTest });
  test.viewedResult = testData.result;
  if (clients && clients.length > 0) {
    const client = clients.find((c) => c.id === test.clientID);
    if (client && client.resultType === "P/F") {
      test.viewedResult = AMAZON_CLIENT_RESULT[test.result];
    }
  }

  if (testData.result) {
    test.pcrStatus = RECEIVED_BYLAB;
  }

  if (test.test_type === "PCR") {
    if (test.scanByLabUserName) {
      transitionStatus = "Accepted";
    } else if (test.scanByTesterName) {
      transitionStatus = "In-transit";
    } else {
      transitionStatus = "Pending";
    }
  } else {
    transitionStatus = "";
  }

  if (!CONFIG.isWhiteLabel) {
    test.status =
      testData.status?.toLowerCase() === "pending" && testData.test_type === "PCR" && !testData.result
        ? "New"
        : testData.status;
  } else {
    if (testData.status?.toLowerCase() === "sent") {
      test.status = "Processing";
    }

    if (testData.result && testData.result !== "Cancelled") {
      test.status = "Completed";
    }
  }
  test.pcrStatus = getValidDep(test.pcrStatus);
  test.test_type = testData.test_type === "Other" ? "Rapid PCR" : testData.test_type;

  let receivedDate = "";

  try {
    const arr = JSON.parse(test.sr_no);
    if (
      test.pcrStatus === "Received By Lab" ||
      ((test.pcrStatus === "Send Out" || test.pcrStatus === "Sample Received") && test.scanByLabUserName)
    ) {
      receivedDate = arr.length > 1 ? arr[1]?.date : arr[0]?.date;
    }
    if (arr.length === 1) {
      dateOfReceive = moment(arr[0]?.date).add("30", "minutes").toISOString();
    }
  } catch (error) {}

  return {
    ...test,
    resultDetails,
    ...(test.StampByName && { stampByName: test.StampByName }),
    note,
    name,
    firstName,
    lastName,
    dob,
    sex: demos?.sex,
    street: demos?.street,
    street2: demos?.street2,
    state: demos?.state,
    city: demos?.city,
    idNumber: demos?.idNumber,
    zip: demos?.zip,
    isReview: demos?.isReview,
    employee_demographics: demos,
    isVaccinated,
    employeeType,
    phoneNumber: formatPhoneNumber(test.phoneNumber, demos?.countryCode),
    transitionStatus,
    receivedDate,
    providerID,
    providerName,
    clinicalComments,
    labComments,
    orderDate,
    orderNo,
    testDate: test?.timerStatus ? test?.resultDateTime : null,
  };
};

export const formatOrders = (orderData, isSiteUser) => {
  let demos =
    typeof orderData.employee_demographics === "string"
      ? JSON.parse(orderData.employee_demographics)
      : orderData.employee_demographics;

  let testsToDo = typeof orderData.testsToDo === "string" ? JSON.parse(orderData.testsToDo) : orderData.testsToDo;

  let eligibilityStatus =
    typeof orderData.eligibilityStatus === "string"
      ? JSON.parse(orderData.eligibilityStatus)
      : orderData.eligibilityStatus;

  const isArray = testsToDo && Array.isArray(testsToDo);
  const testList = (isArray ? testsToDo : testsToDo?.test) || [];
  const test = testList[0] || {};
  const { result, testID } = test;

  const testType = testList && testList?.length > 1 ? "Multi" : testList[0] ? testList[0]?.label : null;

  const processedTest = testList.filter((f) => f.result)?.length || 0;
  const releasedTest = testList.filter((f) => f.result && f.releaseStatus === "Confirmed")?.length || 0;

  delete demos?.status;
  delete demos?.id;

  return {
    ...orderData,
    ...demos,
    tests: testList.length,
    processedTest,
    releasedTest,
    test_type: testType,
    testsToDo,
    eligibilityStatus,
    testID,
    result,
    employee_demographics: demos,
  };
};

export const calculateStringSimilarity = (str1, str2) => {
  const matrix = [];

  if (!str1 && !str2) return 0;

  for (let i = 0; i <= str1.length; i++) {
    matrix[i] = [i];
    for (let j = 1; j <= str2.length; j++) {
      if (i === 0) {
        matrix[i][j] = j;
      } else {
        const cost = str1[i - 1] !== str2[j - 1] ? 1 : 0;
        matrix[i][j] = Math.min(matrix[i - 1][j] + 1, matrix[i][j - 1] + 1, matrix[i - 1][j - 1] + cost);
      }
    }
  }

  const maxLen = Math.max(str1.length, str2.length);
  const distance = matrix[str1.length][str2.length];
  return 1 - distance / maxLen;
};

export const isSimilarPayer = (provider, payerName) => {
  const similarityThreshold = 0.8;
  const { label, payer_alt_names } = provider;

  if (label.toLowerCase() === payerName.toLowerCase()) {
    return true;
  }

  if (calculateStringSimilarity(label.toLowerCase(), payerName.toLowerCase()) > similarityThreshold) {
    return true;
  }

  if (payer_alt_names) {
    const similarAltNames = payer_alt_names.some(
      (altName) =>
        altName.alt_payer_name &&
        calculateStringSimilarity(altName.alt_payer_name.toLowerCase(), payerName.toLowerCase()) > similarityThreshold
    );

    if (similarAltNames) {
      return true;
    }
  }

  return false;
};

export const formatCurrency = (val) => {
  if (!val) return "$0.00";
  const obj = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return obj.format(val);
};

export const formatPercentage = (val) => {
  if (val == null) return "0%";
  const formatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(val);
};

export const parseCurrency = (val) => {
  if (!val) return 0;
  const parsedValue = val.toString().replace(/[^0-9.]/g, "");
  return parseFloat(parsedValue) || 0;
};

export const formatExternalTest = (testData, clients) => {
  let demos =
    typeof testData.employee_demographics === "string"
      ? JSON.parse(testData.employee_demographics)
      : testData.employee_demographics;
  let name;
  let firstName;
  let lastName;
  let phoneNumber = testData.phoneNumber;
  if (demos) {
    name = demos.firstName + " " + demos.lastName;
    firstName = capitalizeLetter(demos.firstName);
    lastName = capitalizeLetter(demos.lastName);
    phoneNumber = `${demos.countryCode}${demos.phoneNumber}`;
  }

  const testType = TEST_TYPE_VALUE[testData.test_type];

  return {
    ...testData,
    phoneNumber,
    name,
    firstName,
    lastName,
    test_type: testType || testData.test_type,
  };
};

export const getAMPM = (date) => {
  if (!date) return "";
  const hours = new Date(date).getHours();
  return hours >= 12 ? "PM" : "AM";
};

export const toLowerCase = (val) => {
  if (val) val.toLowerCase();
  return val;
};

export const validSpace = (value) => {
  if (!value) return "";
  return value.replace(/  +/g, " ");
};

export const isValidName = (name) => {
  return true;
  if (!name) return false;
  if (new RegExp(/^(?!.*([a-z])\1{2})[A-Za-z ]{2,75}$/i).test(name) && window.navigator.language === "en-US") {
    return true;
  }
  if (window.navigator.language === "ja-JP" && new RegExp(/^.{2,75}$/i).test(name)) return true;
  return true;
};
export const isValidState = (state) => {
  return true;
  if (!state) return false;
  if (new RegExp(/^[A-Z]{2}$/).test(state)) {
    return true;
  } else return false;
};
export const isValidIdNum = (id) => {
  return true;
  if (!id) return false;
  if (new RegExp(/^(?!.*([a-zA-Z0-9])\1{4})[a-zA-Z0-9]{5,16}$/).test(id)) {
    return true;
  } else return false;
};
export const isValidDigits = (num) => {
  if (!num) return false;
  if (new RegExp(/^[1-9]\d*$/g).test(num)) {
    return true;
  }
  return false;
};
export const isValidBarcodeNumber = (num) => {
  if (!num) return false;
  if (new RegExp(/^\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(?!0000)\d{4}$/).test(num)) {
    return true;
  }
  return false;
};

export const testTypeValid = (test, TestTypeDuaration) => {
  if (!test) return "";
  if (!TestTypeDuaration) return "";
  const { employee_demographics } = test;
  const findval = JSON.parse(TestTypeDuaration).find((c) => c.testType === employee_demographics.externalTestType);
  if (!findval) return "";
  const date = new Date(test.createdAt);
  date.setHours(date.getHours() + parseInt(findval.hours));
  if (date <= new Date()) {
    return "EXPIRED";
  }
  return moment(date).format("MM-DD-YYYY HH:mm:ss");
};
export const getDropDownValue = (val) => {
  if (val === null || val === undefined) return null;
  if (val) return { value: true, label: "Yes" };
  return { value: false, label: "No" };
};

export const getDemoGraphics = (test) => {
  if (!test) return;

  const demos = parseJSONObject(test.employee_demographics);
  const ins = parseJSONObject(demos.insuranceDetails);
  const eligStatus = parseJSONObject(demos.eligibilityStatus);
  const insDetails = parseInsDetails(ins, demos);

  return {
    ...demos,
    insuranceDetails: insDetails,
    // ...(!demos?.swabtype && { swabtype: "oral" }),
    eligibilityStatus: eligStatus,
  };
};

export const formatDateOfBirth = (dob) =>
  dob ? `${dob.substring(0, 2)}/${dob.substring(2, 4)}/${dob.substring(4)}` : "";

export const getAge = (dob) => {
  const val = formatDateOfBirthDOB(dob);
  return dob ? moment().diff(val, "years") : "";
};
export const isValidDate = (val) => {
  const date = val.replace(/\/|-|,/g, "");
  if (date?.length === 8) {
    const day = date.substring(0, 2);
    const month = date.substring(2, 4);
    const year = date.substring(4);
    const testDate = moment(`${year}-${month}-${day}`).format("YYYY-MM-DD");
    if (testDate !== "Invalid date") {
      return true;
    }
    return false;
  }
  return false;
};

export const isValidDobField = (dob, format) => {
  if (!dob) return false;
  return moment(dob, `${format}`, true).isValid();
};
export const setDate = (val) => {
  if (!val) return;
  const date = val.replace(/\/|-|,/g, "");
  const day = date.substring(0, 2);
  const month = date.substring(2, 4);
  const year = date.substring(4);
  return moment(`${year}-${month}-${day}`).format();
};

export const formatDate = (date) => (date ? moment(date).format("DD/MM/YYYY") : "");

export const formatDOB = (date) => (date ? moment(date).format("MM/DD/YYYY") : "");

export const formatDateMDY = (date) => (date ? moment(date).format("MM/DD/YYYY") : "");

export const formatTimeFromDate = (date) => (date ? moment(date).format("hh:mm A") : "");

export const formatUTCDate = (date) => (date ? moment(date).format("MM-DD-YYYY") : "");

export const formatLogDate = (date) => (date ? moment(date).format("MM-DD-YYYY HH:mm:ss") : "");

export const formatOrderDate = (date) => (date ? moment(date).format("MM-DD-YYYY hh:mm A") : "");

export const formatReportDate = (date) => (date ? moment(date).format("MM-DD-YYYY HH:mm") : "");

export const formateDateLL = (date) => (date ? moment(date).format("LL") : "");

export const formateDateDDMMMYYYY = (date) => (date ? moment(date).format("MMM DD, YYYY") : "");

export const formateDatellll = (date) => (date ? moment(date).format("llll") : "");

export const currentDate = () => moment().format("MM/DD/YYYY");

export const formatPDFName = (demos) => {
  let name = "";
  if (demos.lastName) name = `${demos.lastName.substring(0, 1)}${demos.lastName.substring(1).toLowerCase()}`;
  if (name) name = `${name}, `;
  if (demos.firstName) name = `${name}${demos.firstName.substring(0, 1)}${demos.firstName.substring(1).toLowerCase()}`;
  return name;
};

export const sortByAlphaOrder = (list, key) => {
  return [...list].sort((a, b) => {
    const testerB = b[key] ?? "";
    const testerA = a[key] ?? "";
    return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
  });
};

export const changeSort = (
  sortByKey,
  sortDescending,
  filteredTests,
  setFilteredTests,
  getSiteName,
  getLabName,
  getClientName,
  parseTestResult
) => {
  let sortBy = sortByKey;

  if (sortByKey === "client") sortBy = "site_name";

  switch (sortBy) {
    case "employee_demographics":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getDemoGraphics(b).lastName < getDemoGraphics(a).lastName
                ? 1
                : getDemoGraphics(a).lastName < getDemoGraphics(b).lastName
                ? -1
                : 0
            )
          : [...filteredTests].sort((a, b) =>
              getDemoGraphics(b).lastName > getDemoGraphics(a).lastName
                ? 1
                : getDemoGraphics(a).lastName > getDemoGraphics(b).lastName
                ? -1
                : 0
            )
      );
      break;
    case "clientID":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getClientName(b[sortBy]) < getClientName(a[sortBy])
                ? 1
                : getClientName(a[sortBy]) < getClientName(b[sortBy])
                ? -1
                : 0
            )
          : [...filteredTests].sort((a, b) =>
              getClientName(b[sortBy]) > getClientName(a[sortBy])
                ? 1
                : getClientName(a[sortBy]) > getClientName(b[sortBy])
                ? -1
                : 0
            )
      );
      break;
    case "siteID":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getSiteName(b[sortBy]) < getSiteName(a[sortBy])
                ? 1
                : getSiteName(a[sortBy]) < getSiteName(b[sortBy])
                ? -1
                : 0
            )
          : [...filteredTests].sort((a, b) =>
              getSiteName(b[sortBy]) > getSiteName(a[sortBy])
                ? 1
                : getSiteName(a[sortBy]) > getSiteName(b[sortBy])
                ? -1
                : 0
            )
      );
      break;
    case "labID":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              getLabName(b[sortBy]) < getLabName(a[sortBy]) ? 1 : getLabName(a[sortBy]) < getLabName(b[sortBy]) ? -1 : 0
            )
          : [...filteredTests].sort((a, b) =>
              getLabName(b[sortBy]) > getLabName(a[sortBy]) ? 1 : getLabName(a[sortBy]) > getLabName(b[sortBy]) ? -1 : 0
            )
      );
      break;
    case "result":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              parseTestResult(b) < parseTestResult(a) ? 1 : parseTestResult(a) < parseTestResult(b) ? -1 : 0
            )
          : [...filteredTests].sort((a, b) =>
              parseTestResult(b) > parseTestResult(a) ? 1 : parseTestResult(a) > parseTestResult(b) ? -1 : 0
            )
      );
      break;
    case "employeeID":
    case "status":
    case "test_type":
    case "barcode":
    case "phoneNumber":
    case "phone":
    case "email":
    case "name":
    case "firstName":
    case "lastName":
    case "site_name":
    case "tester_name":
    case "scanByLabUserName":
    case "providerName":
    case "isExternalTest":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) => {
              const testerB = (b[sortBy] ?? "").toString().toLowerCase();
              const testerA = (a[sortBy] ?? "").toString().toLowerCase();
              return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
            })
          : [...filteredTests].sort((a, b) => {
              const testerB = (b[sortBy] ?? "").toString().toLowerCase();
              const testerA = (a[sortBy] ?? "").toString().toLowerCase();
              return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
            })
      );
      break;
    case "sequenceNo":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) => {
              return parseInt(b[sortBy]) < parseInt(a[sortBy]) ? 1 : parseInt(a[sortBy]) < parseInt(b[sortBy]) ? -1 : 0;
            })
          : [...filteredTests].sort((a, b) => {
              return parseInt(b[sortBy]) > parseInt(a[sortBy]) ? 1 : parseInt(a[sortBy]) > parseInt(b[sortBy]) ? -1 : 0;
            })
      );
      break;
    case "resultDate":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy] ? b[sortBy] : b.createdAt;
              const testerA = a[sortBy] ? a[sortBy] : a.createdAt;
              return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
            })
          : [...filteredTests].sort((a, b) => {
              const testerB = b[sortBy] ? b[sortBy] : b.createdAt;
              const testerA = a[sortBy] ? a[sortBy] : a.createdAt;
              return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
            })
      );
      break;
    case "totalTests":
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) =>
              parseTestResult(b.id) < parseTestResult(a.id) ? 1 : parseTestResult(a.id) < parseTestResult(b.id) ? -1 : 0
            )
          : [...filteredTests].sort((a, b) =>
              parseTestResult(b.id) > parseTestResult(a.id) ? 1 : parseTestResult(a.id) > parseTestResult(b.id) ? -1 : 0
            )
      );
      break;
    default:
      setFilteredTests(
        sortDescending
          ? [...filteredTests].sort((a, b) => (b[sortBy] < a[sortBy] ? 1 : a[sortBy] < b[sortBy] ? -1 : 0))
          : [...filteredTests].sort((a, b) => (b[sortBy] > a[sortBy] ? 1 : a[sortBy] > b[sortBy] ? -1 : 0))
      );
  }
};

export const sortTestList = (
  sortBy,
  sortDescending,
  filteredTests,
  getSiteName,
  getLabName,
  getClientName,
  parseTestResult
) => {
  switch (sortBy) {
    case "employee_demographics":
      return sortDescending
        ? [...filteredTests].sort((a, b) =>
            getDemoGraphics(b).lastName < getDemoGraphics(a).lastName
              ? 1
              : getDemoGraphics(a).lastName < getDemoGraphics(b).lastName
              ? -1
              : 0
          )
        : [...filteredTests].sort((a, b) =>
            getDemoGraphics(b).lastName > getDemoGraphics(a).lastName
              ? 1
              : getDemoGraphics(a).lastName > getDemoGraphics(b).lastName
              ? -1
              : 0
          );

    case "clientID":
      return sortDescending
        ? [...filteredTests].sort((a, b) =>
            getClientName(b[sortBy]) < getClientName(a[sortBy])
              ? 1
              : getClientName(a[sortBy]) < getClientName(b[sortBy])
              ? -1
              : 0
          )
        : [...filteredTests].sort((a, b) =>
            getClientName(b[sortBy]) > getClientName(a[sortBy])
              ? 1
              : getClientName(a[sortBy]) > getClientName(b[sortBy])
              ? -1
              : 0
          );
    case "siteID":
      return sortDescending
        ? [...filteredTests].sort((a, b) =>
            getSiteName(b[sortBy]) < getSiteName(a[sortBy])
              ? 1
              : getSiteName(a[sortBy]) < getSiteName(b[sortBy])
              ? -1
              : 0
          )
        : [...filteredTests].sort((a, b) =>
            getSiteName(b[sortBy]) > getSiteName(a[sortBy])
              ? 1
              : getSiteName(a[sortBy]) > getSiteName(b[sortBy])
              ? -1
              : 0
          );
    case "labID":
      return sortDescending
        ? [...filteredTests].sort((a, b) =>
            getLabName(b[sortBy]) < getLabName(a[sortBy]) ? 1 : getLabName(a[sortBy]) < getLabName(b[sortBy]) ? -1 : 0
          )
        : [...filteredTests].sort((a, b) =>
            getLabName(b[sortBy]) > getLabName(a[sortBy]) ? 1 : getLabName(a[sortBy]) > getLabName(b[sortBy]) ? -1 : 0
          );
    case "result":
      return sortDescending
        ? [...filteredTests].sort((a, b) =>
            parseTestResult(b) < parseTestResult(a) ? 1 : parseTestResult(a) < parseTestResult(b) ? -1 : 0
          )
        : [...filteredTests].sort((a, b) =>
            parseTestResult(b) > parseTestResult(a) ? 1 : parseTestResult(a) > parseTestResult(b) ? -1 : 0
          );
    case "employeeID":
    case "status":
    case "test_type":
    case "barcode":
    case "phoneNumber":
    case "phone":
    case "email":
    case "name":
    case "firstName":
    case "lastName":
    case "tester_name":
      return sortDescending
        ? [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] ?? "";
            const testerA = a[sortBy] ?? "";
            return testerB < testerA ? 1 : testerA < testerB ? -1 : 0;
          })
        : [...filteredTests].sort((a, b) => {
            const testerB = b[sortBy] ?? "";
            const testerA = a[sortBy] ?? "";
            return testerB > testerA ? 1 : testerA > testerB ? -1 : 0;
          });
    case "sequenceNo":
      return sortDescending
        ? [...filteredTests].sort((a, b) => {
            return parseInt(b[sortBy]) < parseInt(a[sortBy]) ? 1 : parseInt(a[sortBy]) < parseInt(b[sortBy]) ? -1 : 0;
          })
        : [...filteredTests].sort((a, b) => {
            return parseInt(b[sortBy]) > parseInt(a[sortBy]) ? 1 : parseInt(a[sortBy]) > parseInt(b[sortBy]) ? -1 : 0;
          });
    case "totalTests":
      return sortDescending
        ? [...filteredTests].sort((a, b) =>
            b.tests?.length < a.tests?.length ? 1 : a.tests?.length < b.tests?.length ? -1 : 0
          )
        : [...filteredTests].sort((a, b) =>
            b.tests?.length > a.tests?.length ? 1 : a.tests?.length > b.tests?.length ? -1 : 0
          );
    default:
      return sortDescending
        ? [...filteredTests].sort((a, b) => (b[sortBy] < a[sortBy] ? 1 : a[sortBy] < b[sortBy] ? -1 : 0))
        : [...filteredTests].sort((a, b) => (b[sortBy] > a[sortBy] ? 1 : a[sortBy] > b[sortBy] ? -1 : 0));
  }
};

export const isValidEmail = (email) =>
  new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ).test(email);
export const checkValidity = (name) => {
  if (!name) return "";
  return name.replace(/[^A-Z0-9]/gi, "");
};
export const isValidFile = (file) => {
  const fileName = file.name;

  const exts = ["png", "jpg", "jpeg", "gif"];

  if (fileName) {
    let getExt = fileName.split(".");
    getExt = getExt.reverse();

    if (!exts.includes(getExt[0].toLowerCase())) {
      return "only image files are allowed";
    }

    if (file.size / 1024 / 1020 > 10) {
      return "max. 10MB file size allow";
    }

    return "";
  }
  return "";
};

export const setPhoneNo = (phone) => {
  if (!phone) return null;
  return phone.includes("+1") ? `+${phone.replace(/\D/g, "")}` : `+1${phone.replace(/\D/g, "")}`;
};

export const formatZipCode = (id) => {
  if (id && id.length > 6) {
    return `${id.substring(0, 5)}`;
  }
  return id;
};

export const formatZip = (val) => {
  if (!val) {
    return "";
  }

  const input = val.replace(/[^0-9]+/g, "");
  const length = input.length;

  if (length < 9 && length > 5) {
    return input.substring(0, 5);
  }

  if (length > 5) {
    if (input.substring(5) === "0000") return input.substring(0, 5);
    return `${input.substring(0, 5)}-${input.substring(5)}`;
  }

  if (length === 5) {
    return input;
  }

  return input;
};

export const medFlowInLocalStorage = {
  save: (type) => localStorage.setItem("medFlow", type),
  get: () => localStorage.getItem("medFlow") || null,
  clear: () => localStorage.clear(),
};

export const medFlowPortalTitle = {
  save: (type) => localStorage.setItem("medFlowTitle", type),
  get: () => localStorage.getItem("medFlowTitle") || null,
  clear: () => localStorage.clear(),
};

export const roleInLocalStorage = {
  save: (type) => localStorage.setItem("role", type),
  get: () => localStorage.getItem("role") || null,
  clear: () => localStorage.clear(),
};

export const connectedIDInLocalStorage = {
  save: (type) => localStorage.setItem("connectedID", type),
  get: () => localStorage.getItem("connectedID") || null,
  clear: () => localStorage.removeItem("connectedID"),
};

export const clientIDInLocalStorage = {
  save: (type) => localStorage.setItem("clientID", type),
  get: () => localStorage.getItem("clientID") || null,
  clear: () => localStorage.removeItem("clientID"),
};

export const registrationShowSelectInLocalStorage = {
  save: (data) => localStorage.setItem("showSelected", data),
  get: () => JSON.parse(localStorage.getItem("showSelected")) || null,
  clear: () => localStorage.removeItem("showSelected"),
};

export const sortingFilterInLC = {
  save: (obj) => localStorage.setItem("medflowmd", JSON.stringify(obj)),
  get: () => {
    if (localStorage.getItem("medflowmd")) {
      return JSON.parse(localStorage.getItem("medflowmd"));
    }
    return {
      alltests: {},
      unprocessedtest: {},
      senttest: {},
      pendingreleasetest: {},
      totaltests: {},
      totaltest: {},
      expiredtests: {},
      processing: {},
      processedtests: {},
      positivetest: {},
      quarantine: {},
      tests: {},
      sites: {},
      labs: {},
      clients: {},
      users: {},
      crews: {},
      crewhs: {},
      employees: {},
      crewprofile: {},
    };
  },
  clear: () => localStorage.removeItem("medflowmd"),
};

export const uploadPatientRecordInLC = {
  save: (data) => localStorage.setItem("uploadStatusRecord", data),
  get: () => JSON.parse(localStorage.getItem("uploadStatusRecord")) || null,
  clear: () => localStorage.removeItem("uploadStatusRecord"),
};

export const showsInSS = {
  save: (obj) => sessionStorage.setItem("medflowSs", JSON.stringify(obj)),
  get: () => {
    if (sessionStorage.getItem("medflowSs")) {
      return JSON.parse(sessionStorage.getItem("medflowSs"));
    }
    return {
      selectedShow: {},
    };
  },
  clear: () => sessionStorage.removeItem("medflowSs"),
};

export const personalisationInLC = {
  save: (key, obj) => {
    const dataInLc = localStorage.getItem("medflowpersonalisation");
    let newObj = obj;
    if (key && dataInLc && dataInLc !== "undefined") {
      const data = typeof dataInLc === "string" ? JSON.parse(dataInLc) : dataInLc;
      Object.assign(data, { [key]: obj });
      newObj = data;
    } else if (key) {
      newObj = { [key]: obj };
    }
    localStorage.setItem("medflowpersonalisation", JSON.stringify(newObj));
  },
  saveAs: (arr, key) => {
    const selectedSetting = JSON.stringify(
      arr
        .filter((f) => f.isCheck)
        .map((f) => {
          return { id: f.id, width: f.width };
        })
    );

    let objData = JSON.parse(localStorage.getItem("medflowpersonalisation"));
    if (objData) {
      objData[key] = selectedSetting;
      localStorage.setItem("medflowpersonalisation", JSON.stringify(objData));
      return objData;
    }
    const persolizeToMap = defaultPersonalization();
    if (persolizeToMap) {
      persolizeToMap[key] = selectedSetting;
      localStorage.setItem("medflowpersonalisation", JSON.stringify(persolizeToMap));
      return persolizeToMap;
    }
  },

  get: (key) => {
    const dataInLC = localStorage.getItem("medflowpersonalisation");
    if (!key) return dataInLC;

    const persolizeToMap = getPersonalizationData(key);

    if (dataInLC) {
      const data = JSON.parse(dataInLC);
      if (data[key] && Array.isArray(data[key])) {
        return persolizeToMap.map((d) => {
          return { ...d, isCheck: data[key].indexOf(d.id) !== -1 };
        });
      }

      if (data[key] && !Array.isArray(data[key])) {
        const arrData = typeof data[key] === "string" ? JSON.parse(data[key]) : data[key];

        if (arrData.length === 0) return persolizeToMap;

        const arrDataMap = new Map(arrData.map((item) => [item.id, item]));

        const sortedPersolizeToMap = persolizeToMap.slice().sort((a, b) => {
          return arrData.findIndex((item) => item.id === a.id) - arrData.findIndex((item) => item.id === b.id);
        });
        return sortedPersolizeToMap.map((d) => {
          const obj = arrDataMap.get(d.id);
          if (key === "testDetailsDone") {
            return { ...d, isCheck: true, width: obj?.width || d.width };
          }
          return { ...d, isCheck: obj ? true : false, width: obj?.width || d.width };
        });
      }
    }
    return persolizeToMap;
  },
  clear: () => localStorage.removeItem("medflowpersonalisation"),
};

export const qrResponseJOSN = (obj, empID, type) => {
  if (!obj || Object.keys(obj).length === 0) return "";
  let ph = obj.phone_number || obj.phoneNumber;
  ph = ph?.replace(obj?.countryCode ? obj.countryCode : "+1", "");

  return {
    firstName: obj.first || obj.firstName,
    lastName: obj.lastName || obj.last,
    phone: ph,
    employeeID: empID,
    email: obj.email,
    countryCode: obj.countryCode,
    dob: obj.dob,
    type: type || "MD",
  };
};

export const formatDateOfBirthDOB = (dob) => {
  if (!dob || dob == "") return "";
  if (dob.includes("-")) {
    const index = dob.indexOf("-");
    let dateFormat = "MM-DD-YYYY";
    if (index !== 2) {
      dateFormat = "YYYY-MM-DD";
    }
    return moment(dob, dateFormat).format("MM/DD/YYYY");
  }
  if (!dob.includes("-")) {
    const dobDate = FormatAWSDate(dob);
    return moment(dobDate).format("MM/DD/YYYY");
  }
  return moment(dob, "MM-DD-YYYY").format("MM/DD/YYYY");
};

export const formatAddress = (val) => {
  if (!val || (!val.city && !val.state && !val.zip)) return "";
  let arr = [];
  if (val.city) arr.push(val.city);
  if (val.state) arr.push(val.state);
  arr = [arr.join(",")];
  if (val.zip) arr.push(val.zip);
  if (val.zipcode) arr.push(val.zipcode);
  return arr.join(" ");
};

export const formatDoBForDemo = (dob) => {
  if (!dob || dob == "") return "";
  if (dob.includes("-")) {
    const index = dob.indexOf("-");
    let dateFormat = "MM-DD-YYYY";
    if (index !== 2) {
      dateFormat = "YYYY-MM-DD";
    }
    return moment(dob, dateFormat).format("MMDDYYYY");
  }
  return dob;
};
export const FormatAWSDate = (dobFromID) => {
  if (dobFromID && !dobFromID?.includes("-")) {
    const awsdob = [];
    awsdob.push(dobFromID.substring(4));
    awsdob.push(dobFromID.substring(0, 2));
    awsdob.push(dobFromID.substring(2, 4));
    return awsdob.join("-");
  }
  return dobFromID;
};
export const formatQuaratineDate = (value) => {
  if (!value || value == "") return value;
  return moment(value).format("YYYY-MM-DD");
};

export const formatQuaratineDisplayDate = (value) => {
  if (!value || value == "") return "-";
  return moment(value).format("MM-DD-YYYY");
};

export const calculateTdWidth = (width, numberOfCol) => Math.ceil(width / numberOfCol);

export const toTitleCase = (str) => {
  if (typeof str !== "string" || !str.trim()) {
    return "";
  }

  if (str.length <= 3) {
    return str.toUpperCase();
  }

  return str.replace(/(\b\w{1,3}\b|\b\w{4,}\b)/g, (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
};

export const nameToTitleCase = (str) => {
  if (typeof str !== "string" || !str.trim()) {
    return "";
  }

  if (str.length <= 2) {
    return str.toUpperCase();
  }

  return str.replace(/(\b\w{1,3}\b|\b\w{4,}\b)/g, (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
};

export const parseTestType = (test_type) => {
  if (!test_type) return "";
  return test_type === "Other" ? "Rapid PCR" : test_type;
};

export const getPersonalizationIds = (key) => {
  if (!key) return [];
  if (key === "preregistration") return PRE_REG_PERSONALIZE.map((c) => c.id);
  if (key === "employee") return LAB_MEMBER_PERSONALIZE.map((m) => ({ id: m.id, width: "100%" }));
};

export const getPersonalizationData = (key) => {
  if (!key) return [];
  if (key === "preregistration") return PRE_REG_PERSONALIZE;
  if (key === "employee") return LAB_MEMBER_PERSONALIZE;
  if (key === "labClient") return LAB_CLIENT_PERSONALIZE;
  if (key === "provider") return PROVIDER_PERSONALIZE;
  if (key === "pendingtest") return PENDING_RESULT_PERSONALIZE;
  if (key === "completedtest") return COMPLETED_RESULT_PERSONALIZE;
  if (key === "orderpersonalize") return ORDER_PERSONALIZE;
  if (key === "testDetailsDone") return TEST_DONE_MODAL_COLUMN_DETAILS;
  if (key === "testHistory") return TEST_HISTORY_MODAL_COLUMN;
  return [];
};

export const removeWhitespaceAndParentheses = (str) => {
  // Remove white spaces
  if (!str || !str.trim()) return "";
  str = str.replace(/\s/g, "");

  // Remove parentheses and their inner values
  str = str.replace(/\([^)]*\)/g, "");

  // Remove the file extension
  const lastDotIndex = str.lastIndexOf(".");
  if (lastDotIndex >= 0) {
    str = str.substring(0, lastDotIndex);
  }

  return str.toUpperCase();
};

export const getValidDep = (name) => {
  if (!name || !name.trim()) return "";
  return toTitleCase(name.replace(/  +/g, " "));
};

export const getPhoneNo = (phone_number_value, ccCode) => {
  try {
    let phone_number = phone_number_value?.replace(/\+(\d+)\+\1/g, "+$1");

    if (!phone_number?.includes("+")) {
      phone_number = ccCode ? `${ccCode}${phone_number}` : `+1${phone_number}`;
    }

    const phone = formatPhoneNumberIntl(phone_number);
    const phoneArr = phone.split(" ");
    const countryCode = phoneArr[0];
    phoneArr.splice(0, 1);
    const phoneNo = phoneArr.join("");
    return [countryCode, phoneNo, phone];
  } catch (err) {
    console.log("Error", err);
  }
  return ["", phone_number_value, phone_number_value];
};

export const parseIntVal = (val) => {
  if (val) return parseInt(val);
  return 0;
};

export const searchPhone = (phone) => {
  if (!phone) return null;
  return phone.includes("+") ? `${phone.replace(/\D/g, "")}` : `+1${phone.replace(/\D/g, "")}`;
};

export const makeAlphabetInput = (value) => {
  if (!value) return null;
  return getValidName(value);
};

export const makeBarcode = (id_number, sequenceNo) => {
  if (!id_number) {
    id_number = moment().valueOf().toString();
  }
  if (id_number && sequenceNo) {
    return id_number.concat("-", sequenceNo.toString());
  }
  return "";
};

// Generate Track URL
export const DeliveryTrackUrl = (company, trackingNo) => {
  if (!company || !trackingNo) return null;
  if (company && trackingNo) {
    if (company === "DHL") {
      return `https://www.dhl.com/us-en/home/tracking/tracking-global-forwarding.html?submit=1&tracking-id=${trackingNo}`;
    } else if (company === "FedEx") {
      return `https://www.fedex.com/fedextrack/?trknbr=${trackingNo}`;
    } else if (company === "UPS") {
      return `https://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${trackingNo}`;
    } else {
      return "";
    }
  }
  return "";
};

// Generate Track URL
export const siteStatus = (status) => {
  if (status === 0) return "Pending";
  if (status === 1) return "Active";
  if (status === 2) return "Hold";
  return "Active";
};

// image url validation
export const isValidUrl = (file) => {
  if (!file) return false;
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = typeof file === "object" ? URL.createObjectURL(file) : file;
    img.onload = () => {
      resolve(true);
    };
    img.onerror = () => {
      resolve(false);
    };
  });
};

//Get Connected ID From URL
export const connectedIDByURL = (url) => {
  let urlparams = url.split("/");
  let connectedID = "";
  let testID = "";

  if (urlparams.length === 4) {
    connectedID = urlparams[3];
  }
  if (urlparams.length === 5) {
    connectedID = urlparams[3];
    testID = urlparams[4];
  }
  return { connectedID, testID };
};

export const openSite = (siteID) => {
  // console.log("siiteID::", siteID);
  let url = `${window.location.origin}/site/tests/${siteID}`;
  window.open(url);
};

export const getConnectedToValue = (val) => {
  let parentEmployeeRelation = val || [];
  if (typeof parentEmployeeRelation === "string") {
    parentEmployeeRelation = JSON.parse(parentEmployeeRelation);
  }
  return parentEmployeeRelation;
};

export const ChangeObject = (newReord, oldRecord, testNewRecord, testOldRecord) => {
  const prevRecord = { ...oldRecord, ...testOldRecord };
  const record = { ...newReord, ...testNewRecord };

  return Object.keys(record).reduce((obj, item) => {
    if (
      record.hasOwnProperty(item) &&
      (record[item] || prevRecord[item]) &&
      record[item] !== prevRecord[item] &&
      !NOT_INC_KEYS.includes(item)
    ) {
      obj[item] = `${prevRecord[item] || ""} to ${record[item] || ""}`;
    }
    return obj;
  }, {});
};

export const createChangeObjForLogs = ({ oldRecord, newRecord, fieldsName }) => {
  const keysToPick = Object.keys(fieldsName);

  const changesArr = [];

  const findChanges = (oldObj, newObj, parentKey = "") => {
    const allKeys = new Set([...Object.keys(oldObj || {}), ...Object.keys(newObj || {})]);

    allKeys.forEach((key) => {
      const fullKey = parentKey ? `${parentKey}.${key}` : key;
      const oldVal = oldObj?.[key];
      const newVal = newObj?.[key];

      if (typeof oldVal === "object" && oldVal !== null && typeof newVal === "object" && newVal !== null) {
        findChanges(oldVal, newVal, fullKey);
      }
      // else if (oldVal !== newVal) {
      else if (oldVal !== newVal && keysToPick.includes(fullKey)) {
        if (newVal === undefined) {
          const changeObj = {
            name: fieldsName[fullKey],
            key: fullKey,
            newResult: "",
            oldResult: oldVal,
          };
          changesArr.push(changeObj);
        } else if (oldVal === undefined) {
          const changeObj = {
            name: fieldsName[fullKey],
            key: fullKey,
            newResult: newVal,
            oldResult: "",
          };
          changesArr.push(changeObj);
        } else {
          const changeObj = {
            name: fieldsName[fullKey],
            key: fullKey,
            newResult: newVal,
            oldResult: oldVal,
          };
          changesArr.push(changeObj);
        }
      }
    });
  };

  findChanges(oldRecord, newRecord);

  return changesArr;
};

export const createOrderChangeObjForLogs = ({ oldRecord, newRecord, fieldsName }) => {
  const keysToPick = Object.keys(fieldsName);

  const changesArr = [];

  const findChanges = (oldObj, newObj, parentKey = "") => {
    const allKeys = new Set([...Object.keys(oldObj || {}), ...Object.keys(newObj || {})]);

    allKeys.forEach((key) => {
      const fullKey = parentKey ? `${parentKey}.${key}` : key;
      const oldVal = oldObj?.[key];
      const newVal = newObj?.[key];

      if (typeof oldVal === "object" && oldVal !== null && typeof newVal === "object" && newVal !== null) {
        findChanges(oldVal, newVal, fullKey);
      } else if (oldVal !== newVal && keysToPick.includes(fullKey)) {
        if (newVal === undefined) {
          const changeObj = {
            name: fieldsName[fullKey] || "",
            newResult: "",
            oldResult: oldVal,
          };
          changesArr.push(changeObj);
        } else if (oldVal === undefined) {
          const changeObj = {
            name: fieldsName[fullKey] || "",
            newResult: newVal,
            oldResult: "",
          };
          changesArr.push(changeObj);
        } else {
          const changeObj = {
            name: fieldsName[fullKey] || "",
            newResult: newVal,
            oldResult: oldVal,
          };
          changesArr.push(changeObj);
        }
      }
    });
  };

  findChanges(oldRecord, newRecord);

  return changesArr;
};

export const removeSpecialCharacters = (inputString) => {
  if (!inputString) return "";
  const val = inputString.replace(/[^a-zA-Z0-9 ]/g, "");
  if (val) {
    return val.trim().toLowerCase();
  }
  return "";
};

export const isValidAccessionNo = (inputString) => {
  if (!inputString) return "";
  const val = inputString.replace(/[^0-9 ]/g, "");
  return val ? true : false;
};

export const arePropsEqual = (prevProps, nextProps) => {
  if (JSON.stringify(prevProps) === JSON.stringify(nextProps)) return true;
  return false;
};

const parseDateOfBirth = (dob) => {
  if (dob.includes("-")) {
    let index = dob.indexOf("-");
    let dateFormat = "MM-DD-YYYY";
    if (index !== 2) {
      dateFormat = "YYYY-MM-DD";
    }
    return moment(dob, dateFormat).format("MM/DD/YYYY");
  }
  return moment(dob, "MMDDYYYY").format("MM/DD/YYYY");
};

const parseSrNoObjectRecviedDate = (test) => {
  let arr = [];
  try {
    arr = JSON.parse(test.sr_no);
  } catch (er) {
    return test.createdAt;
  }
  if (arr.length > 0) {
    return arr[0]?.date;
  }
  return test.createdAt;
};

export const getRecviedDateFromSrNo = (test, onlyDate) => {
  if (!test?.scanByLabUserID) return null;
  try {
    const arr = JSON.parse(test.sr_no);
    const date = test.receivedDate || arr[0]?.date;
    if (onlyDate) {
      return date;
    }
    return moment(date).format("YYYY-MM-DDTHH:mm");
  } catch (error) {
    return null;
  }
};

export const formatPhoneForCalRedie = (phoneNumber) => {
  if (!phoneNumber) return "";

  let phone = formatPhoneNumber(phoneNumber);
  phone = phone.replace(/\D/g, "").replace(/^1/, "");
  const match = phone.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    phone = `${match[1]}-${match[2]}-${match[3]}`;
  }

  return phone;
};

export const parseLabAddress = (lab) => {
  const address = lab.city_state_zip;

  let phone = formatPhoneForCalRedie(lab.phone_number);

  // Regular expression pattern to match city, state, and ZIP code
  const regex = /(.*),\s+(\w{2})\s+(\d{5})/;

  const matches = address.match(regex);

  if (matches) {
    const city = matches[1].trim(); // Extracted city
    const state = matches[2]; // Extracted state
    const zipCode = matches[3]; // Extracted ZIP code

    return {
      city: city,
      state: state,
      zip: zipCode,
      phone: phone,
    };
  } else {
    return {
      city: "",
      state: "",
      zip: "",
      phone: "",
    };
  }
};

export const formatDateTimeTZ = (testDate, timeZone, date, time) => {
  try {
    const testFormatedDateTimeToLocalStr = new Date(testDate)
      .toLocaleString("en-US", {
        timeZone: timeZone ? timeZone : "America/Los_Angeles",
        ...(date && {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
        ...(time && {
          hour: "2-digit",
          minute: "2-digit",
        }),
      })
      .replace(",", "");

    if (testFormatedDateTimeToLocalStr) return testFormatedDateTimeToLocalStr;
  } catch (error) {
    console.log("error:::::", error);
  }
};

export const exportToCalRedie = (list, labRecord, siteProvider) => {
  const labAddress = parseLabAddress(labRecord);
  return list.map((e) => {
    const obj = { ...EXPORT_TO_CALREDIE };
    const demo = JSON.parse(e.employee_demographics);
    let phone = formatPhoneForCalRedie(demo.phoneNumber);

    const defaultProvider = siteProvider[e.siteID];

    // if Site has Provider Set Default Provider into Ordering Provider

    if (defaultProvider) {
      Object.assign(obj, {
        ordering_provider_id: defaultProvider.npi,
        ordering_provider_last_name: defaultProvider.lastName,
        ordering_provider_first_name: defaultProvider.firstName,
        ordering_provider_middle_name: defaultProvider.middleName,
        ordering_provider_street: defaultProvider.street,
        ordering_provider_street2: defaultProvider.street2,
        ordering_provider_city: defaultProvider.city,
        ordering_provider_state: defaultProvider.state,
        ordering_provider_zip_code: defaultProvider.zip,
        ordering_provider_phone_number: formatPhoneForCalRedie(defaultProvider.phoneNumber),
      });
    }
    const { fluor, swabtype } = demo;
    obj.patient_id = `BRM-002-${demo.mdID || e.sequenceNo}`;
    obj.patient_last_name = capitalizeLetter(demo.firstName);
    obj.patient_first_name = capitalizeLetter(demo.lastName);
    obj.patient_street = demo?.street;
    obj.patient_street2 = demo?.street2 || "";
    obj.patient_city = demo.city;
    obj.patient_state = demo.state;
    obj.patient_zip_code = formatZip(demo.zip);
    obj.patient_county = "Los Angeles";
    obj.accession_number = e.barcode;
    obj.patient_phone_number = phone;
    obj.patient_dob = parseDateOfBirth(demo.dob);
    obj.patient_gender = demo.sex || "UNK";
    obj.test_result = EXPORT_TO_CALREDIE_RESULT[e.result?.toLowerCase()];
    obj.order_test_date = formatDateTimeTZ(e.createdAt, e.timeZone, "date");
    obj.specimen_collection_date = formatDateTimeTZ(e.createdAt, e.timeZone, "date");
    obj.testing_lab_specimen_received_date = formatDateTimeTZ(parseSrNoObjectRecviedDate(e), e.timeZone, "date");
    obj.test_result_date = moment(e.resultDateTime).format("MM/DD/YYYY");
    obj.date_result_released = moment(e.resultDateTime).format("MM/DD/YYYY");
    obj.testing_lab_clia = labRecord.clia_number;
    obj.testing_lab_name = labRecord.name;
    obj.testing_lab_street = labRecord.street;
    obj.testing_lab_street2 = "";
    obj.testing_lab_city = labAddress.city;
    obj.testing_lab_state = labAddress.state;
    obj.testing_lab_zip_code = labAddress.zip;
    obj.testing_lab_phone_number = labAddress.phone;

    if (fluor && fluor.toLowerCase() === "cy5") {
      obj.equipment_model_name = "CDC 2019-nCoV Real-Time RT-PCR Diagnostic Panel (CDC)";
      obj.test_performed_code = "94309-2";
      obj.test_ordered_code = "94309-2";
    }

    if (swabtype && swabtype === "nasal") {
      obj.specimen_type = "258500001";
    }

    return { ...obj };
  });
};

export const tdEmail = (email) => (
  <td
    key={email}
    className="table-column ellipsis text-dark-danger text-dark-danger-hover cursor-pointer text-decoration-none"
    onMouseOver={(e) => {
      e.target.style.cursor = "pointer";
      e.target.style.textDecoration = "none";
    }}
    onMouseLeave={(e) => {
      e.target.style.textDecoration = "underline";
    }}
    onClick={() => email && window.open(`mailto:${email}`)}
    title={email}
  >
    {email}
  </td>
);

export const getResultToShow = (testCreate) => {
  const { resultDetails, result, releaseStatus } = testCreate;

  if (resultDetails != null) {
    return { label: "View Detail", labelColor: RESULT_COLOR["detail"] };
  }

  const resultKey = result ? result.toLowerCase() : "detail";
  return {
    label: result || "-",
    labelColor: RESULT_COLOR[resultKey],
    releaseStatus,
  };
};

const getResultLabel = (appContext, result) => {
  if ((appContext?.user?.isSite() && result?.releaseStatus === "Confirmed") || !appContext?.user?.isSite()) {
    return result.label;
  }
  return "";
};

export const tdResult = ({ index, result, handleCellClick, appContext, row }) => {
  const resultLabel = getResultLabel(appContext, result);
  const color = getResultColors(row);

  return (
    <td
      key={`result_${index}`}
      className="text-dark-danger text-dark-danger-hover cursor-pointer text-decoration-none text-center"
      style={{
        color: color,
      }}
      onClick={() => resultLabel && handleCellClick()}
      title={resultLabel}
    >
      {resultLabel}
    </td>
  );
};

export const tdPhone = (phoneNumber) => (
  <td
    key={phoneNumber + Math.random()}
    className="table-column ellipsis text-dark-danger text-dark-danger-hover cursor-pointer text-decoration-none"
    onMouseOver={(e) => {
      e.target.style.cursor = "pointer";
      e.target.style.textDecoration = "none";
    }}
    onMouseLeave={(e) => {
      e.target.style.textDecoration = "underline";
    }}
    onClick={() => phoneNumber && window.open(`tel:+${phoneNumber.replace(/\D/g, "")}`)}
    title={formatPhoneNumber(phoneNumber, "".true)}
  >
    {formatPhoneNumber(phoneNumber, "", true)}
  </td>
);

export const isAllowToArchive = (phone_number) => {
  const validPhone = [
    "+14105980783",
    "+14436870662",
    "+18185730434",
    "+16265413922",
    "+17602186687",
    "+18322958840",
    "+923004991004",
    "+18888888888",
  ];
  return validPhone.includes(phone_number);
};

export const formatFileSize = (size) => {
  if (size === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(size) / Math.log(k));
  return parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

export const parseInsDetails = (insuranceDetails, user) => {
  if (!insuranceDetails) return [];

  if (Array.isArray(insuranceDetails)) {
    return insuranceDetails;
  }
  if (!user.insuranceCompanyCode) return [];
  const primary = {
    id: "prefix_" + Math.random().toString(36).slice(2, 9),
    insuranceCompany: user.insuranceCompanyCode,
    insuranceCompanyCode: user.insurance_name,
    insuranceType: user.insuranceType,
    copay: user.copay,
    deductible: user.deductible,
    insuranceGroupId: user.insuranceGroupId,
    isMedicare: user.isMedicare,
    medicalNo: user.insurance_number,
    medicareNo: user.medicalNo,
    isActive: true,
  };
  const insDetails = [primary];
  if (insuranceDetails?.insuranceCompany) {
    insDetails.push({
      ...insuranceDetails,
      isActive: true,
      id: "prefix_" + Math.random().toString(36).slice(2, 9),
    });
  }
  return insDetails;
};

export const parseTestResultForPDF = (test) => {
  return test.result && RESULT_PDF[test.result.toLowerCase()];
};

export const formatPatientIntoPgData = (emp) => {
  if (!emp) return emp;

  return {
    ...emp,
    eligibilityStatus: parseStringToJSON(emp.eligibilityStatus),
    provider: parseStringToJSON(emp.provider),
    firstName: emp.first,
    lastName: emp.last,
    phoneNumber: emp.phone_number,
    mdID: emp.id,
  };
};

export const getBarCodeFromTest = (test) => {
  try {
    let arr = [];
    try {
      arr = JSON.parse(test.sr_no);
    } catch (er) {
      return test.barcode;
    }
    if (arr.length > 0) {
      return arr.map((r) => r.accession_no).join("\n");
    }
    return test.barcode;
  } catch (e) {
    return test.barcode;
  }
};

export const patientInsuranceType = (test) => {
  const insuranceDemographics = test?.employee_demographics;
  const EMPTY_OBJECT = {
    CLIENT: "",
    PATIENT: "",
    INSURANCE: "",
    MEDICARE: "",
    MEDICADE: "",
    CASH: "",
  };
  const { insuranceCompanyCode, insurance_name, insurance_number } = insuranceDemographics;

  if (!insurance_name) {
    return EMPTY_OBJECT;
  }

  if (insurance_name?.includes("Medicare")) {
    return { ...EMPTY_OBJECT, MEDICARE: insurance_number };
  }

  if (insurance_name?.includes("Medicaid")) {
    return { ...EMPTY_OBJECT, MEDICADE: insurance_number };
  }

  if (insuranceCompanyCode === "00001" || insurance_name?.includes("Bill Patient")) {
    return { ...EMPTY_OBJECT, PATIENT: "Patient" };
  }

  if (insuranceCompanyCode === "00002" || insurance_name?.includes("Bill Client")) {
    return { ...EMPTY_OBJECT, CLIENT: "Client" };
  }

  if (insuranceCompanyCode === "00003" || insurance_name?.includes("Cash Patient")) {
    return { ...EMPTY_OBJECT, CASH: "Cash" };
  }

  return { ...EMPTY_OBJECT, INSURANCE: insurance_number };
};

export const parseTestDates = (dates) => {
  try {
    if (!dates) return;

    const list = dates.split(",");

    const newList = list.map((d) => {
      let r = d.trim();
      const date = moment(r.padStart(6, 0), "MMDDYY").format("MM/DD/YYYY");
      return date;
    });

    return newList;
  } catch (ex) {
    console.log("[parseTestDates]", ex);
  }
  return null;
};

export const mileageOptions = () => {
  const array = [];
  for (let i = 1; i <= 100; i++) {
    array.push({ value: i, label: i });
  }
  return array;
};

export const isTenDigitNumber = (str) => {
  return /^\d{11}$/.test(str);
};
export const convertYYYYMMDDToDate = (str, format) => {
  if (!str) return "";
  if (str.length === 8) {
    const year = str.slice(0, 4);
    const month = str.slice(4, 6);
    const day = str.slice(6, 8);

    return moment(`${year}-${month}-${day}`).format(format || "MMM DD,YYYY");
  }
  return moment(str).format(format || "MMM DD,YYYY");
};

export const hideElementsByClassName = (className) => {
  const elements = document.getElementsByClassName(className);
  for (let i = 0; i < elements.length; i++) {
    elements[i].style.display = "none";
  }
};

export const eligibilityMessage = (item, row) => {
  if (row && (CUSTOM_INS.includes(row?.insuranceCompanyCode) || CUSTOM_INS.includes(row?.insuranceCompany))) {
    return "Eligibile";
  }

  if (!item) return "Not Eligibile";
  let message = item?.status || item?.message;

  if (message && message.toLowerCase() === "ineligibile") message = "Not Eligibile";
  return message || "Not Eligibile";
};

export const eligibilityMessageColor = (message) => {
  const msg = message === "Eligibile" ? "Eligibile" : "Not Eligibile";
  return ELIGIBILITY_STATUS_COLOR[msg];
};

export const parseJSONObject = (obj) => {
  if (!obj) return {};
  return typeof obj === "string" ? JSON.parse(obj) : obj;
};

export const parseStringToJSON = (obj) => {
  if (!obj) return obj;
  return typeof obj === "string" ? JSON.parse(obj) : obj;
};

export const getSelectedColumn = (items) =>
  items?.filter((item) => item?.isCheck).map((m) => ({ itemKey: m.itemKey, title: m.title }));

export const isLabImageExist = (testsLab) => {
  if (
    testsLab?.id === "8cd1a74a-392c-4378-a8a0-15480ee37a1e" ||
    testsLab?.id === "9a41b680-4f5f-4914-a8f6-2229740dca0b" ||
    testsLab?.id === "3eaf991b-b876-4897-8e39-47aef2247dcd" ||
    testsLab?.id === "2ce90d8b-daa3-4b3c-a685-6bec0dfecb43" ||
    testsLab?.id === "6d8ec4ef-f634-4237-9984-c5b337e36546" ||
    testsLab?.id === "b63c398e-dc3f-412c-871e-ad89a94ddb0e" ||
    testsLab?.id === "655f8008-f306-4052-a932-3f5622a39925"
  ) {
    return true;
  }
  return false;
};
// add new

export const parseInvoiceData = async (data, loginUser, clients) => {
  const [setting, employeeData] = await Promise.all([
    getCompanySetting(),
    api.getEmployeeByID({ id: data.employeeID }),
  ]);

  const outStandingBalance = employeeData?.setting?.outstanding_balance;

  let claimClients = clients;
  let errorMessage = "";
  let invoiceAlreadySent = false;
  const { invoiceID, invoiceNo, invoiceSendDate } = data?.toData?.find((f) => f.invoiceNo) || {};

  if (invoiceSendDate && moment(invoiceSendDate).isSame(moment(), "day")) {
    errorMessage = "Invoice Already Sent.";
    invoiceAlreadySent = true;
  }

  let sendTo = data.payerid === "00001" ? "Patient" : "Client";

  let toData = {};

  let barCode = data.remote_claimid.replace("CL-", "");

  if (data.payerid === "00002") {
    if (!claimClients) {
      claimClients = await getDefaultProvidersForInvoice({ ids: [data.provider.ref_id] });
    }
    const client = claimClients[data.provider.ref_id];
    toData = {
      id: client.id,
      isActive: true,
      payerid: data.payerid,
      payer_name: data.payer_name,
      firstName: client?.name || "",
      lastName: "",
      street: client?.street || "",
      state: client?.state ? `${client.state},` : "",
      zip: client?.zip || "",
      city: client?.city || "",
      country: client?.country || "",
      email: client?.email,
      sendToType: client?.setting?.billType,
      sendTo: sendTo,
      barCode,
    };
  } else {
    toData = {
      id: data.employeeID,
      isActive: true,
      payerid: data.payerid,
      payer_name: data.payer_name,
      firstName: data.pat_name_f,
      lastName: data.pat_name_l,
      street: data.pat_addr_1,
      street2: data.pat_addr_2,
      city: data.pat_city,
      state: data.pat_state,
      faxSubject: "",
      country: data.pat_country,
      zip: data.pat_zip,
      pcn: data.pcn,
      email: data.pat_email,
      sendTo: sendTo,
      barCode,
      outStandingBalance,
    };
  }

  let paramData = {
    id: invoiceID,
    invoiceSendDate: moment().toISOString(),
    invoiceDate: moment().toISOString(),
    dueDate: moment().add(10, "days").toISOString(),
    sendToType: data.sendToType,
    sendTo: sendTo,
    employeeID: data.employeeID,
    details: data.proc_array,
    invoiceAmount: generateInvoiceOutStanding(data),
    minAmount: data.minimumAmount ? Number(parseFloat(data.minimumAmount).toFixed(2)) : 0,
    claimID: data.id,
    local_id: data.id,
    clientID: userCompanyID.get() || CONFIG.generalClient,
    companyID: data.locationID,
    subAgentID: data.subAgentID,
    reSubmissionDate: data.reSubmissionDate,
    status: "pending",
    claimStatus: data.status,
    errorMessage: errorMessage,
    invoiceAlreadySent,
    udpatedBy: loginUser?.sub,
    updatedByName: loginUser?.name,
    message: INVOICE_DEFAULT_MESSAGE,
    invoiceNo: invoiceNo ?? "00000",
    payer_name: data.payer_name,
    firstName: data.pat_name_f,
    lastName: data.pat_name_l,
    toData: toData,
    fromData: {
      id: setting.id,
      companyName: setting.name,
      street: setting.street,
      street2: setting.street2,
      city: setting.city,
      state: setting.state,
      zip: setting.zip,
      phone: setting.phoneNumber,
    },
  };

  return paramData;
};
export const userCompanyID = {
  save: (id) => localStorage.setItem("cid", id),
  get: () => localStorage.getItem("cid") || null,
  clear: () => localStorage.removeItem("cid"),
};
export const draggablePersonalizationLocalStorage = {
  save: (obj) => localStorage.setItem("medflowpersonalisation", obj),
  saveAs: (arr, key) => {
    const selectedSetting = JSON.stringify(
      arr
        .filter((f) => f.isCheck)
        .map((f) => {
          return { id: f.id, width: f.width };
        })
    );

    const objData = JSON.parse(localStorage.getItem("medflowpersonalisation"));
    objData[key] = selectedSetting;
    localStorage.setItem("medflowpersonalisation", JSON.stringify(objData));
    return objData;
  },
  get: (user, key, NEW_PERSONALIZE) => {
    let arrData = null; //["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","21","22"];
    let objData = null;
    try {
      const lcObj = localStorage.getItem("medflowpersonalisation");
      if (lcObj && lcObj !== "undefined") {
        objData = localStorage.getItem("medflowpersonalisation");
      } else {
        return NEW_PERSONALIZE;
      }
      objData = JSON.parse(objData);
      arrData = JSON.parse(objData[key]);
    } catch (err) {}

    if (!Array.isArray(arrData)) return NEW_PERSONALIZE;

    let objToReturn = arrData?.map((f) => {
      const obj = NEW_PERSONALIZE.find((obj) => obj.id == f.id) || null;
      return {
        ...obj,
        isCheck: NEW_PERSONALIZE.findIndex((obj) => obj.id == f.id) !== -1,
        width: f.width,
      };
    });

    const lsData = arrData?.map((obj) => obj.id);
    if (!lsData) return NEW_PERSONALIZE;
    const nonCheckedData = NEW_PERSONALIZE.filter((obj) => !lsData.includes(obj.id));
    objToReturn = [
      ...objToReturn,
      ...nonCheckedData.map((data) => {
        return { ...data, isCheck: false, width: "100%" };
      }),
    ];
    return objToReturn;
  },
  clear: () => localStorage.removeItem("medflowpersonalisation"),
};
export const RemoveSpaceAndChar = (name) => {
  if (!name) return "";
  return name.replace(/[^A-Z0-9]/gi, "");
};
export const downloadDataAsCSV = async (data, fileName) => {
  const content = jsonToCSV(data);
  const fileContent = new Blob([content], { type: "csv" });
  await FileSaver.saveAs(fileContent, `${fileName}.csv`);
};
export const downloadFileFromS3 = async (filePath, fileName, bucket) => {
  let alink = null;
  let fileURL = null;

  try {
    const data = await Storage.get(filePath, { bucket: bucket });
    const response = await fetch(data);

    if (!response.ok) {
      return false;
    }

    const blob = await response.blob();

    if (blob.size === 0) {
      return false;
    }

    fileURL = window.URL.createObjectURL(blob);
    alink = document.createElement("a");
    alink.href = fileURL;
    alink.download = fileName;
    document.body.appendChild(alink);
    alink.click();

    return true;
  } catch (error) {
    console.error("Error downloading requisition zip:", error);
    return false;
  } finally {
    if (alink) {
      document.body.removeChild(alink);
      window.URL.revokeObjectURL(fileURL);
    }
  }
};
export const emailMsgToCreatedMember = (firstName, userName, password) => {
  const msg = `Thank you ${firstName} for signing up with SafeCamp LTC powered by Tackl Health. We are excited to partner with you and help you explore ways to stay safe and monitor your health.<br><br>

        Please login to <a href="https://www.ltc.safecampmd.com/member-login">https://www.ltc.safecampmd.com/member-login</a> To view your profile, order tests and update your insurance information.<br><br>

        Username: ${userName}<br>
        Password: ${password}<br><br>

        We thank you again for choosing us to service your critical healthcare needs<br><br>

        Best Regards,<br>
        SafeCamp LTC Team`;

  return msg;
};
export const formatEmployeeClaim = (claim, setting, isMulti, isSecondary, era) => {
  const data = { ...claim, ...(claim.provider || {}) };

  const primaryIns = findMaxValueObject(data.toData, "status_code");

  const keysToDelete = [
    "mod1_2",
    "mod2_2",
    "mod3_2",
    "mod4_2",
    "proc_code_2",
    "thru_date_2",
    "units_2",
    "place_of_service_2",
    "proc_code_2",
    "remote_chgid_2",
  ];
  if (data) {
    keysToDelete.forEach((key) => {
      if (data.hasOwnProperty(key)) {
        delete data[key];
      }
    });
  }
  let procArray = [];
  let totalCharge = 0;
  if (data.proc_array.length > 0 && !isMulti) {
    for (let i = 0; i < data.proc_array.length; i++) {
      const proc = data.proc_array[i];

      let eraCPT = null;

      if (isSecondary && era) {
        eraCPT = era.charge.find((f) => f.proc_code === proc.proc_code);
      }

      const obj = {
        diag_ref_1: proc.diag_ref_1,
        diag_ref_2: proc.diag_ref_2,
        diag_ref_3: proc.diag_ref_3,
        diag_ref_4: proc.diag_ref_4,
      };

      const ttlCharge = getTotalCharge(proc);

      totalCharge = totalCharge + ttlCharge;

      const index = i + 1;
      Object.assign(data, {
        [`diag_ref_${index}`]: Object.values(obj)
          .filter((f) => f)
          .join(","),
        [`from_date_${index}`]: proc.from_date,
        [`thru_date_${index}`]: proc.from_date,
        [`charge_${index}`]: ttlCharge,
        [`units_${index}`]: proc.units,
        [`proc_code_${index}`]: proc.proc_code,
        [`place_of_service_${index}`]: proc.place_of_service,
        [`remote_chgid_${index}`]: `${data.remote_claimid}-${index}`,
        [`mod1_${index}`]: proc.mod1,
        [`mod2_${index}`]: proc.mod2,
        [`mod3_${index}`]: proc.mod3,
        [`mod4_${index}`]: proc.mod4,
        ...(isSecondary && {
          [`primary_paid_amount_${index}`]: proc.paid,
          [`primary_paid_date_${index}`]: data.checkDate,
        }),
      });

      if (isSecondary && eraCPT && eraCPT.adjustment && eraCPT.adjustment.length > 0) {
        eraCPT?.adjustment?.forEach((item, i) => {
          const childIndex = i + 1;
          data[`adj_amt_${childIndex}_${index}`] = item.amount;
          data[`adj_code_${childIndex}_${index}`] = item.group + item.code; // or any other value you want to assign
        });
      }

      procArray.push({ ...proc, remote_chgid: `${data.remote_claimid}-${index}` });
    }
  }

  const [countryCode, phoneNo] = getPhoneNo(data.bill_phone);

  Object.assign(data, {
    ...(totalCharge > 0 && { total_charge: totalCharge, proc_array: procArray }),
    bill_phone: phoneNo,
    accept_assign: "Y",
    pat_country: "",
    pat_rel: "18",
    payer_icn: claim.status === CLAIM_SUBMIT_STATUS.denied && claim.payerid !== "01192" ? claim.claimNo : "",
    assigned_comment: claim.assign_details?.message.replace(/'/g, "") || "",
    ...(isSecondary && {
      other_ins_name_l: data.pat_name_l,
      other_ins_name_f: data.pat_name_f,
      other_ins_name_m: data.pat_name_m,
      other_ins_dob: data.pat_dob,
      other_ins_sex: data.pat_sex,
      other_ins_payment_date: data.checkDate,
      other_payerid: primaryIns?.payerid,
      payer_order: "secondary",
      primary_allowed_amount: era?.total_allowed,
      other_payer_name: primaryIns?.payer_name,
      other_ins_number: primaryIns?.ins_number,
    }),
  });

  if (
    isSecondary &&
    data.payerid !== primaryIns?.payerid &&
    data.status === CLAIM_SUBMIT_STATUS.submitted &&
    !CUSTOM_INS.includes(data.payerid)
  ) {
    Object.assign(data, {
      toData: [
        ...(data.toData || []),
        {
          payer_name: data.payer_name,
          payerid: data.payerid,
          ins_number: data.ins_number,
          submissionDate: moment().toISOString(),
          status_code: (primaryIns?.status_code || 0) + 1,
        },
      ],
    });
  }

  // set the billing information according to the NPI set at insurnace level
  addCompanyInusranceNPI(data, setting);
  return data;
};

export const formatEmployeesData = (employees, subAgents, locations, companies, providers) => {
  if (employees.length === 0) return [];

  return employees.map((emp) => {
    const subAgent = subAgents?.find((s) => s.id === emp.subAgent);
    const location = locations?.find((s) => s.id === emp.companyID);
    const client = companies?.find((c) => c.id === emp.clientID);
    const provider = providers?.find((p) => p.id === emp.renderingProvider);
    const refProvider = providers?.find((p) => p.id === emp.referringProvider);
    const ordProvider = providers?.find((p) => p.id === emp.orderingProvider);
    return {
      ...emp,
      phoneNumber: `${emp.countryCode}${emp.phoneNumber}`,
      subAgent: subAgent ? { ...subAgent, value: subAgent.id, label: subAgent.name } : null,
      location: location ? { ...location, value: location.id, label: location.name } : null,
      client: client ? { ...client, value: client.id, label: client.name } : null,
      gender: emp.sex,
      secondaryInsurance: emp.insuranceDetails?.insuranceCompany || "",
      secondaryInsNumber: emp.insuranceDetails?.medicalNo || "",
      clientName: client ? client.name : "N/A",
      locationName: location ? location.name : "N/A",
      subAgentName: subAgent ? subAgent.name : "N/A",
      subAgentID: subAgent ? subAgent.id : null,
      providerName: provider?.name,
      providerFirstName: provider?.firstName,
      providerLastName: provider?.lastName,
      providerMidName: provider?.middleName,
      providerID: provider?.id,
      providerNpi: provider?.npi,
      providerTaxId: provider?.taxId,
      refProviderID: refProvider?.id,
      refProviderFirstName: refProvider?.firstName,
      refProviderName: refProvider?.name,
      refProviderLastName: refProvider?.lastName,
      refProviderMiddleName: refProvider?.middleName,
      refProviderNpi: refProvider?.npi,
      ordProviderID: ordProvider?.id,
      ordProviderFirstName: ordProvider?.firstName,
      ordProviderName: ordProvider?.name,
      ordProviderLastName: ordProvider?.lastName,
      ordProviderMiddleName: ordProvider?.middleName,
      ordProviderNpi: ordProvider?.npi,
    };
  });
};
export const getFloatVal = (val) => {
  if (!val) return 0;
  try {
    return +parseFloat(val).toFixed(2);
  } catch (err) {
    return 0;
  }
};
export const removeKeysFromObject = (obj, keysToRemove) => {
  if (!obj || typeof obj !== "object" || !Array.isArray(keysToRemove)) {
    return obj;
  }

  keysToRemove.forEach((key) => {
    if (obj.hasOwnProperty(key)) {
      delete obj[key];
    }
  });

  return obj;
};
export const formatNumber = (num) => {
  if (!num) return 0;

  return parseInt(num).toLocaleString("en-US");
};

export const patientDataInLocalStorage = {
  save: (obj) => localStorage.setItem("client", JSON.stringify(obj)),
  get: () => {
    if (localStorage.getItem("client")) {
      return JSON.parse(localStorage.getItem("client"));
    }
    return newRegisterObj;
  },
  clear: () => localStorage.clear(),
};

export const formatTimeZone = (Tz) => {
  if (!Tz) return "";
  if (Tz === "Pakistan Standard Time") return "PKT";
  return Tz.match(/\b\w/g).join("");
};
export const formatInvoice = (items) => {
  try {
    return items.map((obj) => {
      let item = { ...obj };
      item["toData"] = parseObject(item, "toData");
      item["details"] = parseObject(item, "details");

      return { ...item, discount: item.discount || 0 };
    });
  } catch (ex) {
    console.log("[formatInvoice]", ex);
  }
  return items;
};
export const formatLocations = (locations, companies) => {
  if (locations.length === 0) return [];

  return locations.map((emp) => {
    const client = companies.find((s) => s.id === emp.companyID);

    return {
      ...emp,
      clientName: client ? client.name : "N/A",
      client,
    };
  });
};
export const formatClaims = (models) => {
  let claims = [...models];
  claims = claims.map((obj) => {
    let m = { ...obj };
    m["provider"] = parseObject(m, "provider");
    m["proc_array"] = parseObject(m, "proc_array");
    m["acknowledgeBy"] = parseObject(m, "acknowledgeBy");
    m["assign_details"] = parseObject(m, "assign_details");
    m["paid_insurance"] = parseObject(m, "paid_insurance");

    if (m.status?.toLowerCase() === CLAIM_SUBMIT_STATUS.submitted) {
      m.status = CLAIM_SUBMIT_STATUS.sent;
    }

    let subBy = m.submittedByName || m.updatedByName;
    if (subBy === "0") subBy = m.updatedByName;

    const toData = m.toData && typeof m.toData === "string" ? JSON.parse(m.toData) : m.toData ?? [];

    return {
      ...m,
      invoiceID: toData?.find((f) => f.invoiceID)?.invoiceNo,
      toData,
      partialAmount: m.status?.toLowerCase() === CLAIM_SUBMIT_STATUS.partial ? true : false,
      reSubmissionDate: m.reSubmissionDate || m.createdAt,
      paid_ins_processed: m.paid_insurance?.payer_name || "",
      submittedByName: subBy,
    };
  });
  return claims;
};
export const formatOrder = (obj, employees) => {
  const address = [];
  const orderObj = { ...obj };
  const emp = (employees || []).find((f) => f.id === obj.employeeID);

  orderObj.details = typeof orderObj.details === "string" ? JSON.parse(orderObj.details) : orderObj.details;
  orderObj.employee_demographics =
    typeof orderObj.employee_demographics === "string"
      ? JSON.parse(orderObj.employee_demographics)
      : orderObj.employee_demographics;
  orderObj.testsToDo = typeof orderObj.testsToDo === "string" ? JSON.parse(orderObj.testsToDo) : orderObj.testsToDo;
  orderObj.provider =
    orderObj.provider && typeof orderObj.provider === "string" ? JSON.parse(orderObj.provider) : orderObj.provider;

  address.push(orderObj.details?.street || "");

  if (orderObj.details?.street2) address.push(orderObj.details?.street2 || "");
  address.push(orderObj.details?.city || "");

  return {
    ...orderObj,
    lastName: orderObj.lastName,
    firstName: orderObj.firstName,
    phoneNumber: orderObj.phoneNumber ? `${orderObj.countryCode}${orderObj.phoneNumber}` : "",
    email: orderObj.email,
    siteName: orderObj.locationName,
    medicalNo: orderObj.medicalNo,
    orderId: orderObj.orderId === "-1" ? "-" : orderObj.orderId,
    address: address.join(","),
    employeeClaimID: orderObj.employeeClaimID || "",
    claim: orderObj.claimSubmitDate ? "1" : "0",
    employeeStatus: emp?.status,
    note: {
      ...(orderObj.note ? orderObj.note : {}),
      message: emp?.note || orderObj.note?.message || "",
    },
  };
};
export const formatOrderedDateAws = (date) =>
  date ? `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(6, 8)}` : "";

export const getPhoneNumber = (phone) => {
  if (!phone) return "";
  const countryCode = phone.substring(0, 1);

  if (countryCode !== "+") {
    const phoneNumber = `+${phone.replace(/\D/g, "")}`;
    return phoneNumber;
  }
  return phone;
};
export const getValidGender = (gen) => {
  if (gen.toLowerCase() == "male" || gen.toLowerCase() == "m") return "M";
  if (gen.toLowerCase() == "female" || gen.toLowerCase() == "f") return "F";
  if (gen.toLowerCase() == "x") return "X";
  return "";
};

export const personalizationLocalStorage = {
  save: (obj) => localStorage.setItem("personalisation", obj),
  saveAs: (arr, key) => {
    const selectedSetting = arr
      .filter((f) => f.isCheck)
      .map((f) => f.id)
      .join(",");

    const objData = JSON.parse(localStorage.getItem("personalisation"));
    objData[key] = selectedSetting;
    localStorage.setItem("personalisation", JSON.stringify(objData));
    return objData;
  },
  get: (user, key, NEW_PERSONALIZE) => {
    let arrData = null;
    let objData = null;
    try {
      const lcObj = localStorage.getItem("personalisation");
      if (lcObj && lcObj !== "undefined") {
        objData = localStorage.getItem("personalisation");
      } else {
        return NEW_PERSONALIZE;
      }
      objData = JSON.parse(objData);
      arrData = objData[key].split(",");
    } catch (err) {}

    return NEW_PERSONALIZE.map((f) => {
      return { ...f, isCheck: arrData?.indexOf(f.id.toString()) !== -1 };
    });
  },
  clear: () => localStorage.removeItem("personalisation"),
};
export const convertLabClientObject = (loc, clientID) => {
  const phone_number = formatPhoneNumberIntl(loc.client_phone);
  const fax = formatPhoneNumberIntl(loc.client_fax);
  const contactFax = formatPhoneNumberIntl(loc.client_main_contact_fax);

  const obj = {
    uuid: uuidv4(),
    clientID: clientID,
    practiceID: loc.client_payer_id || "",
    name: loc.client_name || "",
    street: loc.client_address || "",
    street2: loc.client_address_2 || "",
    city: loc.client_city || "",
    state: loc.client_state || "",
    zip: loc.client_zip || "",
    phone_number: phone_number || null,
    fax: fax || null,
    email: loc.client_email || null,
    contact_name: `${loc.client_main_contact_first_name || ""} ${loc.client_main_contact_last_name || ""}`,
    contact_fax: contactFax || null,
    contact_email: loc.client_main_contact_email || null,
    commission: loc.percent_claims_commission || null,
    discount: loc.percent_discount_list_price || null,
    setting: {
      primaryBillType: loc.primary_invoice_method || "email",
      ...(loc.secondary_invoice_method && { secondaryBillType: loc.secondary_invoice_method }),
    },
  };

  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, typeof value === "string" ? value.trim() : value])
  );
};

export const emailMsgToCreatedFacility = (firstName, userName, password) => {
  const msg = `Thank you ${firstName} for signing up with SafeCamp LTC powered by Tackl Health. We are excited to partner with you and help you explore ways to stay safe and monitor your client’s and members health.<br><br>
  
  Please login to <a href="${CONFIG.appURL}">${CONFIG.appURL}</a> To order tests, add members, add sub agents, track shipments, and update any information required.<br><br>
  
  Username: ${userName}<br>
  Password: ${password}<br><br>

  We thank you again for choosing us to service your critical healthcare needs<br><br>

  Best Regards,<br>
  SafeCamp LTC Team`;

  return msg;
};

export const getPreRegistrationLink = (id, type = "") => {
  if (CONFIG.isLive) {
    return `https://ltc.safecampmd.com/order-test${type}/${id}`;
  }
  return `https://staging.ltc.safecampmd.com/order-test${type}/${id}`;
};
export const getValidReg = (reg) => {
  if (!reg) return "";
  return toTitleCase(reg.replace(/  +/g, " "));
};

export const tdLink = (item, dates, itemID, filterKey) => (
  <td
    className="ellipsis"
    style={{
      textAlign: "center",
      textOverflow: "none",
    }}
  >
    {item > 0 ? (
      <Link
        style={{ color: "#42cef5" }}
        to={{
          pathname: "/admin/orders",
          state: {
            name: itemID,
            term: filterKey,
            filterName: dates,
            filterTerm: dates,
          },
        }}
      >
        {formatNumber(item)}
      </Link>
    ) : (
      item
    )}
  </td>
);
export const getStateLength = (obj) => {
  if (
    !obj.country ||
    obj.isoCode === "US" ||
    obj.isoCode === "CA" ||
    obj.country === "United States" ||
    obj.country === "Canada"
  ) {
    return 2;
  }
  return 8;
};

export const generatePassword = (email, phone) => {
  // Extracting 2 characters from the email and phone number
  const emailChars = email.slice(0, 2);
  const phoneChars = phone.slice(-2);

  // Generating 4 random characters
  const randomChars = Array.from({ length: 4 }, () => Math.random().toString(36)[2]).join("");

  // Combining the extracted and random characters
  let password = emailChars + phoneChars + randomChars;

  // If the password length is less than 8, add random characters to make it 8
  if (password.length < 8) {
    password += Math.random()
      .toString(36)
      .slice(2, 10 - password.length);
  }

  return password;
};

export const getFileType = (filename) => {
  const parts = filename.split(".");
  return parts.pop();
};

export const getFileName = (filename) => {
  const parts = filename.split(".");
  parts.pop();
  return parts.join(".");
};

export const getSplitValue = (data, idx) => {
  const parts = data.split(",");
  return parts[idx] ? parts[idx] : "";
};

export const formatTestDemo = (employee) => {
  return {
    city: employee.city,
    dob: moment(employee.dob).format("MMDDYYYY"),
    email: employee.email,
    firstName: employee.firstName,
    lastName: employee.lastName,
    idNumber: employee.lastName,
    idNumber: employee.idNumber,
    insurance_name: employee.insurance_name,
    insurance_number: employee.insurance_number,
    insuranceCompanyCode: employee.insuranceCompanyCode,
    isVaccinated: employee.isVaccinated || false,
    phoneNumber: employee.phone_number,
    countryCode: employee.countryCode,
    sex: employee.sex,
    state: employee.state,
    street: employee.street,
    street2: employee.street2,
    whiteGlove: employee.whiteGlove || false,
    employeeType: employee.employeeType,
    testerDes: employee.testerDes,
    swabtype: employee.swabtype,
    worker: employee.worker,
    possibleexposure: employee.possibleexposure,
    confirmedexposure: employee.confirmedexposure,
    relatedsymptoms: employee.relatedsymptoms,
    symptoms: employee.symptoms,
    othercomment: employee.othercomment,
    comment: employee.comment,
    clinicalcomment: employee.clinicalcomment,
    docUploaded: employee.docUploaded || false,
    id: employee.id,
    zip: employee.zip,
    mdID: employee.mdID || "",
    ...(employee.fluor && { fluor: employee.fluor }),
    isReview: employee.isReview || false,
    venipuncture: employee.venipuncture || "",
    distance: employee.distance || 0,
    isEligible: employee.isEligible || false,
    stat: employee.stat || false,
    testTypeID: employee.testTypeID,
    providerID: employee.providerID,
    providerName: employee.providerName,
    orderDate: employee.orderDate,
    orderNo: employee.orderNo || "",
  };
};

export const getUUID = () => uuidv4();

// export const generateRefInterval = ({ minValue, maxValue, minValueF, maxValueF }) => {
//   const hasMin = minValue !== undefined && minValue !== null && minValue !== "";
//   const hasMax = maxValue !== undefined && maxValue !== null && maxValue !== "";

//   return hasMin && hasMax
//     ? `${minValue}-${maxValue}` // Display the range
//     : hasMin
//     ? `>${minValue}`
//     : hasMax
//     ? `<${maxValue}`
//     : "Not Estab.";
// };

const getUserDataFromLocalStorage = (suffix) => {
  const keys = Object.keys(localStorage);
  const userDataKey = keys.find((key) => key.endsWith(suffix));
  if (userDataKey) {
    const userData = localStorage.getItem(userDataKey);
    return JSON.parse(userData); // Parse once when the key is found
  }
  return null;
};

const processUserData = (attributes) => {
  const attrMap = attributes.reduce((map, attr) => {
    map[attr.Name] = attr.Value;
    return map;
  }, {});

  const id = attrMap["sub"];
  const firstName = attrMap["custom:firstName"];
  const lastName = attrMap["custom:lastName"];
  const name = `${firstName} ${lastName}`;

  return { userID: id, userName: name };
};

export const getLoginUserInfoFromLocal = () => {
  const userData = getUserDataFromLocalStorage(".userData");
  if (userData && userData.UserAttributes) {
    return processUserData(userData.UserAttributes);
  }
  return null;
};

export const mergeUniqueCptCodes = (data) => {
  if (!data) return [];

  const uniqueCodesMap = new Map();

  data.forEach((item) => {
    item.cptCodes.forEach((cptCode) => {
      if (!uniqueCodesMap.has(cptCode.code)) {
        uniqueCodesMap.set(cptCode.code, cptCode);
      }
    });
  });

  return Array.from(uniqueCodesMap.values());
};

export const uniqueObject = (data, id) => {
  return Object.values(
    data.reduce((acc, obj) => {
      acc[obj[id]] = obj;
      return acc;
    }, {})
  );
};
export const isTestCodeMatch = (sourceTest, testToMatch) => {
  if (!sourceTest) return "";

  const order = sourceTest.order || {};
  const testsToDo = order.testsToDo || {};
  const test = testsToDo.test || [];

  if (!Array.isArray(test) || test.length === 0) return false;

  return test.some((item) => item?.label?.toLowerCase() === testToMatch?.title?.toLowerCase());
};

export const getTestName = (sourceTest) => {
  if (!sourceTest) return "";

  const order = sourceTest.order || {};
  const testsToDo = order.testsToDo || {};
  const test = testsToDo.test || [];

  if (test.length > 0) {
    return test.map((item) => item.label).join(", ");
  }

  return sourceTest.test_type;
};

export const formatTwoFaErrorMessage = (error) => {
  const errorData = error?.response?.data;

  if (typeof errorData?.error === "string") {
    return errorData.error;
  } else if (Array.isArray(errorData?.errors)) {
    return errorData.errors.map((err) => err.code).join(", ");
  } else {
    return "Unknown error";
  }
};

export const getClientSpecificString = () => {
  const DEFAULT_CONFIG = {
    // roomno: "Room No",
    roomno: "Apt/Unit #",
    isShowRoom: true,
    isShowFloor: true,
    isShowSendToLab: true,
    isShowCancelReason: true,
    isShowResultFile: false,
    eligibilityBucket: CONFIG.eligibilityBucket,
    isTestCreationFromEmployee: false,
  };

  switch (CONFIG.clientID) {
    case SEROCLINIX_TEST:
    case SEROCLINIX_ID:
      return {
        ...DEFAULT_CONFIG,
        eligibilityBucket: process.env.REACT_APP_ALTA_BUCKET,
        roomno: "Apt/Unit #",
        isShowFloor: false,
        isShowSendToLab: false,
        isShowCancelReason: false,
        isShowResultFile: true,
        isTestCreationFromEmployee: true,
      };
    case FUTURE_GENERATION_ID:
    case ALTA_NEW_ID:
    case CALIBER_ID:
    case ALTA_ID:
      return {
        ...DEFAULT_CONFIG,
        eligibilityBucket: process.env.REACT_APP_ALTA_BUCKET,
        roomno: "Apt/Unit #",
        isShowFloor: false,
        isShowSendToLab: false,
        isShowCancelReason: false,
        isShowResultFile: true,
      };
    case NEW_DISCOVERY_ID:
      return {
        ...DEFAULT_CONFIG,
        roomno: "Apt/Unit #",
        isShowSendToLab: false,
        isShowFloor: false,
        isShowCancelReason: false,
      };
    default:
      return DEFAULT_CONFIG;
  }
};

export const formatPersonalization = ({ personalize, defaultConfig, userClientData, isSiteUser }) => {
  return personalize
    .filter((item) => {
      if (item.itemKey === "street2" && !defaultConfig.isShowRoom) return false;
      if (item.itemKey === "floor" && !defaultConfig.isShowFloor) return false;
      if (item.itemKey === "eligibilityStatus" && !userClientData?.setting?.eligibility) return false;
      if (item.itemKey === "providerStatus" && !isSiteUser) return false;
      if (item.itemKey === "status" && isSiteUser) return false;
      return true;
    })
    .map((f) => {
      if (f.itemKey === "street2" && defaultConfig.isShowRoom) {
        return { ...f, title: defaultConfig.roomno };
      }
      return f;
    });
};

export const getDiagnosisCode = (order) => {
  const ictSet = new Set();
  order?.testsToDo?.test?.forEach(({ ictCodes = [] }) =>
    ictCodes.forEach(({ code: ictCode, cptCodes = [] }) => {
      ictSet.add(ictCode);
    })
  );
  const ictCodes = Array.from(ictSet.values());

  return ictCodes.reduce((acc, diagCode, index) => {
    acc[`diag_${index + 1}`] = diagCode;
    return acc;
  }, {});
};

export const getTestSource = (source) => {
  if (!source) return TEST_SOURCE_OPTIONS;

  const sourcesArray = Array.isArray(source) ? source : [source];

  return sourcesArray
    .map((source) => ({
      ...source,
      label: toTitleCase(source.label.trim()),
      value: toTitleCase(source.value.trim()),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const getDefaultTestSource = (source) => {
  if (!source) return null;

  return Array.isArray(source) ? source[0] : source;
};

// export const getRangeName = (result, ranges) => {
//   const rangeKeys = Object.keys(ranges);
//   console.log("RangeKes", rangeKeys);
//   for (let i = 0; i < rangeKeys.length; i += 2) {
//     const lowKey = rangeKeys[i];
//     const highKey = rangeKeys[i + 1];
//     const lowValue = parseInt(ranges[lowKey]);
//     const highValue = parseInt(ranges[highKey]);

//     console.log("LowValue", lowValue);

//     console.log("HighValue", highValue);

//     console.log("Result", result);

//     if (result >= lowValue && result <= highValue) {
//       return lowKey.replace(/([A-Z][a-z]+)/g, "$1 ").trim();
//     }
//   }
//   return null;

// };

const expectedOrder = [
  "NormalLow",
  "NormalHigh",
  // "HighLow",
  // "HighHigh",
  // "PanicLow",
  // "PanicHigh",
  // "RepeatLow",
  // "RepeatHigh",
  // "LowLow",
  // "LowHigh",
  // "DefaultHigh",
  // "DefaultLow",
];

const LABEL_COLOR = {
  Low: "blue",
  Normal: "green",
  High: "red",
  Panic: "red",
  Repeat: "red",
  Indeterminate: "blue",
  Detected: "red",
  "Not Detected": "black",
  Invalid: "blue",
};

const NORMAL_RANGES_KEY = ["NormalLow", "NormalHigh", "minValue", "maxValue", "minValueM", "maxValueM"];
const NORMAL_RANGES_KEY_NEW = ["NormalLow", "NormalHigh", "minValue", "maxValue", "minValueM", "maxValueM"];

const getOrderedRanges = (ranges) => {
  return customPick(ranges, expectedOrder);
};

export const getMinMaxFromRanges = ({ ranges }) => {
  const values = Object.values(customPick(ranges, NORMAL_RANGES_KEY))
    .filter((value) => value !== "")
    .map((value) => parseFloat(value))
    .filter((value) => !isNaN(value));

  if (values.length === 0 || values.length === 1) {
    return false;
  }

  const minimumValue = Math.min(...values);
  const maximumValue = Math.max(...values);
  if (!isNaN(minimumValue) || !isNaN(maximumValue)) {
    return `${minimumValue} - ${maximumValue}`;
  }
  return false;
};

export const getMinMaxFromRangesNew = (ranges) => {
  const values = Object.values(customPick(ranges, NORMAL_RANGES_KEY_NEW))
    .filter((value) => value !== "")
    .map((value) => parseFloat(value))
    .filter((value) => !isNaN(value));

  if (values.length === 0 || values.length === 1) {
    return false;
  }

  const minimumValue = Math.min(...values);
  const maximumValue = Math.max(...values);

  if (!isNaN(minimumValue) || !isNaN(maximumValue)) {
    return `${minimumValue} - ${maximumValue}`;
  }
  return false;
};

export const getRefInterval = (targetRanges, testType) => {
  return targetRanges.map((item) => {
    const refInterval = testType === TYPE_OF_TEST.QUALITATIVE ? "Not Detected" : getMinMaxFromRangesNew(item.ranges[0]);

    return {
      ...item,
      refInterval,
      typeOfTest: testType,
    };
  });
};

export const getRangeName = (result, ranges) => {
  const orderedRanges = getOrderedRanges(ranges);

  const rangeKeys = Object.keys(orderedRanges);

  let lowValue;
  let highValue;

  for (let i = 0; i < rangeKeys.length; i += 2) {
    const lowKey = rangeKeys[i];

    const highKey = rangeKeys[i + 1];

    lowValue = parseFloat(orderedRanges[lowKey]);

    highValue = parseFloat(orderedRanges[highKey]);

    if (!isNaN(lowValue) && !isNaN(highValue) && result >= lowValue && result <= highValue) {
      return lowKey
        .replace(/([A-Z][a-z]+)/g, "$1 ")
        .trim()
        .split(" ")[0];
    }
  }

  lowValue = lowValue || 0;
  highValue = highValue || 0;

  if (parseFloat(result) < lowValue) return "Low";

  if (parseFloat(result) > highValue) return "High";

  return result;
};

export const generateRefInterval = ({ minValue, maxValue, minValueM, maxValueM, ranges, isAssociatedPanel }) => {
  const rangeStr = getMinMaxFromRangesNew(ranges ?? {});

  // const rangeStr = getMinMaxFromRanges({ minValue, maxValue, minValueM, maxValueM, ranges });

  if (rangeStr) return rangeStr;

  const actualMinValue = minValue !== undefined && minValue !== null && minValue !== "" ? minValue : minValueM;
  const actualMaxValue = maxValue !== undefined && maxValue !== null && maxValue !== "" ? maxValue : maxValueM;

  const hasMin = actualMinValue !== undefined && actualMinValue !== null && actualMinValue !== "";
  const hasMax = actualMaxValue !== undefined && actualMaxValue !== null && actualMaxValue !== "";

  return hasMin && hasMax
    ? `${actualMinValue}-${actualMaxValue}` // Display the range
    : hasMin
    ? `>${actualMinValue}`
    : hasMax
    ? `<${actualMaxValue}`
    : "Not Estab.";
};

export const generateRefIntervalByRanges = (ranges) => {
  const normalRanges = ranges?.[0];
  const { NormalLow, NormalHigh } = normalRanges;
  if (!NormalLow || !NormalHigh) return "";
  return `${NormalLow} - ${NormalHigh}`;
};

const getRange = (rangeObject, resultValue) => {
  const result = Number(resultValue);

  const orderedRanges = [
    { name: "Repeat", low: rangeObject.RepeatLow, high: rangeObject.RepeatHigh },
    { name: "Panic", low: rangeObject.PanicLow, high: rangeObject.PanicHigh },
    { name: "Normal", low: rangeObject.NormalLow, high: rangeObject.NormalHigh },
    { name: "Low", low: rangeObject.LowLow, high: rangeObject.LowHigh },
    { name: "High", low: rangeObject.HighLow, high: rangeObject.HighHigh },
  ];

  for (const range of orderedRanges) {
    const low = Number(range.low);
    const high = Number(range.high);

    if (result >= low && result <= high) {
      return ` = ${low} - ${high}`;
    }
  }

  const repeatHigh = Number(rangeObject.RepeatHigh);
  if (result > repeatHigh) {
    return `>= ${repeatHigh}`;
  }

  console.log("range not match");
  return "";
};

export const generateTagLineQualitativeWithPanleTests = ({ panel }) => {
  const { ranges, resultValue } = panel;
  const rangeToShow = getRange(ranges[0], resultValue);
  return rangeToShow;
};

export const analyzeResultValue = (range, result, refInterval, testRange) => {
  if (!result) return { label: "", color: "black", fontWeight: "500", orginalResult: result };

  const panelRanges = testRange && testRange.length > 0 ? testRange[0] : {};
  const defaultPanelValues = {
    ...panelRanges,
    DefaultLow: range.minValue,
    DefaultHigh: range.maxValue,
  };

  const labelValue = getRangeName(result, defaultPanelValues);

  if (customIsJsonString(result)) {
    const parsedJSONString = JSON.parse(result);

    if (parsedJSONString.value !== refInterval) {
      return {
        label: "",
        color: LABEL_COLOR[parsedJSONString.value],
        fontWeight: "bold",
        orginalResult: parsedJSONString.label.toUpperCase(),
        resRange: "high",
      };
    }

    return {
      label: "",
      color: "black",
      fontWeight: "bold",
      orginalResult: parsedJSONString.label.toUpperCase(),
      resRange: "normal",
    };
  }

  return {
    label: labelValue,
    color: LABEL_COLOR[labelValue],
    fontWeight: "bold",
    orginalResult: result,
    resRange: LABEL_COLOR[labelValue] === "red" ? "high" : "low",
  };
};

export const parseTestResult = (testResult) => {
  const result = customIsJsonString(testResult);

  if (result) {
    const parsed = JSON.parse(testResult);

    return parsed.label;
  }
  return testResult;
};

export const parseTestUnit = (units) => {
  if (Array.isArray(units) && units.length > 0) {
    return units[0].label;
  }
  return units?.label || "";
};

export const showTestResult = (item, isQualtitave) => {
  const result = parseTestResult(item.testResult);
  if (isQualtitave && result === "Detected") {
    return `${result} :: >=10K ${item?.unit || ""}`;
  }
  return result;
};

// export const isResultInRange = (intervalString, result) => {
//   let intervals = intervalString.split(" ");

//   if (intervals.length === 1) {
//     const interval = parseTestResult(result);
//     return interval === intervals[0] ? true : false;
//   } else {
//     for (let interval of intervals) {
//       let [start, end] = interval.split(" - ").map(Number);

//       if (parseFloat(result) >= parseFloat(start) && parseFloat(result) <= parseFloat(end)) {
//         return true;
//       }
//     }

//     return false;
//   }
// };

export const isResultInRange = (intervalString, result) => {
  let intervals = intervalString.split("-");

  if (intervals.length === 1) {
    const interval = parseTestResult(result);

    return interval === intervals[0] ? true : false;
  } else {
    let [start, end] = intervals.map(Number).filter(Boolean);

    if (parseFloat(result) >= parseFloat(start) && parseFloat(result) <= parseFloat(end)) {
      return true;
    }

    return false;
  }
};
export const showAlphaResult = (resultData, resRange) => {
  const { refInterval, testResult, units } = resultData;

  const isInRange = isResultInRange(refInterval, testResult);

  if (isInRange) {
    return "Not Detected";
  }

  return `DETECTED :: ${resRange === "high" ? ">=" : "<="} ${testResult} ${units.label}`;

  // let intervals = intervalString.split("-");

  // if (intervals.length === 1) {
  //   const interval = parseTestResult(result);

  //   return interval === intervals[0] ? true : false;
  // } else {
  //   let [start, end] = intervals.map(Number).filter(Boolean);

  //   if (parseFloat(result) >= parseFloat(start) && parseFloat(result) <= parseFloat(end)) {
  //     return true;
  //   }

  //   return false;
  // }
};

export function mergeArraysById(arrayToMap, arrayToSearch) {
  return arrayToMap.map((obj) => {
    const matchingObject = arrayToSearch?.find((e) => e.id === obj.id);
    return { ...matchingObject, ...obj };
  });
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const mergeUserPermissions = (customPermissions) => {
  const updatedPermissions = { ...DEFAULT_WHITE_LABEL_USRERS_PERMISSIONS };

  for (const key in customPermissions) {
    if (customPermissions.hasOwnProperty(key)) {
      if (typeof customPermissions[key] === "object" && customPermissions[key] !== null) {
        updatedPermissions[key] = {
          ...DEFAULT_WHITE_LABEL_USRERS_PERMISSIONS[key],
          ...customPermissions[key],
        };
      } else {
        updatedPermissions[key] = customPermissions[key];
      }
    }
  }

  return updatedPermissions;
};

export const formatSite = (obj) => {
  if (!obj) return obj;
  return {
    ...obj,
    setting: obj.setting ? JSON.parse(obj.setting) : null,
    messageReTest: obj.messageReTest ? JSON.parse(obj.messageReTest) : null,
    uploadFileStatus: obj.uploadFileStatus ? JSON.parse(obj.uploadFileStatus) : null,
    safeCampAdmin: obj.safeCampAdmin ? JSON.parse(obj.safeCampAdmin) : null,
  };
};

export const transformSubObjectToPermissionObject = (subObject) => {
  const result = {};

  subObject.forEach((item) => {
    result[item.value] = false;
    result.access = false;
    result.write = false;
    result.delete = false;
  });

  return result;
};

export const detectChangeInPersonalization = (oldPersonalize, newPersonalize) => {
  if (oldPersonalize.length === 0) return false;
  if (oldPersonalize.length !== newPersonalize.length) {
    return true;
  }

  for (let i = 0; i < oldPersonalize.length; i++) {
    const obj1 = oldPersonalize[i];
    const obj2 = newPersonalize[i];

    if (obj1.width !== obj2.width || obj1.isCheck !== obj2.isCheck || obj1.id !== obj2.id) {
      return true;
    }
  }

  return false;
};

export const getStartAndEndDateFromEligibility = (dateString) => {
  if (!dateString) return { startDate: null, endDate: null };
  const [startDateString, endDateString] = dateString.split("-");

  const startDate = moment(startDateString, "YYYYMMDD").format("YYYY-MM-DD");
  const endDate =
    !endDateString || endDateString === "99991231"
      ? moment().format("YYYY-MM-DD")
      : moment(endDateString, "YYYYMMDD").format("YYYY-MM-DD");

  return { startDate, endDate };
};

// export const isTestDataComplete = (testData) => {
//   console.log('TestData', testData)
//   const isCompleted = Object.keys(testData).every(
//     (key) => key === "cpt" || (testData[key] !== "" && testData[key] !== undefined && testData[key] !== null)
//   );
//   console.log("isCOmplated", isCompleted);
//   return isCompleted;
// };

export const isTestDataComplete = (testData) => {
  const skipKeys = ["cpt", "sampleContainer"];

  const isCompleted = Object.keys(testData).every((key) => {
    if (skipKeys.includes(key)) {
      return true;
    }

    const value = testData[key];
    return value !== "" && value !== undefined && value !== null;
  });

  return isCompleted;
};

const newEmptyTestData = {
  test: "",
  cpt: "",
  source: "",
  unit: "",
  refCharge: "",
};

export const updateOrderData = (array, isTestCreationFromEmployee) => {
  let hasUpdated = false;

  const updatedArray = array.map((item) => {
    if (item.description !== null && isTestCreationFromEmployee === false) {
      // const isAllKeysFilled = item.orderData.every(
      //   (dataItem) => dataItem.test && dataItem.unit && dataItem.source && dataItem.ict
      // );

      const isAllKeysFilled = item.orderData.every(
        (dataItem) => dataItem?.test && dataItem.unit && dataItem.source && (isTestCreationFromEmployee || dataItem.ict)
      );

      if (isAllKeysFilled) {
        item.orderData.push({ ...newEmptyTestData });
        hasUpdated = true;
      }
    } else if ((item.description !== null || isTestCreationFromEmployee) && item.orderData.length) {
      const isAllKeysFilled = item.orderData.every(
        (dataItem) =>
          dataItem?.test &&
          dataItem?.test !== "" &&
          dataItem.unit &&
          dataItem.unit !== "" &&
          dataItem.source &&
          dataItem.source !== "" &&
          (isTestCreationFromEmployee || dataItem.ict)
      );

      if (isAllKeysFilled) {
        item.orderData.push({ ...newEmptyTestData });
        hasUpdated = true;
      }
    }

    return item;
  });

  return { updatedArray, hasUpdated };
};

export const getDiagListFromOrderTest = (orderTestData) => {
  return orderTestData.reduce((acc, item, index) => {
    if (item.description && item.description.code) {
      acc[`diag_${index + 1}`] = item.description.code;
    } else {
      acc[`diag_${index + 1}`] = null;
    }
    return acc;
  }, {});
};

const findItemAndIndexTest = (array, id) => {
  const index = array.findIndex((item) => item.id === id);
  if (index !== -1) {
    return { index, item: array[index] };
  } else {
    return null;
  }
};

const findItemAndIndexIct = (array, id) => {
  const index = array.findIndex((item) => item.code === id);
  if (index !== -1) {
    return { index, item: array[index] };
  } else {
    return null;
  }
};

export const getTestListFromOrderTest = (orderTestData, isTestCreationFromEmployee) => {
  const testArr = [];

  let diagnosisData = orderTestData.filter((item) => item.description).map((item) => item.description);

  if ((diagnosisData.length === 0 || orderTestData[0].description === null) && isTestCreationFromEmployee === false) {
    return { error: "Add Diagnosis One." };
  }

  for (const item of orderTestData) {
    if (item.description || isTestCreationFromEmployee === true) {
      for (const orderLevelTestItem of item.orderData) {
        const testItem = {
          ...orderLevelTestItem,
          test: {
            ...orderLevelTestItem.test,
            sources: Array.isArray(orderLevelTestItem.source)
              ? orderLevelTestItem.source
              : orderLevelTestItem.source
              ? [orderLevelTestItem.source]
              : null,
            selectedContainer: orderLevelTestItem?.sampleContainer ||"",
            sampleContainer:orderLevelTestItem?.sampleContainer ?[orderLevelTestItem.sampleContainer] :[],
          },
        };

        const exist = findItemAndIndexTest(testArr, testItem.test.id);

        if (!exist && isTestDataComplete(testItem)) {
          // const cptArr = [
          //   {
          //     ...testItem?.cpt,
          //     unit: testItem?.unit,
          //     referenceCharge: testItem?.refCharge,
          //     source: testItem.source,
          //   },

          // ];
          const cptArray = Array.isArray(testItem?.cpt) ? testItem?.cpt : [testItem?.cpt];

          const cptArr = cptArray.map((item) => ({
            ...item,
            unit: testItem?.unit,
            referenceCharge: testItem?.refCharge,
            source: testItem?.source,
          }));

          // const ictObj = {
          //   ...item.description,
          //   cptCodes: cptArr,
          // };

          const filteredIcdData = diagnosisData.filter((diagData) =>
            testItem?.ict?.some((test) => test.value === diagData.code || test.label === diagData.title)
          );

          testArr.push({
            ...testItem.test,
            cptCodes: cptArr,
            ictCodes: filteredIcdData,
            sourceSite: testItem?.sourceSite || "",
          });
        } else if (exist && isTestDataComplete(testItem)) {
          const cloneItem = { ...exist.item };
          const isIctExist = findItemAndIndexIct(cloneItem?.ictCodes || [], item.description.code);
          if (!isIctExist) {
            const cptCodeArr = [
              {
                ...testItem.cpt,
                unit: testItem.unit,
                referenceCharge: testItem.refCharge,
                source: testItem.source,
              },
            ];
            const ictObj = {
              ...item.description,
              cptCodes: cptCodeArr,
            };
            cloneItem.cptCodes.push(cptCodeArr[0]);

            cloneItem.ictCodes.push(ictObj);
            testArr[exist.index] = cloneItem;
          } else {
            const cptObj = {
              ...testItem.cpt,
              unit: testItem.unit,
              referenceCharge: testItem.refCharge,
              source: testItem.source,
            };

            cloneItem.ictCodes[isIctExist.index].cptCodes.push(cptObj);
            cloneItem.cptCodes.push(cptObj);

            testArr[exist.index] = cloneItem;
          }
        }
      }
    }
  }
  return testArr;
};

export const ictAlreadyExist = (orderTestData, code) => {
  return orderTestData.some((item) => item?.description?.code === code);
};

const formatIctForOrder = (ict, diagIct) => {
  return ict.map((item) => {
    let label = "";

    const diagIndex = diagIct.indexOf(item.value);

    if (diagIndex !== -1) {
      label = String.fromCharCode(65 + diagIndex);
    }

    return {
      label: label,
      value: item.value,
    };
  });
};

export const formatTestForOrder = (order, orderIcd) => {
  // console.log("🚀 ~ formatTestForOrder ~ order:", order)
  let cloneOrderIcd = customCloneDeep(orderIcd);

  let orderIcdKeyBy = customKeyBy(cloneOrderIcd, "id");

  const keysToPick = ["diag_1", "diag_2", "diag_3", "diag_4", "diag_5", "diag_6", "diag_7", "diag_8"];

  const diagIct = Object.values(customPick(order, keysToPick));

  const tests = order?.testsToDo?.test || [];

  tests.forEach((testItem) => {
    const orderTestData = {
      test: testItem,
      cpt: testItem?.cptCodes || [],
      source: testItem?.swabtype ? [{ label: testItem.swabtype, value: testItem.swabtype }] : testItem.sources,
      unit: testItem?.unit || 1,
      refCharge: testItem?.cptCodes?.[0]?.referenceCharge || 0,
      ict: formatIctForOrder(testItem?.ictCodes || [], diagIct),
      sampleContainer: testItem?.selectedContainer || null,
    };
    orderIcdKeyBy[1].orderData.push(orderTestData);
  });

  // Add Diagcode

  const uniqueIctItemsMap = new Map();

  tests.forEach((test) => {
    test?.ictCodes?.forEach((ictItem) => {
      uniqueIctItemsMap.set(ictItem.code, ictItem);
    });
  });

  const uniqueIctItemsArray = Array.from(uniqueIctItemsMap.values());

  uniqueIctItemsArray.forEach((ictItem) => {
    const ictIndex = diagIct.indexOf(ictItem.code);
    if (ictIndex !== -1) {
      orderIcdKeyBy[ictIndex + 1].description = ictItem;
    }
  });

  // tests.forEach((testItem) => {
  //   testItem?.ictCodes?.forEach((ictItem) => {
  //     const ictIndex = diagIct.indexOf(ictItem.code);
  //     orderIcdKeyBy[ictIndex + 1].description = ictItem;
  //     ictItem?.cptCodes?.forEach((cptItem) => {
  //       const orderTestData = {
  //         test: testItem,
  //         cpt: cptItem,
  //         source: cptItem?.source || {},
  //         unit: cptItem.unit,
  //         refCharge: cptItem.referenceCharge || 0,
  //         ict: [],
  //       };

  //       orderIcdKeyBy[ictIndex + 1].orderData.push(orderTestData);
  //     });
  //   });
  // });

  return Object.values(orderIcdKeyBy);
};

export const getFileNameFromDocuments = (path) => {
  if (!path) return "";

  const fullFileName = path.split("/").pop();

  if (!fullFileName) return path;

  const fileName = fullFileName.split(/[-$]/).pop();
  return fileName;
};

export const splitArrayIntoChunks = (array, chunkSizes, INDIVIDUAL_REQUESTED) => {
  const chunks = [];
  let currentIndex = 0;

  chunkSizes.forEach((size, index) => {
    let chunk = array.slice(currentIndex, currentIndex + size);

    if (chunk.length < size) {
      const currentIndexTests = INDIVIDUAL_REQUESTED[index] || [];
      const missingCount = size - chunk.length;
      const defaultTest = currentIndexTests.slice(0, missingCount);
      chunk = [...chunk, ...defaultTest];
    }

    chunks.push(chunk);
    currentIndex += size;
  });

  return chunks;
};

export const loadReqFormTest = async ({ clientID, getTestTypes, tests = [] }) => {
  if (clientID === ALTA_NEW_ID) {
    const allLabTests = tests && tests.length > 0 ? tests : await getTestTypes();

    const microbiologyTests = allLabTests.filter((test) => test.category === "Microbiology");
    const otherTests = allLabTests.filter((test) => test.category !== "Microbiology");

    const formattedMicrobiologyTests = microbiologyTests.map((t) => {
      return {
        internalCode: t.code,
        title: t.name,
        type: t?.setting?.labelID?.label,
      };
    });

    const formattedOtherTest = otherTests.map((t) => {
      return {
        internalCode: t.code,
        title: t.name,
        type: t?.setting?.labelID?.label,
      };
    });

    const individualRequestedChunkSizes = [
      INDIVIDUAL_REQUESTED[0].length,
      INDIVIDUAL_REQUESTED[1].length,
      INDIVIDUAL_REQUESTED[2].length,
    ];

    const splitFormattedOtherTest = splitArrayIntoChunks(
      formattedOtherTest,
      individualRequestedChunkSizes,
      INDIVIDUAL_REQUESTED
    );

    const finalIndividualRequested = splitFormattedOtherTest.map((chunk, index) => {
      return chunk.length === 0 ? INDIVIDUAL_REQUESTED[index] : chunk;
    });

    return {
      MICROBIOLOGY: formattedMicrobiologyTests.length ? formattedMicrobiologyTests : MICROBIOLOGY,
      INDIVIDUAL_REQUESTED: finalIndividualRequested,
    };
  }

  return {
    MICROBIOLOGY: clientID === "e33ea32c-7cc2-4609-b3b2-35d8309df342" ? MICROBIOLOGY_CEROCLINIX : MICROBIOLOGY,
    INDIVIDUAL_REQUESTED: INDIVIDUAL_REQUESTED,
  };
};

export const getTestBasedUponCpt = ({ changeObj, testResponse }) => {
  const selectedCpt = changeObj?.id || null;

  const { tests, rows } = testResponse;

  if (!selectedCpt) return [];

  // let data = rows?.filter((testItem) => testItem?.cptCodes?.some((cpt) => cpt.code == selectedCpt));
  return rows?.filter((testItem) => testItem?.id == selectedCpt);
};

export const countTotalCpt = (cptArr) =>
  cptArr.reduce((count, item) => (isTestDataComplete(item) ? count + 1 : count), 0);

export const renderSourcesCell = (item, row) => {
  if (!Array.isArray(row?.sources)) {
    return (
      <td key={`source_${item.id}`} title="">
        {" "}
      </td>
    );
  }

  const title = row.sources.map((source) => source.label).join(", ");

  const value = row.sources.length > 1 ? "Multi" : row.sources.length === 1 ? row.sources[0].label : "";

  return (
    <td key={`source_${item.id}`} style={{ cursor: "pointer" }} title={title}>
      {value}
    </td>
  );
};

export const renderPlatformCell = (row) => {
  const value = row?.setting?.platform?.map((item) => item.title).join(", ");

  return (
    <td key={`platform_${row.id}`} style={{ cursor: "pointer" }} title={value}>
      {value}
    </td>
  );
};

export const formatValueFloatInput = (val) => {
  if (val === "") return val;

  const isValid = /^-?\d*\.?\d*$/.test(val);
  if (!isValid) return "";

  if (val.startsWith(".")) val = "0" + val;
  if (val === ".") val = "0.";

  return val;
};

export const onHandleDownloadSample = async (type = 2) => {
  // if template info
  if (type === 1) {
    const data = [
      { "Field Name": "client_payer_id", STATUS: "( required )", VALUE: "221" },
      { "Field Name": "client_name", STATUS: "( required )", VALUE: "Client 1" },
      { "Field Name": "client_address", STATUS: "( required )", VALUE: "7365 Douglas Drives" },
      { "Field Name": "client_address_2", STATUS: "( optional )", VALUE: "" },
      { "Field Name": "client_city", STATUS: "( required )", VALUE: "Bechtelarland" },
      { "Field Name": "client_state", STATUS: "( required )", VALUE: "UT" },
      { "Field Name": "client_zip", STATUS: "( required )", VALUE: "10013" },
      { "Field Name": "client_phone", STATUS: "( required )", VALUE: "561-555-7689" },
      { "Field Name": "client_email", STATUS: "( optional )", VALUE: "" },
      { "Field Name": "client_main_contact_first_name", STATUS: "( optional )", VALUE: "" },
      { "Field Name": "client_main_contact_last_name", STATUS: "( optional )", VALUE: "" },
      { "Field Name": "client_main_contact_email", STATUS: "( optional )", VALUE: "" },
      { "Field Name": "percent_claims_commission", STATUS: "( optional )", VALUE: "" },
      { "Field Name": "percent_discount_list_price", STATUS: "( optional )", VALUE: "" },
      { "Field Name": "primary_invoice_method", STATUS: "( optional )", VALUE: "email" },
      { "Field Name": "secondary_invoice_method", STATUS: "( optional )", VALUE: "" },
    ];

    await downloadDataAsCSV(data, "Import Client Template Info");
  } else {
    const data = [
      {
        client_payer_id: "payer_id_1",
        client_name: "Acme Corporation",
        client_address: "123 Main St",
        client_address_2: "",
        client_city: "Springfield",
        client_state: "IL",
        client_zip: "62701",
        phone_number: "555-1234",
        fax: "555-5678",
        email: "contact@acme.com",
        client_main_contact_first_name: "John",
        client_main_contact_last_name: "Doe",
        contact_fax: "",
        contact_email: "john.doe@acme.com",
        percent_claims_commission: 10,
        percent_discount_list_price: 5,
        primary_invoice_method: "email",
        secondary_invoice_method: "",
      },
      {
        client_payer_id: "payer_id_2",
        client_name: "Global Tech",
        client_address: "456 Elm St",
        client_address_2: "Apt 5",
        client_city: "Metropolis",
        client_state: "NY",
        client_zip: "10001",
        phone_number: "555-9876",
        fax: "555-4321",
        email: "info@globaltech.com",
        client_main_contact_first_name: "Jane",
        client_main_contact_last_name: "Smith",
        contact_fax: "",
        contact_email: "jane.smith@globaltech.com",
        percent_claims_commission: 15,
        percent_discount_list_price: 7,
        primary_invoice_method: "email",
        secondary_invoice_method: "phone",
      },
      {
        client_payer_id: "payer_id_3",
        client_name: "Example LLC",
        client_address: "789 Oak St",
        client_address_2: "",
        client_city: "Gotham",
        client_state: "CA",
        client_zip: "90210",
        phone_number: "555-2468",
        fax: "",
        email: "support@example.com",
        client_main_contact_first_name: "Bob",
        client_main_contact_last_name: "Johnson",
        contact_fax: "",
        contact_email: "bob.johnson@example.com",
        percent_claims_commission: 20,
        percent_discount_list_price: 10,
        primary_invoice_method: "email",
        secondary_invoice_method: "",
      },
    ];

    await downloadDataAsCSV(data, "Import Client Sample Info");
  }
};

export const getUniqueSources = (targetRanges) => {
  const uniqueSourcesMap = new Map();

  targetRanges?.forEach(({ sources }) => {
    sources?.forEach((source) => {
      const label = source.label?.trim();
      if (!uniqueSourcesMap?.has(label)) {
        uniqueSourcesMap?.set(label, source);
      }
    });
  });

  return Array.from(uniqueSourcesMap.values());
};

export const makeUniqueSources = (data) => {
  if (!data) return [];

  const normalize = (str) => str.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();

  const uniqueData = Array.from(new Map(data.map((item) => [normalize(item.label), item])).values());
  return uniqueData;
};

export const infectionResultBasedUponRange = ({ resultValue, ranges }) => {
  const numValue = Number(resultValue);

  if ((numValue >= Number(ranges.LowLow) && numValue <= Number(ranges.LowHigh)) || numValue < Number(ranges.LowLow)) {
    return "Low";
  } else if (numValue >= Number(ranges.NormalLow) && numValue <= Number(ranges.NormalHigh)) {
    return "Normal";
  } else if (numValue >= Number(ranges.HighLow) && numValue <= Number(ranges.HighHigh)) {
    return "High";
  } else if (numValue >= Number(ranges.PanicLow) && numValue <= Number(ranges.PanicHigh)) {
    return "Panic";
  } else if (
    (numValue >= Number(ranges.RepeatLow) && numValue <= Number(ranges.RepeatHigh)) ||
    numValue > Number(ranges.RepeatHigh)
  ) {
    return "Repeat";
  } else {
    return "Infection Confirmed";
  }
};

export const isAnyRangeEmpty = (ranges) => {
  return ranges.some((range) => {
    const filteredValues = Object.entries(range)
      .filter(([key, value]) => key !== "id")
      .map(([key, value]) => value);
    return filteredValues.every((value) => value === "");
  });
};

export const getContentType = (fileExtension) => {
  switch (fileExtension) {
    case "pdf":
      return "application/pdf";
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "gif":
      return "image/gif";
    case "txt":
      return "text/plain";
    case "doc":
    case "docx":
      return "application/msword";
    case "xls":
    case "xlsx":
      return "application/vnd.ms-excel";
    default:
      return "application/octet-stream"; // default to binary data
  }
};

export const capitalizeBCBSOrTitleCase = (name) => {
  if (name.toLowerCase().includes("bcbs")) {
    return name.replace(/bcbs/gi, "BCBS");
  } else {
    return nameToTitleCase(name);
  }
};

export const formatLabelID = (id) => {
  if (!id) return "";
  const splitId = id.split("-");
  return splitId[splitId.length - 1];
};

export const formatTestForLabReport = (array) => {
  if (!array) return [];
  const result = [];
  let currentGroup = null;

  array?.forEach((item) => {
    if (item.testName) {
      if (currentGroup) {
        result.push(currentGroup);
      }
      currentGroup = { testName: item.testName, targetRange: [] };
    } else if (currentGroup) {
      currentGroup.targetRange.push(item);
    }
  });

  // Add the last group if exists
  if (currentGroup) {
    result.push(currentGroup);
  }

  return result;
};

export const formatTestNewDiscovery = ({ results, type, code }) => {
  if (type === TEST_PANEL_TYPES.QUALITATIVE_WITH_PATHOGEN || type === TEST_PANEL_TYPES.QUANTITATIVE_WITH_PATHOGEN) {
    const { antibioticTests, pathogenisTests } = results?.reduce(
      (acc, item) => {
        const parsedResult = parseTestResult(item.testResult);
        if (item?.antibioticResistanceType === "Resistance") {
          acc.antibioticTests.push({ ...item, parsedResult });
        } else {
          acc.pathogenisTests.push({ ...item, parsedResult });
        }
        return acc;
      },
      { antibioticTests: [], pathogenisTests: [] }
    );

    return {
      testName: results?.[0].panelName,
      code,
      pathogenisTests,
      antibioticTests,
    };
  }
  return null;
};

export const getPanelElementType = (option) => {
  if (!option) return null;
  return ANTIBIOTIC_RESISTANCE_GENES_TYPE.find((t) => t.value === option);
};

export const getSpecificResultPersonalize = (tests) => {
  const isAnyQualtitative = tests?.some((item) => item?.possibleValues === "Qualitative");

  // const testType = tests?.[0]?.typeOfTest;

  // return testType === TYPE_OF_TEST.QUALITATIVE || isAnyQualtitative
  //   ? PANEL_RESULT_TEST_PERSONALIZE
  //   : PANEL_RESULT_TEST_PERSONALIZE.filter((item) => item.itemKey !== 'testResultMarks');

  return isAnyQualtitative
    ? PANEL_RESULT_TEST_PERSONALIZE
    : PANEL_RESULT_TEST_PERSONALIZE.filter((item) => item.itemKey !== "testResultMarks");
};

export const getTestPanelType = (typeOfTest, isAssociatedPanel) => {
  if (typeOfTest === TYPE_OF_TEST.EXTERNAL) {
    return TEST_PANEL_TYPES.EXTERNAL;
  } else if (typeOfTest === TYPE_OF_TEST.QUALITATIVE) {
    return isAssociatedPanel
      ? TEST_PANEL_TYPES.QUALITATIVE_WITH_PATHOGEN
      : TEST_PANEL_TYPES.QUALITATIVE_WITHOUT_PATHOGEN;
  } else if (typeOfTest === TYPE_OF_TEST.QUANTITATIVE) {
    return isAssociatedPanel
      ? TEST_PANEL_TYPES.QUANTITATIVE_WITH_PATHOGEN
      : TEST_PANEL_TYPES.QUANTITATIVE_WITHOUT_PATHOGEN;
  } else {
    return TEST_PANEL_TYPES.BOTH;
  }
};

export const formatTargetRanges = (targetRanges, typeOfTest, isAssociatedPanel) => {
  if (!isAssociatedPanel) return [];

  const targetArr = [];

  targetRanges?.forEach((item) => {
    const refInterval = generateRefInterval({ ranges: item?.ranges?.[0] ?? null });

    const finalTargetObj = {
      ...item,
      refInterval: typeOfTest === TYPE_OF_TEST.QUALITATIVE ? "Not Detected" : refInterval,
      typeOfTest,
    };
    targetArr.push(finalTargetObj);
  });

  const sortedArray = targetArr.sort((a, b) => {
    const typeA = (a.antibioticResistanceType || "").toLowerCase();
    const typeB = (b.antibioticResistanceType || "").toLowerCase();

    // Sort by antibioticResistanceType
    if (typeA < typeB) return -1;
    if (typeA > typeB) return 1;

    // If antibioticResistanceType is the same, sort by 'name'
    const nameA = (a.name || "").toLowerCase();
    const nameB = (b.name || "").toLowerCase();
    return nameA.localeCompare(nameB);
  });

  return sortedArray;
};

export const env = process.env.REACT_APP_ENV === "dev" ? "test" : "live";

export const isLocalhost = () => {
  return window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
};

export const vhToPixels = (vh) => {
  return Math.round(window.innerHeight * (vh / 100));
};
export const formatSelectedSource = (selectedSource) => {
  return selectedSource?.length > 0
    ? [...new Set(selectedSource.map((srcItem) => srcItem.label?.trim()))].join(",") || ""
    : "";
};

export const filterSourcesBySwabType = (sources, swabType) => {
  if (!swabType) return [];

  if (Array.isArray(swabType)) {
    swabType = swabType.map((item) => item.label).join(",");
  }

  const swabTypesArray = swabType
    .toLowerCase()
    .split(",")
    .map((type) => type.trim());

  return swabType ? sources.filter((source) => swabTypesArray.includes(source.label.trim().toLowerCase())) : null;
};

// created test obj from order

export function compareAndReturnNewValues(newTestCreate, order) {
  let orderProviderID = null;
  let orderProviderName = null;

  if (typeof order?.provider === "string" && !order?.provider?.ref_id) {
    const parsedProvider = JSON.parse(order.provider);
    orderProviderID = parsedProvider?.ref_id;
    orderProviderName = parsedProvider?.ref_name;
  } else {
    orderProviderID = order.provider?.ref_id;
    orderProviderName = order.provider?.ref_name;
  }

  const obj1 = {
    ...order?.employee_demographics,
    siteID: order?.siteID,
    location: order?.location,
    providerID: orderProviderID || null,
    providerName: orderProviderName || null,
    externalID: order?.externalID,
    clincialComments: order?.clincialComments,
    orderDate: order?.orderDate,
    collectionDate: order?.collectionDate,
    labReceiveDate: order?.labReceiveDate,
  };

  const obj2 = {
    ...newTestCreate?.employee_demographics,
    siteID: newTestCreate?.siteID,
    location: newTestCreate?.location,
    providerID: newTestCreate?.providerID || null,
    providerName: newTestCreate?.providerName || null,
    externalID: newTestCreate?.externalID,
    clincialComments: newTestCreate?.clincialComments,
    orderDate: newTestCreate?.orderDate,
    collectionDate: newTestCreate?.collectionDate,
    labReceiveDate: newTestCreate?.labReceiveDate,
  };

  const valueChanges = {};
  const { testsToDo } = order;

  const isTestCreated = testsToDo?.test?.some((item) => item.barcode !== null && !item.result && item.barcode);

  // Compare values for all common keys
  Object.keys(obj1)?.forEach((key) => {
    if (obj1[key] !== obj2[key]) {
      valueChanges[key] = obj2[key];
    }
  });

  // Return null if no new values are found
  return Object.keys(valueChanges)?.length > 0 && isTestCreated ? valueChanges : null;
}

export const testObj = {
  siteID: "",
  invalidResultStatus: "",
  labName: "",
  employee_demographics: {
    venipuncture: "",
    lastName: "",
    country: "",
    distance: "",
    city: "",
    insurance_number: "",
    employeeID: "",
    idNumber: "",
    docUploaded: "",
    confirmedexposure: "",
    countryCode: "",
    street: "",
    isReview: "",
    isEligible: "",
    state: "",
    street2: "",
    insurance_name: "",
    worker: "",
    email: "",
    providerName: "",
    zip: "",
    stat: "",
    address: "",
    whiteGlove: "",
    orderNo: "",
    othercomment: "",
    sex: "",
    testerDes: "",
    clinicalcomment: "",
    relatedsymptoms: "",
    insuranceCompanyCode: "",
    symptoms: "",
    firstName: "",
    possibleexposure: "",
    swabtype: "",
    testTypeID: "",
    phoneNumber: "",
    employeeType: "",
    isVaccinated: "",
    isoCode: "",
    dob: "",
    providerID: "",
    orderDate: "",
    isLucira: "",
    status: "",
  },
  testerPhone: "",
  status: "",
  emailSend: "",
  appVer: "",
  email: "",
  createdBy: "",
  clientID: "",
  resultDateTime: "",
  testTypeID: "",
  _version: "",
  expired: "",
  labID: "",
  timerStatus: "",
  test_type: "",
  labelPrinted: "",
  pcrStatus: "",
  baseSiteID: "",
  id: "",
  site_name: "",
  __typename: "",
  barcode: "",
  clientName: "",
  phoneNumber: "",
  _lastChangedAt: "",
  employeeID: "",
  orderID: "",
  releaseStatus: "",
  arkstoneStatus: "",
  createdAt: "",
  isFalsePositive: "",
  result: "",
  resultDate: "",
  StampByName: "",
  createSource: "",
  schrID: "",
  isArchive: "",
  tester_name: "",
  sequenceNo: "",
  scanByLabUserName: "",
  updatedAt: "",
  timezone: "",
  scanByLabUserID: "",
  labReceiveDate: "",
  StampBy: "",
  sr_no: "",
};

export function getUpdatedFields(compareObjValues) {
  let formattedObj = {};

  if (compareObjValues) {
    formattedObj = {
      ...compareObjValues,
      ...(compareObjValues?.clincialComments && {
        clinicalcomment: compareObjValues.clincialComments,
      }), // Add new key if value exists
      ...(compareObjValues?.street && { address: compareObjValues.street }), // Add new key if value exists
    };
  }

  const finalObj = { employee_demographics: {} };

  // Loop through the keys in formattedObj
  for (const key in formattedObj) {
    if (testObj.employee_demographics.hasOwnProperty(key)) {
      // If the key exists in employee_demographics, update it there
      finalObj.employee_demographics[key] = formattedObj[key];
    } else if (testObj?.hasOwnProperty(key)) {
      // If the key exists outside employee_demographics, update it outside
      finalObj[key] = formattedObj[key];
    } else if (compareObjValues.labReceiveDate) {
      finalObj["createdAt"] = moment(compareObjValues.labReceiveDate).toISOString();
    }
  }

  // Remove employee_demographics if no updates were made inside it
  if (Object.keys(finalObj.employee_demographics).length === 0) {
    delete finalObj.employee_demographics;
  }

  return finalObj;
}

// created order Obj from order
const orderObj = {
  id: "",
  employeeID: "",
  orderNo: "",
  providerID: null,
  clientID: "",
  clientName: "",
  providerName: null,
  siteID: "",
  site_name: "",
  employee_demographics: {
    lastName: "",
    city: "",
    insurance_number: "",
    idNumber: "",
    countryCode: "",
    street: "",
    state: "",
    street2: "",
    insurance_name: "",
    email: "",
    zip: "",
    address: "",
    whiteGlove: false,
    sex: "",
    testerDes: "",
    insuranceCompanyCode: "",
    firstName: "",
    phoneNumber: "",
    employeeType: "",
    isVaccinated: false,
    dob: "",
    isLucira: false,
    status: "",
    othercomment: "",
    clincialComments: "",
  },
  orderDate: null,
  status: "",
  createdBy: "",
  createdAt: null,
  updatedAt: null,
  barcode: "",
  version: null,
  test_type: "",
  labID: "",
  result: "",
};

export const prevTestTypeKey = (obj1, obj2) => {
  let prevTestTypeID = obj1?.testTypeID;
  let newTestTypeID = obj2?.testTypeID;
  if (prevTestTypeID !== newTestTypeID) {
    return prevTestTypeID;
  }
  return null;
};

export function compareTestdReturnNewValues(obj1, obj2) {
  const valueChanges = {};

  // Compare values for all common keys
  Object.keys(obj1)?.forEach((key) => {
    if (obj1[key] !== obj2[key]) {
      valueChanges[key] = obj2[key];
    }
  });

  // Return null if no new values are found
  return Object.keys(valueChanges)?.length > 0 ? valueChanges : null;
}

export function convertTestToOrderObj(comapredData) {
  let finalObject = {};
  if (comapredData) {
    finalObject = {
      ...comapredData,
      ...(comapredData?.clinicalcomment && { clincialComments: comapredData.clinicalcomment }), // Add new key if value exists
      ...(comapredData?.street && { address: comapredData.street }),
      ...(comapredData?.comment && { othercomment: comapredData.comment }),
    };
  }
  const updatedFields = {
    employee_demographics: {},
  };

  // Iterate over all keys in finalObject
  for (let key in finalObject) {
    if (finalObject.hasOwnProperty(key)) {
      // Check if key exists in outer fields of orderObj and if the value is different
      if (orderObj.hasOwnProperty(key) && finalObject[key] !== orderObj[key]) {
        updatedFields[key] = finalObject[key]; // Add only the updated outer field
      }

      // Check if key exists in the nested 'employee_demographics' of orderObj
      if (orderObj.employee_demographics && orderObj.employee_demographics.hasOwnProperty(key)) {
        if (finalObject[key] !== orderObj.employee_demographics[key]) {
          updatedFields.employee_demographics[key] = finalObject[key]; // Add only updated nested fields
        }
      }
    }
  }

  // Remove employee_demographics if no fields inside were updated
  if (Object.keys(updatedFields.employee_demographics).length === 0) {
    delete updatedFields.employee_demographics;
  }

  return updatedFields;
}
export const getResultColors = (test) => {
  if (test.status === "Cancel") return RESULT_COLOR["Cancel"];
  if (test.resultDetails) return RESULT_COLOR["detail"];
  else if (test.result === "Not Detected") return RESULT_COLOR["NotDetected"];
  return RESULT_COLOR[test.result?.toLowerCase()];
};

export const validatePatientRecordFromClient = (Employee, validationObj, fromReset = false) => {
  for (const [field, isValid] of Object.entries(validationObj)) {
    if (!isValid) continue; // Skip fields that don't need validation

    switch (field) {
      case "first":
        if (!Employee.first) {
          return "First name is required";
        }
        if (!isValidName(Employee.first)) {
          return "First name is invalid, must contain at least 2 letters and should be realistic";
        }
        break;

      case "last":
        if (!Employee.last) {
          return "Last name is required";
        }
        if (!isValidName(Employee.last)) {
          return "Last name is invalid, must contain at least 2 letters and should be realistic";
        }
        break;

      case "email":
        if (!Employee.email || !Employee.email.trim()) {
          return "Email Address is required";
        }
        if (!isValidEmail(Employee.email)) {
          return "Invalid Email Address";
        }
        break;

      case "phone_number":
        if (!Employee.phone_number || Employee.phone_number === " ") {
          return "Phone number is required";
        }
        break;

      case "dob":
        if (!Employee.dob) {
          return "Date of Birth is required";
        }
        break;

      case "sex":
        if (!Employee.sex) {
          return "Gender is required";
        }
        break;

      case "insuranceCompanyCode":
        if (!fromReset && !Employee.insuranceCompanyCode) {
          return "Payer Information is required";
        }
        break;

      case "referringPyhsician":
        if (!Employee.referringPyhsician) {
          return "Referring Pyhsician is required";
        }
        break;

      case "provider":
        if (!Employee.provider) {
          return "Patient Client is required";
        }
        break;
      case "address":
        if (!Employee.street) {
          return "Address is required";
        }
        break;
      case "city":
        if (!Employee.city) {
          return "City is required";
        }
        break;
      case "state":
        if (!Employee.state) {
          return "State is required";
        }
        break;
      case "zip":
        if (!Employee.zip) {
          return "Zipcode is required";
        }
        break;
      case "siteID":
        if (!Employee.siteID) {
          return "Client is required";
        }
        break;
      default:
        break;
    }
  }

  // Additional validation logic for Medicare, Insurance Number, etc. if needed
  if (!CUSTOM_INS.includes(Employee?.insuranceCompanyCode) && validationObj["insuranceCompanyCode"]) {
    if (!Employee.insurance_number || !Employee.insurance_number.trim()) {
      return `Insurance Number is required`;
    }
    if (!isValidIDNumber(Employee.insurance_number)) {
      return `Insurance Number is invalid`;
    }
    if (Employee.isMedicare === "Y" && !Employee.medicalNo) {
      return "Medicare Number should not be empty";
    }
    if (Employee.isMedicare === "Y" && !isValidIDNumber(Employee.medicalNo)) {
      return "Medicare Number is invalid";
    }
  }

  return null;
};

export const hasRejectedReasonChanged = (prevData, updateTest) => {
  // Check if both arrays have the same length
  if (prevData.length !== updateTest.length) {
    return true; // If lengths are different, data has changed
  }

  // Iterate over the arrays and check if rejectedReason or testStatus has changed
  for (let i = 0; i < prevData.length; i++) {
    const prevItem = prevData[i];
    const updatedItem = updateTest[i];

    // Check if rejectedReason has changed or testStatus is "Rejected"
    const compareItems = {
      rejectedReason: prevItem.rejectedReason !== updatedItem.rejectedReason,
      fileName: prevItem.fileName !== updatedItem.fileName,
      testStatus: prevItem?.testStatus !== "Rejected",
    };

    // Check if any relevant changes exist
    const hasChanges =
      updatedItem.testStatus === "Rejected" &&
      (compareItems.rejectedReason || compareItems.fileName || compareItems.testStatus);

    if (hasChanges) {
      return true; // Return true if labComment has changed or testStatus is "Rejected"
    }
  }
  return false;
};

export const convertArraytoObject = (arrayOfObjects, key) => {
  return arrayOfObjects.reduce((acc, current) => {
    acc[current[key]] = current;
    return acc;
  }, {});
};

// export const getAgeFromDateString = (dateString) => {
//   const parsedDate = moment(dateString, "MMDDYYYY");

//   const age = moment().diff(parsedDate, "years");

//   return age;
// };

export const getAgeFromDateString = (dateString) => {
  const formats = ["MMDDYYYY", "YYYY-MM-DD"];

  const parsedDate = moment(dateString, formats, true);

  if (!parsedDate.isValid()) {
    throw new Error("Invalid date format");
  }

  const age = moment().diff(parsedDate, "years");

  return age;
};

export const getRangeByAgeOrGender = ({ ranges, gender, age }) => {
  if (!ranges) return null;

  const ageNumber = Number(age);

  const userData = {
    age: Number(age),
    gender: { value: genderOBJ[gender] },
    // diabtic: { value: diabtic.value },
    // fasting: { value: fasting.value },
  };

  const isFilterAndRangeKey = ranges.some(
    (item) => item.hasOwnProperty("FilterKey") && item.hasOwnProperty("RangeArr")
  );
  const isFilterKeyOnly = ranges.some((item) => item.hasOwnProperty("FilterKey"));

  if (isFilterAndRangeKey) {
    const result = findBestMatchingRange(ranges, userData);

    return result;
  } else if (isFilterKeyOnly) {
    const scoredItems = ranges.map((item) => ({
      item,
      score: getMatchScore(item, userData),
    }));

    const maxScore = Math.max(...scoredItems.map(({ score }) => score));

    const bestMatches = scoredItems.filter(({ score }) => score === maxScore);

    if (bestMatches.length > 0) {
      return bestMatches[0].item;
    }

    return ranges[0];
  } else {
    const firstPriority = ranges.find((item) => item.Gender === gender.value && isWithinAgeRange(item, ageNumber));

    if (firstPriority) {
      return firstPriority;
    }

    const secondPriority = ranges.find((item) => isWithinAgeRange(item, ageNumber) || item.Gender === gender.value);

    return secondPriority || ranges[0];
  }
};

export const generateSimpleRefIntervalFromRanges = ({ ranges, testType, test }) => {
  if (
    (testType === TEST_PANEL_TYPES.QUALITATIVE_WITH_PATHOGEN ||
      testType === TEST_PANEL_TYPES.QUALITATIVE_WITHOUT_PATHOGEN) &&
    test.antibioticResistanceType !== ANTIBIOTIC_RESISTANCE_TYPE.ADDITIONAL
  ) {
    return `Not Detected`;
  }

  return `${ranges.NormalLow} - ${ranges.NormalHigh}`;
};

export const addRouteIfNotExists = (route, routesArray) => {
  if (!routesArray.some((existingRoute) => existingRoute.path === route.path)) {
    routesArray.push(route);
  }
};
export const getRowResultColor = ({ dataRow, value }) => {
  if (typeof value === "object" && !Array.isArray(value) && value !== null) {
    const result = value.value;
    return RESULT_COLOR[result];
  } else {
    const refInterval = dataRow?.refInterval;
    let resultColor = "";
    if (refInterval === "Not Estab.") {
      // resultColor = Number(value) !== 0 ? 'red' : 'green';
      resultColor = "green";
    } else {
      let { NormalLow = 0, NormalHigh = 0 } = dataRow?.ranges?.[0] || {};

      resultColor = Number(value) > NormalHigh || Number(value) < NormalLow ? "red" : "green";
    }

    return resultColor;
  }
};

export const getOverallTestResultColor = (arr) => {
  if (arr.some((item) => item.resultColor === "red")) {
    return "red";
  }
  if (arr.some((item) => item.resultColor === "blue")) {
    return "blue";
  }
  return "green";
};

export const isAnySourceExist = ({ orderArray, sourcesArray }) => {
  return orderArray.some(
    (item) =>
      Array.isArray(item?.source) &&
      item.source.some((sourceItem) =>
        sourcesArray.some(
          (secondItem) => sourceItem.label === secondItem.label && sourceItem.value === secondItem.value
        )
      )
  );
};

export const hasAtLeastOneDiagnosis = (diagnostics) => {
  const keysToCheck = ["diag_1", "diag_2", "diag_3", "diag_4", "diag_5", "diag_6", "diag_7", "diag_8"];

  return keysToCheck.some((key) => diagnostics[key] !== null);
};

export const formatTestSource = (sources) => {
  if (!sources) return null;

  const sortedSources = sources
    .map((source) => ({
      ...source,
      label: toTitleCase(source.label.trim()),
      value: toTitleCase(source.value.trim()),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  return sortedSources;
};

export const getTestDates = (order) => {
  const testCreatedAt = order.collectionDate ? moment(order.collectionDate).toISOString() : moment().toISOString();

  const testDate = order.labReceiveDate ? moment(order.labReceiveDate).format("YYMMDD") : moment().format("YYMMDD");

  return {
    testCreatedAt,
    testDate,
  };
};

export const isBarcodeRangeAssigned = ({ newRange, clients }) => {
  const newStart = parseInt(newRange.startRange, 10);
  const newEnd = parseInt(newRange.endRange, 10);

  if (isNaN(newStart) || isNaN(newEnd)) {
    return {
      error: true,
      message: "Invalid barcode range: startRange and endRange must be numbers.",
    };
  }

  if (newStart > newEnd) {
    return {
      error: true,
      message: "Invalid barcode range: startRange is greater than endRange.",
    };
  }

  for (const client of clients) {
    const existingRange = client.barcodeRange;
    if (existingRange) {
      const existingStart = parseInt(existingRange.startRange, 10);
      const existingEnd = parseInt(existingRange.endRange, 10);

      if (isNaN(existingStart) || isNaN(existingEnd)) {
        continue;
      }

      if (existingStart > existingEnd) {
        continue;
      }

      if (
        (newStart >= existingStart && newStart <= existingEnd) ||
        (newEnd >= existingStart && newEnd <= existingEnd) ||
        (existingStart >= newStart && existingStart <= newEnd)
      ) {
        return {
          error: true,
          message: `${client.name} already has this range`,
        };
      }
    }
  }

  return {
    error: false,
    message: "Barcode range is available.",
  };
};

export const viewBarcodeRange = (barcodeObj) => {
  if (!barcodeObj) return "";

  return `${barcodeObj?.startRange} -  ${barcodeObj?.endRange}`;
};

export const parseResultDataToJsonForAlta = (csvData) => {
  const headerKeys = ["Sample Name", "Target Name"];

  const data = csvData.map((item) => item.data);

  const headerIndex = data.findIndex((row) => headerKeys.every((key) => row.includes(key)));

  const rowsStartIndex = headerIndex !== -1 ? headerIndex + 1 : -1;

  if (headerIndex !== -1) {
    const header = data[headerIndex];
    const rows = data.slice(rowsStartIndex);

    const jsonResult = rows.map((row) =>
      header.reduce((acc, key, index) => {
        acc[key] = row[index] || "";
        return acc;
      }, {})
    );

    return jsonResult;
  }

  return null;
};

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const processAltaResultSamples = ({ data, tests, appContext, testTypes }) => {
  const barcodesSet = new Set(tests.map((test) => test.barcode));

  const incrementCount = (obj, key) => {
    obj[key] = (obj[key] || 0) + 1;
  };

  let responseMessage = {};

  const filteredData = data.filter((sample) => barcodesSet.has(sample["Sample Name"]));

  const invalidData = data.filter((sample) => !barcodesSet.has(sample["Sample Name"]));

  const groupedSamples = customGroupBy(filteredData, "Sample Name");

  const groupedSamplesInvalid = customGroupBy(invalidData, "Sample Name");

  responseMessage["Valid Accession No"] = Object.keys(groupedSamples).length;

  responseMessage["Invalid Accession No"] = Object.keys(groupedSamplesInvalid).length;

  const processed = customFlatMap(groupedSamples, (sampleGroup) => {
    const sampleName = sampleGroup[0]["Sample Name"];

    const testObj = tests.find((test) => test.barcode === sampleName);

    if (!testObj) {
      return [];
    }

    const testID = testObj.id;

    const testTypeID = testObj.employee_demographics?.testTypeID || null;

    const selectedTestType = testTypes.find((item) => item.id === testTypeID);

    const selectedTargetRanges = selectedTestType?.targetRanges || [];

    const formattedGroup = sampleGroup.map((obj, index, arr) => {
      let result;

      if (index === 0) {
        const rpTest = customFind(arr, { "Target Name": "RP" });
        arr.rpCTNumber = parseFloat(rpTest?.CT);
        arr.isInvalidGroup = !rpTest || isNaN(arr.rpCTNumber);
      }

      if (arr.isInvalidGroup) {
        result = "Invalid";
      } else {
        const ctValue = obj.CT;

        if (ctValue === "Undetermined") {
          result = "Not Detected";
        } else {
          const selectedElement = selectedTargetRanges.find(
            (item) => item.name === obj["Target Name"] || item.csv_name === obj["Target Name"]
          );

          const ctNumberToCheck = selectedTestType.setting?.ctValue || selectedElement?.setting?.ctValue || 34;

          const ctNumber = parseFloat(ctValue);

          if (isNaN(ctNumber)) {
            result = "Invalid";
          } else {
            result = ctNumber < parseFloat(ctNumberToCheck) ? "Detected" : "Not Detected";
          }
        }
      }
      return {
        testID,
        accession_number: obj["Sample Name"],
        testTypeID,
        test_name: obj["Target Name"],
        result,
        labID: CONFIG.whiteLabelID,
        StampBy: appContext.user.sub,
        StampByName: appContext.user.name,
      };
    });

    if (formattedGroup.some((item) => item.result === "Detected")) {
      incrementCount(responseMessage, "Detected");
    } else if (formattedGroup.some((item) => item.result === "Not Detected")) {
      incrementCount(responseMessage, "Not Detected");
    } else if (formattedGroup.some((item) => item.result === "Invalid")) {
      incrementCount(responseMessage, "Invalid");
    }

    return formattedGroup;
  });

  return { processed, responseMessageArray: responseMessage };
};

// export const createFilterMapping = (items) => {
//   const temp = items.reduce((acc, { title, category, isSkipFilter }) => {
//     if (!isSkipFilter) {
//       const key = category || "Others";
//       if (!acc[key]) {
//         acc[key] = [];
//       }
//       acc[key].push(title);
//     }
//     return acc;
//   }, {});
//   const { Others, ...rest } = temp;
//   return Others ? { ...rest, Others } : rest;
// };

export const createFilterMapping = (items, filterTab) => {
  const groupedItems = items.reduce((acc, item) => {
    const { title, category, isSkipFilter } = item;
    if (!isSkipFilter) {
      const key = category || TAB_NAMES[filterTab] || "Other";
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
    }
    return acc;
  }, {});

  Object.keys(groupedItems).forEach((key) => {
    groupedItems[key].sort((a, b) => {
      const aIndex = a.sortedIndex ?? a.id ?? Infinity;
      const bIndex = b.sortedIndex ?? b.id ?? Infinity;
      return aIndex - bIndex;
    });

    groupedItems[key] = groupedItems[key].map((item) => item.title);
  });

  const { Demographic, Other, ...rest } = groupedItems;

  return {
    ...(Demographic ? { Demographic } : {}),
    ...rest,
    ...(Other ? { Other } : {}),
  };
};

export const sortSettingsList = (sortBy, sortDescending, arr) => {
  switch (sortBy) {
    // strings
    case "npi":
    case "name":
    case "street":
    case "city":
    case "state":
    case "fax":
    case "npiType":
    case "taxid":
    case "phoneNumber":
    case "speciality":
      return sortDescending
        ? [...arr].sort((a, b) => {
            if (a[sortBy] === undefined || a[sortBy] === "") return 1;
            if (b[sortBy] === undefined || b[sortBy] === "") return -1;
            const valB = b[sortBy] ?? ""; // Fallback to an empty string if undefined or null
            const valA = a[sortBy] ?? "";
            return valB.localeCompare(valA); // Use localeCompare for descending order
          })
        : [...arr].sort((a, b) => {
            if (a[sortBy] === undefined || a[sortBy] === "") return 1;
            if (b[sortBy] === undefined || b[sortBy] === "") return -1;
            const valB = b[sortBy] ?? "";
            const valA = a[sortBy] ?? "";
            return valA.localeCompare(valB); // Use localeCompare for ascending order
          });

    case "orders":
    case "claimAmount":
    case "openAmount":
    case "paid":
    case "totalOrders":
    case "associatedClients":
      return [...arr].sort((a, b) => {
        let valA = a[sortBy] ?? 0; // Set to 0 if missing
        let valB = b[sortBy] ?? 0; // Set to 0 if missing
        return sortDescending ? valB - valA : valA - valB;
      });
    case "code":
    case "zip":
    case "practiceID":
      return sortDescending
        ? [...arr].sort((a, b) => {
            if (a[sortBy] === undefined || a[sortBy] === "" || a[sortBy] === null) return 1;
            if (b[sortBy] === undefined || b[sortBy] === "" || b[sortBy] === null) return -1;
            let valA = a[sortBy] ?? 0;
            let valB = b[sortBy] ?? 0;
            return parseInt(valB) < parseInt(valA) ? 1 : parseInt(valA) < parseInt(valB) ? -1 : 0;
          })
        : [...arr].sort((a, b) => {
            if (a[sortBy] === undefined || a[sortBy] === "") return 1;
            if (b[sortBy] === undefined || b[sortBy] === "") return -1;
            let valA = a[sortBy] ?? 0;
            let valB = b[sortBy] ?? 0;
            return parseInt(valB) > parseInt(valB) ? 1 : parseInt(valA) > parseInt(valB) ? -1 : 0;
          });
    case "lastOrder":
      return [...arr].sort((a, b) => {
        if (a[sortBy] === undefined) return 1; // Move 'a' (empty) to the end
        if (b[sortBy] === undefined) return -1; // Move 'b' (empty) to the end

        // Parse the date strings into Date objects
        const dateA = new Date(a[sortBy]);
        const dateB = new Date(b[sortBy]);
        // Sort dates (ascending or descending)
        return sortDescending
          ? dateB - dateA // Descending order
          : dateA - dateB; // Ascending order
      });

    default:
      return sortDescending
        ? [...arr].sort((a, b) => (b[sortBy] < a[sortBy] ? 1 : a[sortBy] < b[sortBy] ? -1 : 0))
        : [...arr].sort((a, b) => (b[sortBy] > a[sortBy] ? 1 : a[sortBy] > b[sortBy] ? -1 : 0));
  }
};

export const createFilterMappingWithoutGroup = (items) => {
  return items
    .filter((item) => !item.isSkipFilter)
    .sort((a, b) => (a.sortedIndex ?? a.id ?? Infinity) - (b.sortedIndex ?? b.id ?? Infinity))
    .map((item) => item.title);
};

export const combinePermissions = (arr) => {
  const parsedCodes = arr.map((item, index) => {
    try {
      return JSON.parse(item.code);
    } catch (error) {
      console.error(`Error parsing JSON for item at index ${index}:`, error);
      return {};
    }
  });

  const combined = {};

  parsedCodes.forEach((code) => {
    for (const mainKey in code) {
      if (!combined[mainKey]) {
        combined[mainKey] = {};
      }

      for (const subKey in code[mainKey]) {
        if (combined[mainKey][subKey] === undefined) {
          combined[mainKey][subKey] = Boolean(code[mainKey][subKey]);
        } else {
          combined[mainKey][subKey] = combined[mainKey][subKey] || Boolean(code[mainKey][subKey]);
        }
      }
    }
  });

  return combined;
};

export const checkRoleAssigned = ({ users, roleId }) => {
  if (!Array.isArray(users)) return false;

  return users.some((user) => {
    let rolesArray = [];

    if (typeof user.roles === "string") {
      try {
        rolesArray = JSON.parse(user.roles);
      } catch (error) {}
    } else if (Array.isArray(user.roles)) {
      rolesArray = user.roles;
    }
    return rolesArray.some((roleObj) => roleObj.id === roleId);
  });
};
