import ErrorMessage from "components/Message/ErrorMessage";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import { AppContext } from "context/app-context";
import { useContext, useEffect, useState, useMemo } from "react";
import "react-activity/dist/Bounce.css";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import {
  CONFIG,
  HEALTH_AND_SAFTEY,
  TEST_SETTING_TYPES,
  USER_ROLE_TYPE,
  newRoleOptions,
  newRoleOptionsWhiteLabel,
} from "../../constant";
import {
  combinePermissions,
  createChangeObjForLogs,
  formatPhoneNumber,
  isValidEmail,
  isValidPhoneWithCode,
  transformSubObjectToPermissionObject,
} from "../../utils";
import Loader from "../Loader/Loader";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import UserPermissions from "./userPermissions";
import { PERMISSIONS_LIST, DEFAULT_WHITE_LABEL_USRERS_PERMISSIONS, dependentPermissionsList } from "constant";
import { userGivenPermission } from "store/features/authentication/authenticationSelectors";
import { useSelector } from "react-redux";
import { customIsEmpty } from "util/customLodash";
import {
  ReactSelectCustomOption,
  ReactSelectCustomContainer,
} from "components/CustomReactSelect/ReactSelectCustomOptionContainer";
import TestTypeSelect from "views/TestTypeSetting/TestTypeComponent/TestTypeSelect";
import UserRoleSelect from "./UserRoleSelect";
import api from "api";

const WhiteLabelUserModal = (props) => {
  const { show, user, handleSave, handleClose, resetCall, handleConfirmUser, message, isCrewCall, users, loading } =
    props;
  const [newUser, setNewUser] = useState(user);
  const [error, setError] = useState("");
  const [rolesOptions, setRolesOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState([{ value: "Employee", label: "Patient" }]);

  // const userAssignedPermissions = user.userPermission
  //   ? JSON.parse(user.userPermission)
  //   : DEFAULT_WHITE_LABEL_USRERS_PERMISSIONS;

  const userAssignedPermissions = useMemo(() => {
    const permissions = user.userPermission ? JSON.parse(user.userPermission) : DEFAULT_WHITE_LABEL_USRERS_PERMISSIONS;

    if (user.roleValue && user.roleValue.includes("Provider")) {
      permissions["clientBaseRole"] = { access: true };
    }

    return permissions;
  }, [user.roleValue]);

  const getRolesOptions = async () => {
    try {
      const optionList = await api.getGeneralSetting(TEST_SETTING_TYPES.ROLE);
      setRolesOptions(optionList);
    } catch (error) {
      console.error(`Error fetching:`, error);
    }
  };

  useEffect(async () => {
    await getRolesOptions();
  }, []);

  const getSelectedRole = () => {
    let userRoleData;
    try {
      userRoleData = user?.roles ? JSON.parse(user.roles) : [];
    } catch (error) {
      console.error("Error parsing user.roles:", error);
      userRoleData = [];
    }

    if (!Array.isArray(userRoleData)) {
      userRoleData = [];
    }

    const selectedRoleData = userRoleData
      .map((item) => {
        try {
          if (item.selectedRole) {
            return JSON.parse(item.selectedRole);
          } else if (item.permission && item.optLabel) {
            return {
              id: item.id,
              code: item.permission,
              label: item.optLabel,
              value: item.optValue,
            };
          }
        } catch (err) {
          console.error("Unable to parse selectedRole:", err);
          return null;
        }
      })
      .filter(Boolean);

    if (selectedRoleData.length === 0) {
      const rolesInUserData = userRoleData.map((item) => item.role);

      const usedValues = new Set();

      const matchedRoles = rolesOptions.filter((option) => {
        if (rolesInUserData.includes(option.value) && !usedValues.has(option.value)) {
          usedValues.add(option.value);
          return true;
        }
        return false;
      });

      setSelectedRole(matchedRoles);
      return;
    }

    setSelectedRole(selectedRoleData);
  };

  useEffect(() => {
    getSelectedRole();
  }, [user, rolesOptions]);

  const systemUserPermissions = useSelector(userGivenPermission);

  const [permission, setPermission] = useState(userAssignedPermissions);

  const [is2FaEnabled, setIs2FaEnabled] = useState(user?.is2FaEnabled === "y" ? true : false);

  const [customPermissionList, setCustomPermissionList] = useState([]);

  useEffect(() => {
    if (!user.isNew) handleEditRole();
  }, [user]);

  useEffect(() => {
    if (CONFIG.isWhiteLabel && user.isNew) {
      setSelectedRole("");
    }
  }, []);

  useEffect(() => {
    if (message) setError(message);
  }, [message]);

  const appContext = useContext(AppContext);
  const sites = appContext.sites;
  const labs = appContext.labs;
  const clients = appContext.clients;

  const handleRoleChange = (selectedOption) => {
    // const permissionList = combinePermissions(selectedOption);

    // setPermission(permissionList);

    setSelectedRole(selectedOption);
    setNewUser({ ...newUser, role: selectedOption.map((t) => t.value).join(",") });
  };

  const filterPermissionsByRoles = (roles) => {
    if (customIsEmpty(roles)) {
      return PERMISSIONS_LIST;
    }

    if (roles.includes("Lab") || roles.includes("Employers")) {
      return PERMISSIONS_LIST;
    }

    const basePermissions = ["employee", "orders", "test", "pendingResults", "completedTests"];
    if (roles.includes("Provider")) {
      return PERMISSIONS_LIST.filter(
        (permission) => basePermissions.includes(permission.value) || permission.value === "checkEligibility"
      );
    }
    if (roles.includes("Tester") || roles.includes("LabTech")) {
      return PERMISSIONS_LIST.filter((permission) => basePermissions.includes(permission.value));
    }

    return [];
  };

  useEffect(() => {
    setCustomPermissionList(PERMISSIONS_LIST);
  }, []);

  // useEffect(() => {
  //   const rolesData = selectedRole ? selectedRole?.map((item) => item.value) : [];

  //   const res = filterPermissionsByRoles(rolesData);

  //   setCustomPermissionList(res);
  // }, [selectedRole]);

  const handleEditRole = async () => {
    try {
      const userData = { ...user };

      let selectedRoleData = [];
      user.oldRole.forEach((element) => {
        if (CONFIG.isWhiteLabel) {
          let roleObj = newRoleOptionsWhiteLabel.find((obj) => obj.value === element);
          if (roleObj) {
            selectedRoleData.push(roleObj);
          }
        } else {
          let roleObj = newRoleOptions.find((obj) => obj.value === element);
          if (roleObj) {
            selectedRoleData.push(roleObj);
          }
        }
      });

      // setSelectedRole(selectedRoleData);
      setNewUser({ ...userData, role: selectedRoleData.map((t) => t.value).join(",") });
    } catch (err) {
      console.log("Errr", err);
    }
  };

  const handleMultipleRoles = () => {
    let mainRoleArray = [];

    if (selectedRole.length === 0) {
      setError("Select Role");
      return false;
    }

    for (const singleRole of selectedRole) {
     
      let role = singleRole.value;
      let roleObj = {};

      if (
        role === "Lab" ||
        role === HEALTH_AND_SAFTEY ||
        role === "Client" ||
        role === "LabTech" ||
        role === "Employers" ||
        role === "Provider" ||
        role === "Tester"
      ) {
        if (role === "Lab" || role === "LabTech") {
          if (!newUser.lab) {
            setError("Select lab also");
            return false;
          }
          roleObj = {
            role: role,
            relatedId: newUser.lab.id,
            title: newUser.lab.name,
            label: newUser.lab.appPackage ? "isWhiteLabelLab" : "",
            id: singleRole?.id || "",
            permission: singleRole?.code || "",
            optLabel: singleRole.label,
            optValue: singleRole.value,
          };
          mainRoleArray.push(roleObj);
        }

        if (role === HEALTH_AND_SAFTEY || role === "Employers") {
          if (!newUser.show) {
            setError("Select show also");
            return false;
          }
          roleObj = {
            role: role,
            relatedId: newUser.show.id,
            title: newUser.show.name,
            id: singleRole?.id || "",
            permission: singleRole?.code || "",
            optLabel: singleRole.label,
            optValue: singleRole.value,
          };
          mainRoleArray.push(roleObj);
        }
        if (role === "Provider" || role === "Tester") {
          const isProviderSelected = !!newUser.provider;
          if (!isProviderSelected) {
            setError("Select Associated Client");
            return false;
          }

          let providerRelatedID = Array.isArray(newUser.provider) ? newUser.provider[0].id : newUser.provider.id;
          const roleObj = {
            role: role,
            relatedId: providerRelatedID,
            title: newUser.provider.name,
            id: singleRole?.id || "",
            permission: singleRole?.code || "",
            optLabel: singleRole.label,
            optValue: singleRole.value,
          };
          mainRoleArray.push(roleObj);
        }
        if (role === "Client") {
          if (!newUser.client) {
            setError("Select client also");
            return false;
          }
          roleObj = {
            role: role,
            relatedId: newUser.client.id,
            id: singleRole?.id || "",
            permission: singleRole?.code || "",
            optLabel: singleRole.label,
            optValue: singleRole.value,
          };
          mainRoleArray.push(roleObj);
        }
      } else {
        mainRoleArray.push({
          role: role,
          id: singleRole?.id || "",
          permission: singleRole?.code || "",
          optLabel: singleRole.label,
          optValue: singleRole.value,
        });
      }
    }

    // const clientID = appContext.user["custom:clientID"] || CONFIG.clientID;
    // if (clientID) {
    //   const isClientAdded = mainRoleArray.find((f) => f.relatedId === clientID);
    //   if (!isClientAdded) {
    //     mainRoleArray.push({ role: "Client", relatedId: clientID, title: CONFIG.basename });
    //   }
    // }

    return mainRoleArray;
  };

  const onHandleSave = async () => {
    if (!newUser.isNew && !systemUserPermissions?.systemUsers?.editUserCredentials) {
      appContext.showErrorMessage("You have not permision to edit user.");
      return;
    }

    if (CONFIG.isWhiteLabel) {
      Object.assign(newUser, {
        lab: handleLabObj(),
      });
    }

    let mainRoleData = handleMultipleRoles();

    

    if (!mainRoleData) return;
    if (!newUser.firstName.trim()) {
      setError("First name is required");
      return;
    }

    if (!newUser.lastName.trim()) {
      setError("Last name is required");
      return;
    }

    if (!newUser.email.trim()) {
      setError("Email  is required");
      return;
    }
    if (!isValidEmail(newUser.email)) {
      setError("Email is invaild");
      return;
    }

    if (!newUser.phone) {
      setError("Phone number is required");
      return;
    }
    const hasAtLeastOneAccessTrue = Object.values(permission).some(
      (singlePermission) => singlePermission?.access === true
    );

    if (!hasAtLeastOneAccessTrue) {
      setError("At least one permission must have access set to true");
      return;
    }

    // Ensure at least one dependent permission is true if settings is true
    const hasDependentPermissionTrue = dependentPermissionsList.some((item) => permission[item]?.access === true);

    if (permission?.settings?.access && !hasDependentPermissionTrue) {
      setError("If 'settings' is selected, at least one dependent permission must also be selected.");
      return;
    }

    if (!isValidPhoneWithCode(newUser.phone)) {
      setError("Invalid Phone Number");
      return;
    }

    if (newUser.isNew && !newUser.password) {
      setError("Password is requried");
      return;
    }

    if (newUser.isNew) {
      const isValidPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(newUser.password);

      if (!isValidPassword) {
        setError(
          "Password must contains at least one lowercase letter, one uppercase letter, one numeric digit, and one special character"
        );
        return;
      }
    }

    const systemUser = selectedRole.length === 1 && selectedRole[0].value === "Employee" ? 0 : 1;

    const oldUserData = users.find((item) => item.id === newUser.id);

    const fieldsName = {
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      is2FaEnabled: "Two Factor Auth",
      "provider.name": "Provider",
      // role: "Role",
      "userPermission.employee.access": "Patient Access Permission",
      "userPermission.employee.addPatientNote": "Add Patient Notes Permission",
      "userPermission.employee.archivePatient": "Archive Patients Permissions",
      "userPermission.employee.createPatient": "Create New Patient Permission",
      "userPermission.employee.editPatientRecord": "Edit Patient Record Permission",
      "userPermission.orders.access": "Order Access Permission",
      "userPermission.orders.addOrders": "Add Order Permission",
      "userPermission.orders.archiveOrders": "Archive Order Permission",
      "userPermission.orders.editOrders": "Edit Order Permission",
      "userPermission.orders.loadOrders": "Load Order Permission",
      "userPermission.test.archiveTest": "Archive Test Permission",
      "userPermission.test.createTest": "Create Test Permission",
      "userPermission.test.editTest": "Edit Test Permission",
      "userPermission.test.finalizeSendResult": "Finalize Or Send Result Permission",
      "userPermission.test.resultTest": "Result Test Permission",
      "userPermission.test.access": "Access Test Permission",
      "userPermission.pendingResults.access": "Pending Result Access Permission",
      "userPermission.completedTests.access": "Completed Test Access Permission",
      "userPermission.checkEligibility.access": "Check Eligibility Access",
      "userPermission.checkEligibility.write": "Check Eligibility Write",
      "userPermission.checkEligibility.delete": "Check Eligibility Archive",
    };

    let finalUser = {
      ...newUser,
      multipleRoles: mainRoleData.length > 0 ? JSON.stringify(mainRoleData) : "",
      systemUser,
      is2FaEnabled,
      userPermission: updateDefaulPermissionOnSave(permission),
    };
    // return;
    
    const logChangeObj = createChangeObjForLogs({
      oldRecord: oldUserData ? { ...oldUserData, userPermission: JSON.parse(oldUserData.userPermission) } : {},
      newRecord: { ...finalUser, is2FaEnabled: is2FaEnabled ? "y" : "n" },
      fieldsName,
    });

    let logsObj = {
      userID: appContext.user?.sub,
      userName: appContext.user?.name,
      slug: finalUser.isNew ? "User Created" : "User Updated",
    };

    if (!finalUser.isNew) {
      logsObj.changeObject = logChangeObj;
    }

    handleSave(finalUser, logsObj);
  };

  const updateDefaulPermissionOnSave = (selectedPermission) => {
    const defaulAllKeys = [
      "employee",
      "orders",
      "test",
      "pendingResults",
      "completedTests",
      "settings",
      "checkEligibility",
      "providerManagement",
      "clientManagement",
      "payerManagement",
      "diagnosisCodes",
      "procedureCodes",
      "systemUsers",
      "testsManagement",
      "testsDictionary",
    ];
    const cloneSelectedPermission = { ...selectedPermission };

    for (const item of defaulAllKeys) {
      const exist = customPermissionList.find((val) => val.value === item);
      if (!exist) {
        const perm = PERMISSIONS_LIST.find((obj) => obj.value === item);
        cloneSelectedPermission[item] = perm?.subObject
          ? transformSubObjectToPermissionObject(perm.subObject)
          : { access: false, write: false, delete: false };
      }
    }

    return cloneSelectedPermission;
  };

  const handlePhoneinput = (e) => {
    setNewUser({
      ...newUser,
      phone: e,
    });
  };

  const handleLabObj = () => {
    return {
      label: appContext.userLab.name,
      value: appContext.userLab.id,
      id: appContext.userLab.id,
      name: appContext.userLab.name,
      appPackage: appContext.userLab.whiteLabelPackage,
    };
  };

  const handleAdditionalFields = (roles) => {
    let obj = {};
    for (const role of roles) {
      if (role.role === HEALTH_AND_SAFTEY || role.role?.toLowerCase().includes("employer")) {
        const site = getUserSiteName(role?.relatedId);
        if (site) {
          let show = { label: site.name, value: site.id, id: site.id, name: site.name };
          obj = { ...obj, show: show };
        }
      }

      if (role.role === USER_ROLE_TYPE.Lab) {
        const lab = getUserLabName(role?.relatedId);
        if (lab) {
          let labData = {
            label: lab.name,
            value: lab.id,
            id: lab.id,
            name: lab.name,
            appPackage: lab.whiteLabelPackage,
          };
          obj = { ...obj, lab: labData };
        }
      }

      if (role.role === USER_ROLE_TYPE.Client) {
        const client = getUserClientName(role?.relatedId);
        if (client) {
          let clientData = { label: client.name, value: client.id, id: client.id, name: client.name };
          obj = { ...obj, client: clientData };
        }
      }
    }
    return obj;
  };

  const getUserLabName = (labId) => {
    const filterLab = appContext.labs.filter((lab) => lab.id === labId);
    if (filterLab.length > 0) {
      return filterLab[0];
    }
  };

  const getUserClientName = (clientId) => {
    return appContext.clients.find((client) => clientId === client.id);
  };

  const getUserSiteName = (userId) => {
    if (userId) {
      const filterSite = appContext.sites.filter((s) => s.id === userId);
      if (filterSite.length > 0) {
        return filterSite[0];
      }
    }
  };

  const handelTwoFaChange = (event) => {
    const checked = event.target.checked;
    setIs2FaEnabled(checked);
  };

  return (
    <Modal
      show={show}
      animation={true}
      onHide={() => {
        setNewUser("");
        handleClose();
      }}
      style={{ paddingLeft: "0" }}
      centered
      size={"lg"}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {newUser.isNew ? "Create User" : "Update User"}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body
        style={{
          paddingTop: 0,
        }}
      >
        {loading && <Loader />}

        <div className="createClientsWrapper">
          <div className="leftSide">
            <div className="row mb-3">
              <div className="w-100 createClientsWrapper">
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">First Name: </label>
                  <input
                    value={newUser.firstName}
                    className="modalInput"
                    placeholder="First Name"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => setNewUser({ ...newUser, firstName: e.target.value })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Last Name: </label>
                  <input
                    value={newUser.lastName}
                    className="modalInput"
                    placeholder="Last Name"
                    onChange={(e) => setNewUser({ ...newUser, lastName: e.target.value })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Email: </label>
                  <input
                    value={newUser.email}
                    className="modalInput"
                    placeholder="Email"
                    style={{
                      width: "100%",
                    }}
                    onChange={(e) => setNewUser({ ...newUser, email: e.target.value.trim().toLowerCase() })}
                  />
                </div>
                <div className="form-group-wrapper">
                  <label className="modalLineHeaders">Phone #: </label>
                  {newUser.isNew ? (
                    <PhoneNoInput handleChange={handlePhoneinput} value={newUser?.phone} disabled={!newUser.isNew} />
                  ) : (
                    <input
                      value={formatPhoneNumber(newUser.phone, "", true)}
                      className="modalInput w-100"
                      disabled={true}
                      placeholder="Phone"
                    />
                  )}
                </div>
                <div className="form-group-wrapper">
                  {newUser.isNew && (
                    <>
                      <label className="modalLineHeaders">Password: </label>

                      <input
                        type="password"
                        className="modalInput"
                        value={newUser.password}
                        placeholder="Password"
                        style={{
                          width: "100%",
                        }}
                        onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
                      />
                    </>
                  )}
                </div>
                <div
                  className={`form-group-wrapper ${
                    newUser.role === "Employer" || newUser.role === "Lab" ? "activeEmploye" : ""
                  }`}
                >
                  {systemUserPermissions?.systemUsers?.createNewUserRole && (
                    <UserRoleSelect
                      value={selectedRole}
                      onChange={handleRoleChange}
                      settingType={TEST_SETTING_TYPES.ROLE}
                      required={true}
                      isMulti={true}
                    />
                  )}

                  {/* {systemUserPermissions?.systemUsers?.createNewUserRole && (
                      <div>
                        <label className="modalLineHeaders">Role:</label>
                        <Select
                          options={CONFIG.isWhiteLabel ? newRoleOptionsWhiteLabel : newRoleOptions}
                          blurInputOnSelect={true}
                          defaultValue={null}
                          isMulti
                          menuPlacement="auto"
                          placeholder="Select a Role"
                          onChange={handleRoleChange}
                          className="w-100 roleSelector"
                          value={selectedRole}
                        />
                      </div>
                    )} */}
                </div>

                {/* our permissions */}
                <div
                  className={`form-group-wrapper 
          ${newUser?.role?.includes("Employers") || newUser?.role?.includes("Lab") ? "activeEmploye" : ""}`}
                >
                  {(newUser?.role?.includes(HEALTH_AND_SAFTEY) || newUser?.role?.includes("Employers")) && (
                    <>
                      <label className="modalLineHeaders">
                        Select {newUser?.role?.includes("Employers") ? "Lab Client:" : "Show:"}
                      </label>
                      <Select
                        className="w-100 siteSelector"
                        options={sites.map((site) => {
                          return { label: site.name, value: site.id, id: site.id, name: site.name };
                        })}
                        value={newUser.show}
                        blurInputOnSelect={true}
                        defaultValue={null}
                        menuPlacement="auto"
                        placeholder="Select a Show"
                        onChange={(e) => setNewUser({ ...newUser, show: e })}
                      />
                    </>
                  )}
                </div>

                {systemUserPermissions?.systemUsers?.createNewUserRole && (
                  <div
                    className={`form-group-wrapper  
                     ${newUser?.role?.includes("Provider") || newUser?.role?.includes("Tester")}`}
                  >
                    {(newUser?.role?.includes("Provider") || newUser?.role?.includes("Tester")) && (
                      <>
                        <label className="modalLineHeaders">Select Client</label>
                        <Select
                          className="w-100 siteSelector"
                          options={sites.map((site) => {
                            return { label: site.name, value: site.id, id: site.id, name: site.name };
                          })}
                          isMulti
                          value={newUser.provider}
                          blurInputOnSelect={true}
                          defaultValue={null}
                          menuPlacement="auto"
                          placeholder="Select a Client"
                          onChange={(e) => setNewUser({ ...newUser, provider: e })}
                          components={{
                            Option: ReactSelectCustomOption,
                            ValueContainer: ReactSelectCustomContainer,
                          }}
                        />
                        {newUser.provider?.length > 1 && (
                          <div className="selected-values-update-user">
                            {newUser.provider?.map((selected) => (
                              <span key={selected.value} className="selected-value-badge">
                                {selected.label}
                                <span
                                  className="badge-remove"
                                  onClick={() =>
                                    setNewUser({
                                      ...newUser,
                                      provider: newUser.provider.filter((item) => item.value !== selected.value),
                                    })
                                  }
                                >
                                  ×
                                </span>
                              </span>
                            ))}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}

                <div
                  className={`form-group-wrapper ${
                    newUser?.role?.includes("Employer") || newUser?.role?.includes("Lab") ? "activeEmploye" : ""
                  }`}
                >
                  {newUser?.role?.includes("Lab") && !CONFIG.isWhiteLabel ? (
                    <>
                      <label className="modalLineHeaders">Select Lab:</label>
                      <Select
                        className="w-100  siteSelector"
                        options={labs.map((lab) => {
                          return {
                            label: lab.name,
                            value: lab.id,
                            id: lab.id,
                            name: lab.name,
                            appPackage: lab.whiteLabelPackage,
                          };
                        })}
                        blurInputOnSelect={true}
                        defaultValue={null}
                        value={newUser.lab}
                        menuPlacement="auto"
                        placeholder="Select a Lab"
                        // onChange={(e) => (console.log(e), setNewUser({ ...newUser, lab: e }))}
                        onChange={(e) => setNewUser({ ...newUser, lab: e })}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={`form-group-wrapper ${
                    newUser?.role?.includes("Employer") || newUser?.role?.includes("Lab") ? "activeEmploye" : ""
                  }`}
                >
                  {newUser?.role?.includes("Client") && (
                    <>
                      <label className="modalLineHeaders">Select Client:</label>
                      <Select
                        className="w-100 siteSelector"
                        options={clients.map((client) => {
                          return { label: client.name, value: client.id, id: client.id, name: client.name };
                        })}
                        value={newUser.client}
                        blurInputOnSelect={true}
                        defaultValue={null}
                        menuPlacement="auto"
                        placeholder="Select a Client"
                        onChange={(e) => setNewUser({ ...newUser, client: e })}
                      />
                    </>
                  )}
                </div>
                <div className="d-flex align-items-center column-gap-10 profile-detail">
                  <span className="section-title">Two Factor Auth: </span>
                  <SwitchToggle checked={is2FaEnabled} handleToogleChange={(e) => handelTwoFaChange(e)} />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="leftSide">
            <div className="row mb-3 permissionBox ">
              <div className="manage-permissions-heading">Manage Permissions</div>
              <div className="cool-scrollbar">
                {customPermissionList.map((m) => (
                  <UserPermissions
                    setPermission={setPermission}
                    permission={permission}
                    permissionData={m}
                    value={m.value}
                    key={m.value}
                    label={m.label}
                    isDelete={m.delete}
                    disabled={m?.disabled || false}
                    isWrite={m.write}
                    width="41.5%"
                  />
                ))}
              </div>
            </div>
          </div> */}

          {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          style={{ marginBottom: 10 }}
          variant="secondary"
          className="modalButtons"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>

        <div className="d-flex gap-3">
          {!newUser.isNew && (
            <Button
              style={{ marginBottom: 10 }}
              className="modalButtons"
              variant="primary"
              onClick={() => (handleClose(), resetCall(true))}
            >
              Reset Password
            </Button>
          )}
          <Button style={{ marginBottom: 10 }} variant="primary" className="modalButtons" onClick={onHandleSave}>
            {newUser.isNew ? "Create" : "Update"}
          </Button>
          {newUser.status === "UNCONFIRMED" && (
            <Button
              style={{ marginBottom: 10 }}
              variant="primary"
              className="modalButtons"
              onClick={() => (handleConfirmUser(newUser), handleClose())}
            >
              Confirm User
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default WhiteLabelUserModal;
