import React, { useEffect, useState } from "react";
import Select from "react-select";
import api from "api";
import Icon from "components/Icon";
import { ReactSelectCustomContainer } from "components/CustomReactSelect/ReactSelectCustomOptionContainer";
import { TEST_SETTING_TYPES } from "constant";
import CreateUserRolesOptions from "./CreateUserRolesOptions";
import { useDispatch } from "react-redux";
import { setError } from "store/features/general/generalSlice";

const useOptions = (settingType) => {
  const dispatch = useDispatch()
  const [options, setOptions] = useState([]);
  
  const getOptions = async () => {
    try {
      const optionList = await api.getGeneralSetting(settingType);
      setOptions(optionList);
    } catch (error) {
      console.error(`Error fetching ${settingType}:`, error);
    }
  };

  const addOption = async (optionData) => {
    const { option } = optionData;

    if (settingType === TEST_SETTING_TYPES.ROLE) {

      const isExist = options.some((item) => item.label.toLowerCase() === option.toLowerCase());
      if(isExist){
        dispatch(setError("This Role Already Exist."))
        return
      }
      if (!isExist) {
        await api.createRolesOption(settingType, optionData);
      }
      await getOptions();
    }
  };

  useEffect(() => {
    getOptions();
  }, [settingType]);

  return { options, addOption };
};

const UserRoleSelect = ({
  settingType,
  onChange,
  value,
  tabIndex = 1,
  label = "Role",
  className = "",
  title = "Create New Role",
  placeholder = "Select User Role",
  required = false,
  isMulti = false,
  defaultValue = null,
  defaultOptions = null,
  showAddIcon = true,
  showNumberSelected = false,
  mainClass = "test-component-element",
}) => {
  const { options, addOption } = useOptions(settingType);

  const [showCreateOptionModal, setShowCreateOptionModal] = useState(false);

  const handelAddOption = (value) => {
    addOption(value);

    setShowCreateOptionModal(false);
  };

  return (
    <div className={mainClass}>
      <div className="selector-label-container">
        {label && (
          <p className="test-type-input-label">
            {label}
            {required && <span style={{ color: "red" }}>*</span>}
          </p>
        )}
        {showAddIcon && (
          <Icon
            iconType={"createIconBlue"}
            containerClass={"ms-2 cursor-pointer"}
            innerClass={"test-selector-add-icon"}
            handleClick={() => setShowCreateOptionModal(true)}
          />
        )}
      </div>
      <div className="d-flex align-items-center">
        <Select
          className={`w-100 ${className}`}
          options={defaultOptions ? defaultOptions : options}
          blurInputOnSelect={true}
          menuPlacement={"auto"}
          defaultValue={defaultValue}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          tabIndex={tabIndex}
          isMulti={isMulti}
          getOptionValue={(option) => option.label}
          // components={{ ValueContainer: ReactSelectCustomContainer }}
          components={showNumberSelected ? { ValueContainer: ReactSelectCustomContainer } : undefined}
        />
      </div>
      {showCreateOptionModal && (
        <CreateUserRolesOptions
          handleClose={() => setShowCreateOptionModal(false)}
          title={title}
          label={label}
          handleSaveChange={handelAddOption}
          placeholder={placeholder}
        />
      )}
    </div>
  );
};

export default UserRoleSelect;
