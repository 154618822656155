import React, { useState, useRef, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import { Button, Modal } from "react-bootstrap";
import { fetchSignature } from "api";
import editIconBlue from "assets/img/edit-icon-blue.svg";
import GreenTick from "assets/img/green-tick-icon.png";
import ErrorMessage from "components/Message/ErrorMessage";
const SignaturePad = (props) => {
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  let signCanvas = useRef(null);
  const { appContext,consent, deleteSignature, title, getValidSignature } = props;
  const clearSignature = () => {
    setDisableSaveButton(false);
    if(consent)
    {
      try 
      {
        deleteSignature({consent,title})
      }
      catch(err) 
      {
        console.log(err)
      }
    }
    signCanvas.current.clear();
  }
  const handleClose = () => {
    setDisabled(false);
  }
  const onSaveSignature = (signCanvas) => {
    if (signCanvas.current.isEmpty()) {
      setError("Signature is required");
      return;
    }
    getValidSignature({signCanvas, title});
    setDisabled(false);
    setDisableSaveButton(true);
  }
  useEffect(() => {
    const renderTrimmedSignature = async () => {
      if (disabled && consent && signCanvas.current) {
        try {
          const result = await fetchSignature(consent); // Fetch the signature data
          const canvas = signCanvas.current.getCanvas();
          const ctx = canvas.getContext('2d');

          // Clear the canvas
          ctx.clearRect(0, 0, canvas.width, canvas.height);

          // Create an image from the result
          const img = new Image();
          img.onload = () => {
            // Calculate offsets to center the signature
            const xOffset = (canvas.width - img.width) / 2;
            const yOffset = (canvas.height - img.height) / 2;

            // Draw the image on the canvas at the calculated position
            ctx.drawImage(img, xOffset, yOffset);
          };
          img.src = result; // Set the trimmed signature URL
          setDisableSaveButton(true);
        } catch (err) {
          signCanvas.current.clear();
          appContext.showErrorMessage("Failed to fetch signature");
        }
      }
    };

    renderTrimmedSignature();
  }, [consent, disabled]);



  return <>
    <span
      style={{
        color: "#5b90fa",
        fontWeight: "500",
        marginBotton: "10px",
        padding: "0px",
        marginInline: "10px",
        fontSize: "18px",
        cursor: "pointer",
      }}
      onClick={() => setDisabled(true)}
    >
      {
        consent ? <img src={GreenTick} alt="tick icon" width={24} /> : <img
          src={editIconBlue}
          alt="edit icon"
        />
      }
      {title}
    </span>


    <Modal
    className="seperateModal"
      show={disabled}
      size="lg"
      centered
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{
        paddingTop: 0,
      }}>
        <div className="border border-2 p-2 w-inherit">
          <SignatureCanvas
            ref={signCanvas}
            penColor='black'
            // onEnd={() => onSignChange(signCanvas)}
            canvasProps={{ width: 700, height: 250 }}
          />
          <div>
          </div>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <div style={{ paddingInline: "2px", marginRight: "auto" }}>{error && <ErrorMessage error={error} handleChange={() => setError("")} />}</div>
            <Button
              style={{ margin: 1, }}
              disabled={disableSaveButton}
              variant="secondary"
              className={`modalButtons headerButton btn-fill`}
              onClick={() => onSaveSignature(signCanvas)}
            >Save</Button>
            <Button
              style={{ margin: 1 }}
              variant="secondary"
              className="modalButtons headerButton btn-fill"
              onClick={clearSignature}
            >Clear</Button>


            <Button style={{ margin: 1 }}
              variant="secondary"
              className="modalButtons headerButton btn-fill" onClick={handleClose}>Close</Button>
          </div>
        </div>

      </Modal.Body>

    </Modal>

  </>

}

export default React.memo(SignaturePad);



