import api from "api";
import { AppContext } from "context/app-context";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generalSettings } from "store/features/testDictionary/testDictionarySelectors";
import { fetchGeneralSettings } from "store/features/testDictionary/testDictionarySlice";
import { TEST_SETTING_TYPES } from "../../constant";

const useOptions = (settingType) => {
  const dispatch = useDispatch();
  const appContext = useContext(AppContext);

  const generalSettingsData = useSelector(generalSettings);

  const handleOption = async (optionName, id = null) => {
    try {
      let response;
      if (settingType === TEST_SETTING_TYPES.TEST_LABEL) {

        if (id) {
          response = await api.updateTestLabel(optionName, id);
        } else {
          response = await api.createTestLabel(optionName);
        }
      }
      else if (settingType === TEST_SETTING_TYPES.INSTRUMENT) {
        if (id) {
          response = await api.updateInstrument(optionName, id);
        } else {
          response = await api.createInstrument(optionName);
        }
      }
      else if(settingType === TEST_SETTING_TYPES.SPECIMEN_CONTAINER)
      {
        if(id)
        {
          response = await api.updateSpecimanContainer(optionName, id);
        }
        else 
        {
          response = await api.createSpecimanContainer(optionName); 
        }
      }
      else {
        if (!id) {

          const alreadyExist = generalSettingsData.some((item) => {
            const label = item.label ? item?.label.toLowerCase().trim() : "";
            const type = item.settingType ? item.settingType.toLowerCase().trim() : "";

            if (settingType === "source") {
              return label === optionName?.source?.toLowerCase().trim();
            }

            if (settingType === "cancelled") {
              return label === optionName?.label?.toLowerCase().trim();
            } else {
              return label === optionName.toLowerCase().trim() && type === settingType.toLowerCase().trim();
            }
          });

          if (alreadyExist) {
            appContext.showErrorMessage(`This ${settingType} already exist.`);
            return;
          }
        }

        const action = id ? api.updateOptions : api.createOption;
        response = await action(settingType, optionName, id, {
          sub: appContext?.user?.sub,
          name: appContext?.user?.name,
        });
      }

      if (response && response.id) {
        dispatch(fetchGeneralSettings());
      }
    } catch (error) {
      console.error(`Error ${id ? "updating" : "adding"} ${settingType}:`, error);
    }
  };

  const handleDelete = async (id) => {
    const response = await api.deleteOption(id);
    if (response && response.id) {
      dispatch(fetchGeneralSettings());
    }
  };

  return {
    addOption: (optionName) => handleOption(optionName),
    updateOption: (optionName, id) => handleOption(optionName, id),
    deleteOption: (id) => handleDelete(id),
  };
};

export default useOptions;
