import api from "api";
import MainTable from "components/Table/MainTable";
import { TABLE_QUICK_TOOLS } from "constant";
import { TEST_SETTING_TYPES } from "constant";
import { SYSTEM_USER_ROLES_PERSONALIZATION } from "constants/personalization";
import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import CreateUserRolesOptions from "./CreateUserRolesOptions";
import { AppContext } from "context/app-context";
import { checkRoleAssigned } from "utils";
import useOptions from "views/TestTypeSetting/useOptions";

const RolesViewModal = ({ handleClose }) => {
  const [rolesData, setRolesData] = useState([]);

  const appContext = useContext(AppContext);

  const { deleteOption } = useOptions(TEST_SETTING_TYPES.SPECIMEN_CONTAINER);

  const [editObj, setEditObj] = useState(null);

  const [loading, setLoading] = useState(false);

  const getOptions = async () => {
    try {
      const rolesOptions = await api.getGeneralSetting(TEST_SETTING_TYPES.ROLE);
      setRolesData(rolesOptions);
    } catch (error) {
      console.error(`Error fetching ${TEST_SETTING_TYPES.ROLE}:`, error);
    }
  };

  useEffect(async () => {
    await getOptions();
  }, []);

  const handleEdit = (item) => {
    setEditObj(item);
  };

  const handleDelete = async (item) => {
    const isRoleAssigned = checkRoleAssigned({ users: appContext.users, roleId: item.id });

    if (isRoleAssigned) {
      appContext.showErrorMessage("This role is currently assigned to a user and cannot be deleted.");
      return;
    }
    await deleteOption(item.id);
    await getOptions();
  };

  const handleCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.edit:
        handleEdit(row);
        break;
      case TABLE_QUICK_TOOLS.delete:
        handleDelete(row);
        break;
      default:
        break;
    }
  };

  const handelUpdateOption = async (data) => {
    setLoading(true);

    await api.updateRolesOption({ permission: data.permission, id: editObj.id });
    await getOptions();
    setEditObj(null);
    setLoading(false);
  };

  return (
    <>
      <Modal
        show
        animation={true}
        onHide={() => handleClose()}
        backdrop="static"
        centered
        size={"xl"}
        keyboard={true}
        className="second-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0" id="contained-modal-title-vcenter">
            Roles
          </Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ paddingTop: 0 }}>
          <div style={{ background: "#f2f0f0", paddingLeft: "4px", paddingRight: "4px" }}>
            <MainTable
              cssClass="table-noresponsive"
              trDataWrapperHeader={"trDataWrapperHeader"}
              tools={[TABLE_QUICK_TOOLS.edit, TABLE_QUICK_TOOLS.delete]}
              columns={SYSTEM_USER_ROLES_PERSONALIZATION}
              rows={rolesData}
              handleCellClick={handleCellClick}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" className="headerButton" onClick={() => handleClose()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {editObj && (
        <CreateUserRolesOptions
          handleClose={() => setEditObj(null)}
          title={"Update Role"}
          label={"Role"}
          handleSaveChange={handelUpdateOption}
          placeholder={"Update Role"}
          editObj={editObj}
          loading={loading}
          disabled={true}
        />
      )}
    </>
  );
};

export default RolesViewModal;
