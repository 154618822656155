import {
  ANTIBIOTIC_RESISTANCE_TYPE,
  CPT_CODE_SEARCH_TYPE,
  TABLE_QUICK_TOOLS,
  TEST_SETTING_TYPES,
  TEST_TYPE_OPTIONS,
  TYPE_OF_TEST,
} from "constant";
import { AppContext } from "context/app-context";
import moment from "moment";
import { memo, useContext, useEffect, useMemo, useState } from "react";
import { Accordion, Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectedCompanySetting } from "store/features/companySetting/companySettingSelectors";
import { refLabsSelectors } from "store/features/locations/locationsSelectors";
import { customCloneDeep } from "util/customLodash";
import {
  formatTargetRanges,
  generateRefIntervalByRanges,
  getTestPanelType,
  formatTestSource,
  getUniqueSources,
} from "utils";
import AddResultDisclaimer from "./TestTypeComponent/AddResultDisclaimer";
import ResultDisclaimerSwitch from "./TestTypeComponent/ResultDisclaimerSwitch";
import TestElemensInput from "./TestTypeComponent/TestElemensInput";
import TestIctOrCptSelector from "./TestTypeComponent/TestIctOrCptSelector";
import TestTypeInput from "./TestTypeComponent/TestTypeInput";
import TestTypeSelect from "./TestTypeComponent/TestTypeSelect";
import TestCptSelectorModal from "./TestCptSelector/TestCptSelectorModal";
import TestElementPositionModal from "./TestElementPositionModal/TestElementPositionModal";
import TestTypeInputDate from "./TestTypeComponent/TestTypeInputDate";
import TextEditor from "views/TextEditor";
import TestRangeModal from "./TestDetailsRangeView/TestRangeModal";
import { selectClient } from "store/features/authentication/authenticationSelectors";

const TestTypeModal = ({
  item,
  handleSave,
  handleClose,
  openDetailModal,
  isDetailModalOpen,
  mainTests,
  setTestPanelData,
}) => {
  const [newItem, setNewItem] = useState({
    ...item,

    isAssociatedPanel: item.hasOwnProperty("isAssociatedPanel") ? item.isAssociatedPanel : false,
    custom_notes: item.custom_notes || "",
    showDisclaimer: !!item.custom_notes,
  });

  const userClientData = useSelector(selectClient);

  const [showRangesModal, setShowRangesModal] = useState(false);

  const [showCptModal, setShowCptModal] = useState(false);

  const [showElementPositionModal, setShowElementPositionModal] = useState(false);

  const [ranges, setRanges] = useState(
    item?.ranges || [
      {
        id: 1,

        AgeStart: "",
        AgeEnd: "",

        Gender: "",
        Bacteria: "",

        NormalLow: "",
        NormalHigh: "",

        HighLow: "",
        HighHigh: "",

        PanicLow: "",
        PanicHigh: "",

        RepeatLow: "",
        RepeatHigh: "",

        LowLow: "",
        LowHigh: "",
      },
    ]
  );

  const [ictCodes, setIctCodes] = useState([]);

  const appContext = useContext(AppContext);

  const [showEditor, setShowEditor] = useState(null);

  const [cptList, setCptList] = useState([]);

  const companySettings = useSelector(selectedCompanySetting);
  const labLocations = useSelector(refLabsSelectors);

  useEffect(() => {
    const { ictCodes, cptCodes } = item;

    if (ictCodes?.length > 0) {
      setIctCodes(ictCodes);
      setCptList(ictCodes[0]?.cptCodes || cptCodes || []);
    } else if (cptCodes?.length > 0) {
      setCptList(cptCodes);
    }
  }, []);

  useEffect(() => {
    const { targetRanges } = item;

    setNewItem((prevNewItem) => ({
      ...prevNewItem,
      targetRanges: targetRanges ? targetRanges.slice() : [],
    }));
  }, [item]);

  useEffect(() => {
    const uniqueSources =
      newItem.targetRanges.length > 0 && (!newItem.sources || newItem.sources.length === 0)
        ? getUniqueSources(newItem.targetRanges)
        : newItem.sources;

    setNewItem({
      ...newItem,
      sources: uniqueSources,
      isAssociatedPanel: newItem.targetRanges.length > 0,
    });
  }, [newItem.targetRanges]);

  const clientDetails = useMemo(() => {
    if (appContext && Array.isArray(appContext.clients) && appContext.clients.length > 0) {
      return appContext.clients[0];
    }
    return {};
  }, [appContext?.clients]);

  const getSiteOptions = useMemo(() => {
    return labLocations.map((l) => {
      return { value: l.id, label: l.name };
    });
  }, [labLocations]);

  const getCliaNumber = useMemo(() => {
    if (!companySettings.cliaNumber) return [];
    return companySettings.cliaNumber.map((l) => {
      return { value: l.cliCode, label: l.label || l.cliCode };
    });
  }, [companySettings]);

  const validateInput = () => {
    if (!newItem.name) {
      appContext.showErrorMessage("Enter valid test name");

      return;
    }
    if (!newItem.code) {
      appContext.showErrorMessage("Enter valid test code");
      return;
    }
    if (!newItem.category) {
      appContext.showErrorMessage("Select the department");
      return;
    }

    if (!newItem.typeOfTest) {
      appContext.showErrorMessage("Select the valid Result Methodology");
      return;
    }

    if (!newItem.sources || newItem.sources.length === 0) {
      appContext.showErrorMessage("Select at least one source.");
      return;
    }

    if (newItem.code && newItem.code.length > 4) {
      appContext.showErrorMessage("Test Code should not be greater then 4");
      return;
    }

    if (newItem.custom_notes == "" && newItem.typeOfTest !== TYPE_OF_TEST.EXTERNAL) {
      appContext.showErrorMessage("Please add disclaimer");
      return;
    }

    if (newItem.targetRanges?.length === 0 && newItem.typeOfTest !== TYPE_OF_TEST.EXTERNAL) {
      appContext.showErrorMessage("Add Atleast One Pathogen.");
      return;
    }

    if (newItem.typeOfTest === TYPE_OF_TEST.EXTERNAL && newItem?.setting?.sendOut !== true) {
      appContext.showErrorMessage("External Result Must Be Send Out.");
      return;
    }

    if (
      newItem?.setting?.sendOut === true &&
      (!newItem.setting.referenceLab || newItem.setting.referenceLab === null) &&
      (!newItem?.setting?.referenceLabID || newItem?.setting?.referenceLabID === null)
    ) {
      appContext.showErrorMessage("Add Reference Lab for send out.");
      return;
    }

    if (getCliaNumber.length > 1 && !newItem.location) {
      appContext.showErrorMessage("Select Location.");
      return;
    }

    const formatedTargetRanges = formatTargetRanges(
      newItem.targetRanges,
      newItem.typeOfTest,
      newItem.isAssociatedPanel
    );

    // const refInt = generateRefInterval(newItem);
    const refInt = generateRefIntervalByRanges(ranges);
    // const uniqueSources = getUniqueSources(newItem.targetRanges);

    const testObj = {
      ...newItem,
      // sources: uniqueSources,
      // units: !newItem.isAssociatedPanel ? newItem.units : null,
      ictCodes: ictCodes && ictCodes.length > 0 ? [{ ...ictCodes[0], cptCodes: cptList }] : [],
      refInterval: newItem?.typeOfTest === TYPE_OF_TEST.QUANTITATIVE ? refInt : "Not Detected",
      targetRanges: newItem.typeOfTest === TYPE_OF_TEST.EXTERNAL ? [] : formatedTargetRanges,
      custom_notes: newItem.typeOfTest === TYPE_OF_TEST.EXTERNAL ? null : newItem.custom_notes,
      showDisclaimer: newItem.typeOfTest === TYPE_OF_TEST.EXTERNAL ? false : newItem.showDisclaimer,
      type: getTestPanelType(newItem.typeOfTest, newItem.isAssociatedPanel),
      ranges: !newItem.isAssociatedPanel ? ranges : null,
      cptCodes: cptList,
    };

    if (newItem.isNew) {
      const isAlreadyExist = mainTests.some(({ name }) => name.toLowerCase().includes(newItem.name.toLowerCase()));
      if (isAlreadyExist) {
        appContext.showErrorMessage("Test with same name already exists");
        return;
      }
    }
    handleSave(testObj);
  };

  const handleTestRemove = (item) => {
    setNewItem((prevNewItem) => {
      const updatedTargetRanges = prevNewItem?.targetRanges?.filter((x) => x.id !== item.id);
      return { ...prevNewItem, targetRanges: updatedTargetRanges };
    });
  };

  const handleTestSelect = (item) => {
    const itemExists = newItem?.targetRanges?.some((x) => x.id === item.id);

    if (!itemExists) {
      handleEditAssociatedPanels({ ...item, ranges: null });
    }
  };

  const handleDisclaimerSave = (val, type) => {
    setShowEditor(null);
    setNewItem({ ...newItem, custom_notes: val });
  };

  const handleEditCellClick = (key, row, event) => {
    switch (key) {
      case TABLE_QUICK_TOOLS.edit:
        handleEditAssociatedPanels(row);
        break;
      default:
        break;
    }
  };

  const handleEditAssociatedPanels = (accPanel) => {
    setTestPanelData({
      isNew: false,
      ...accPanel,
      typeOfTest: newItem.typeOfTest,
      currentRanges: newItem.targetRanges,
    });
    openDetailModal();
  };

  const handelEffectiveDate = (e, fieldName) => {
    const effectiveDate = moment(e).format("YYYY-MM-DD") != "Invalid date" ? moment(e).format("YYYY-MM-DD") : "";

    setNewItem({ ...newItem, setting: { ...newItem.setting, [fieldName]: effectiveDate } });
  };

  const handelLoincCode = (e) => {
    const value = e.target.value;

    const numericValue = value.replace(/[^0-9]/g, "");

    setNewItem({ ...newItem, lonicCode: numericValue });
  };

  const handelCtValue = (e) => {
    const value = e.target.value;

    const numericValue = value.replace(/[^0-9.]/g, "");

    const sanitizedValue =
      numericValue.split(".").length > 2 ? numericValue.substring(0, numericValue.lastIndexOf(".")) : numericValue;

    setNewItem({ ...newItem, setting: { ...newItem.setting, ctValue: sanitizedValue } });
  };

  const handelRemoveGrid = (id) => {
    if (ranges.length > 1) {
      const filteredRanges = ranges.filter((item) => item.id !== id);
      setRanges(filteredRanges);
    }
  };

  const handleChangeRanges = (e, index) => {
    const { name, value } = e.target;
    let copyRanges = customCloneDeep(ranges);
    copyRanges[index][name] = value;
    setRanges(copyRanges);
  };

  const handleChangeGender = (e, index) => {
    let copyRanges = customCloneDeep(ranges);
    copyRanges[index].Gender = e;
    setRanges(copyRanges);
  };

  const handleChangeBacteria = (e, index) => {
    let copyRanges = customCloneDeep(ranges);
    copyRanges[index]["Bacteria"] = e;
    setRanges(copyRanges);
  };

  const handleAddRange = (rangeValue) => {
    setRanges((prevRanges) => [
      ...prevRanges,
      {
        id: prevRanges.length + 1,

        ...rangeValue,

        AgeStart: "",
        AgeEnd: "",

        NormalLow: "",
        NormalHigh: "",

        HighLow: "",
        HighHigh: "",

        PanicLow: "",
        PanicHigh: "",

        RepeatLow: "",
        RepeatHigh: "",

        LowLow: "",
        LowHigh: "",
      },
    ]);
  };

  // Pathogens and Reactive Data

  const organismsRows = useMemo(
    () =>
      newItem?.targetRanges?.filter((item) => item?.antibioticResistanceType === ANTIBIOTIC_RESISTANCE_TYPE.ORGANISM) ||
      [],
    [newItem?.targetRanges]
  );

  const resistanceRows = useMemo(
    () =>
      newItem?.targetRanges?.filter(
        (item) => item?.antibioticResistanceType === ANTIBIOTIC_RESISTANCE_TYPE.RESISTANCE
      ) || [],
    [newItem?.targetRanges]
  );

  const associatedRows = useMemo(
    () =>
      newItem?.targetRanges?.filter(
        (item) => item?.antibioticResistanceType === ANTIBIOTIC_RESISTANCE_TYPE.ASSOCIATED
      ) || [],
    [newItem?.targetRanges]
  );

  const additionalRows = useMemo(
    () =>
      newItem?.targetRanges?.filter(
        (item) => item?.antibioticResistanceType === ANTIBIOTIC_RESISTANCE_TYPE.ADDITIONAL
      ) || [],
    [newItem?.targetRanges]
  );

  const handleSaveCptAndIct = ({ cptList }) => {
    setCptList(cptList);
  };
  const handleSaveTestElementPositions = (data) => {
    setNewItem({ ...newItem, targetRanges: data });
    setShowElementPositionModal(false);
  };

  const handleDiagCode = (e) => {
    if (!e) {
      setIctCodes([]);
      return;
    }
    setIctCodes([e.ictCodes[0]]);
  };
  const handleManageText = () => {
    setIsManageModal(true);
  };

  const handelChangeResultType = (e) => {
    const newItemUpdate = {
      ...newItem,
      typeOfTest: e.label,
      setting:
        e.label === TYPE_OF_TEST.EXTERNAL
          ? { ...newItem.setting, sendOut: true }
          : {
              ...newItem.setting,
              sendOut: false,
              referenceLab: null,
              referenceLabID: null,
            },
    };

    setNewItem(newItemUpdate);
  };



  return (
    <Modal show animation={true} onHide={() => handleClose()} centered size={"2xl"} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title className="my-0 h4" id="contained-modal-title-vcenter">
          Panel Management
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="testAndAssayManagementModal" style={{ padding: "10px", paddingTop: "0px" }}>
        <div className="test-type-container">
          <div className="test-type-column test-type-column-70">
            <p className="test-component-title">Panel Information</p>
            <div className="test-component-container">
              <div className="test-component-elements">
                <TestTypeInput
                  label={"Panel Name"}
                  placeholder="Enter Name"
                  onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
                  value={newItem?.name}
                  required={true}
                />
                <TestTypeInput
                  label={"Internal Panel Code"}
                  placeholder="Enter Internal Code"
                  onChange={(e) => setNewItem({ ...newItem, code: e.target.value })}
                  value={newItem?.code}
                  required={true}
                  maxLength={4}
                />

                <TestTypeSelect
                  value={newItem.category ? { label: newItem.category, value: newItem.category } : null}
                  onChange={(e) => setNewItem({ ...newItem, category: e.label })}
                  settingType={TEST_SETTING_TYPES.DEPARTMENT}
                  label={"Department"}
                  title={"Create Department"}
                  placeholder="Select Department"
                  updateMainTest={(value) => setNewItem({ ...newItem, category: value })}
                  required={true}
                />

                <TestTypeSelect
                  value={newItem.typeOfTest ? TEST_TYPE_OPTIONS.find((f) => f.label === newItem.typeOfTest) : null}
                  onChange={handelChangeResultType}
                  label={"Result Type"}
                  placeholder="Select Result Type"
                  defaultOptions={TEST_TYPE_OPTIONS}
                  showAddIcon={false}
                  required={true}
                />

                <ResultDisclaimerSwitch
                  label="Result Disclaimer"
                  placeholder={"Result Disclaimer"}
                  checked={newItem?.showDisclaimer ? true : false}
                  handleChange={(e) => setNewItem({ ...newItem, showDisclaimer: e.target.checked })}
                  disabled={newItem?.custom_notes?.trim() !== ""}
                  required={newItem.typeOfTest !== TYPE_OF_TEST.EXTERNAL}
                />

                {newItem?.showDisclaimer || newItem?.custom_notes?.trim() !== "" ? (
                  <AddResultDisclaimer onClick={() => setShowEditor(true)} icon="editIconBlue" />
                ) : (
                  <div className="test-component-element"> </div>
                )}
              </div>

              <div className="mt-2 test-component-elements">
                <TestTypeSelect
                  value={newItem?.sources || null}
                  onChange={(e) => setNewItem({ ...newItem, sources: e })}
                  settingType={TEST_SETTING_TYPES.SOURCE}
                  label={"Sources"}
                  title={"Create Source"}
                  placeholder="Select Source"
                  isMulti={true}
                  updateMainTest={(value) => setNewItem({ ...newItem, sources: { label: value, value: value } })}
                  required={true}
                  // mainClass={getCliaNumber.length > 1 ? "test-component-element-66" : "test-component-element"}
                />
                <TestTypeSelect
                  value={newItem?.sampleContainer || null}
                  onChange={(e) => setNewItem({ ...newItem, sampleContainer: e })}
                  settingType={TEST_SETTING_TYPES.SPECIMEN_CONTAINER}
                  label={"Specimen Container"}
                  title={"Create Specimen Container"}
                  placeholder="Select Specimen Container"
                  isMulti={true}
                  // updateMainTest={(value) => setNewItem({ ...newItem, sampleContainer: { label: value, value: value } })}
                  required={true}
                  // mainClass={getCliaNumber.length > 1 ? "test-component-element-66" : "test-component-element"}
                />

                {getCliaNumber.length > 1 && (
                  <TestTypeSelect
                    label="Location"
                    placeholder="Select Location"
                    showAddIcon={false}
                    defaultOptions={getCliaNumber}
                    value={newItem?.location ? getCliaNumber.find((f) => f.value === newItem.location) : null}
                    required={true}
                    onChange={(e) =>
                      setNewItem({
                        ...newItem,
                        location: e.value,
                      })
                    }
                  />
                )}
              </div>
              <div className="test-component-elements mt-2">
                <TestElemensInput
                  resistanceRows={resistanceRows}
                  organismsRows={organismsRows}
                  associatedRows={associatedRows}
                  additionalRows={additionalRows}
                  handleTestRemove={(item) => handleTestRemove(item)}
                  onChange={handleTestSelect}
                  handleCellClick={handleEditCellClick}
                  required={newItem.typeOfTest !== TYPE_OF_TEST.EXTERNAL}
                />
              </div>
            </div>

            <div style={{ marginTop: "20px" }}>
              <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                  <Accordion.Header className="mt-0 test-component-title ms-0">Additional Information</Accordion.Header>
                  <Accordion.Body>
                    <div className="test-component-container">
                      <div className="test-component-elements">
                        <TestIctOrCptSelector
                          searchType={CPT_CODE_SEARCH_TYPE.ICT}
                          setItem={handleDiagCode}
                          item={ictCodes.length > 0 ? ictCodes[0] : {}}
                          label="ICD / Diagnosis Code"
                          placeholder="Enter ICD / Diagnosis Code"
                        />

                        <TestTypeInput
                          searchType={CPT_CODE_SEARCH_TYPE.CPT}
                          label={"CPT Code"}
                          readOnly
                          placeholder={"Click Mange to add CPT"}
                          handleShowCPT={() => setShowCptModal(true)}
                          value={cptList.length ? `Linked CPT Code (${cptList.length})` : ""}
                        />

                        <TestTypeInput
                          label={"LONIC Code"}
                          placeholder="LONIC Code"
                          onChange={handelLoincCode}
                          value={newItem?.lonicCode}
                        />

                        <div className="test-component-element">
                          <TestTypeInputDate
                            label="Effective from Date"
                            onChange={(event) => handelEffectiveDate(event, "effectiveFrom")}
                            value={newItem?.setting?.effectiveFrom}
                            icon
                          />
                        </div>

                        <div className="test-component-element">
                          <TestTypeInputDate
                            label="Effective to Date"
                            onChange={(event) => handelEffectiveDate(event, "effectiveTo")}
                            value={newItem?.setting?.effectiveTo}
                            icon
                          />
                        </div>

                        <TestTypeInput
                          label={"Test Reagent"}
                          placeholder="Test Reagent"
                          value={newItem?.setting?.reagent || ""}
                          onChange={(e) =>
                            setNewItem({ ...newItem, setting: { ...newItem.setting, reagent: e.target.value } })
                          }
                        />

                        <TestTypeInput
                          label={"Cutoff CT Value"}
                          placeholder="Cutoff CT Value"
                          onChange={handelCtValue}
                          value={newItem?.setting?.ctValue || ""}
                        />

                        <TestTypeInput
                          searchType={"elementPosition"}
                          label={"Element Position"}
                          readOnly
                          placeholder={"Click To Change Test Element Positions"}
                          handleShowCPT={() => setShowElementPositionModal(true)}
                          // value={cptList.length ? `Linked CPT Code (${cptList.length})` : ""}
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>

          <div className="test-type-column test-type-column-30">
            <p className="test-component-title">Panel Setting</p>
            <div className="test-component-container column-full-height">
              <ResultDisclaimerSwitch
                inlineLabel
                label="Active"
                checked={newItem?.isActive}
                handleChange={(e) => setNewItem({ ...newItem, isActive: e.target.checked })}
              />
              {userClientData?.setting?.arkstone === "y" && (
                <ResultDisclaimerSwitch
                  inlineLabel
                  label="Arkstone"
                  checked={newItem?.isARKStone}
                  handleChange={(e) => setNewItem({ ...newItem, isARKStone: e.target.checked })}
                />
              )}
              <ResultDisclaimerSwitch
                inlineLabel
                label="Send Out"
                checked={newItem?.setting?.sendOut}
                handleChange={(e) => {
                  const isChecked = e.target.checked;
                  setNewItem({
                    ...newItem,
                    setting: {
                      ...newItem.setting,
                      sendOut: isChecked,
                      referenceLab: isChecked ? newItem?.setting?.referenceLab : null,
                      referenceLabID: isChecked ? newItem?.setting?.referenceLabID : null,
                    },
                    typeOfTest: isChecked ? TYPE_OF_TEST.EXTERNAL : newItem.typeOfTest,
                  });
                }}
              />
              {newItem?.setting?.sendOut && (
                <div className="border-b-1 pb-3 pt-3">
                  <TestTypeSelect
                    label=""
                    placeholder="Select Reference Lab"
                    showAddIcon={false}
                    defaultOptions={getSiteOptions}
                    value={
                      newItem?.setting?.referenceLabID
                        ? getSiteOptions.find((f) => f.value === newItem.setting?.referenceLabID)
                        : null
                    }
                    onChange={(e) =>
                      setNewItem({
                        ...newItem,
                        setting: { ...newItem.setting, referenceLab: e.label, referenceLabID: e.value },
                      })
                    }
                  />
                </div>
              )}

              <ResultDisclaimerSwitch
                inlineLabel
                label="H&S"
                checked={newItem?.setting?.hs || false}
                noBorder
                handleChange={(e) =>
                  setNewItem({
                    ...newItem,
                    setting: { ...newItem.setting, hs: e.target.checked },
                  })
                }
              />
            </div>
          </div>
        </div>

        {showCptModal && (
          <TestCptSelectorModal
            cptData={cptList}
            handleClose={() => setShowCptModal(false)}
            saveCptData={handleSaveCptAndIct}
          />
        )}

        {showElementPositionModal && (
          <TestElementPositionModal
            testElementData={newItem?.targetRanges || []}
            handleClose={() => setShowElementPositionModal(false)}
            saveTestElementPosition={handleSaveTestElementPositions}
          />
        )}

        {showRangesModal && (
          <TestRangeModal
            data={ranges}
            onChange={handleChangeRanges}
            onChangeGender={handleChangeGender}
            onChangeBacteria={handleChangeBacteria}
            handelRemoveView={(data) => handelRemoveGrid(data)}
            handleClose={() => setShowRangesModal(false)}
            handleAddRange={() => handleAddRange()}
          />
        )}

        {showEditor && (
          <TextEditor
            text={newItem?.custom_notes || ""}
            handleSave={handleDisclaimerSave}
            handleClose={() => setShowEditor(null)}
            type={showEditor.type}
          />
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" className="modalButtons" onClick={() => handleClose()}>
          Close
        </Button>

        <Button variant="primary" className="modalButtons" onClick={validateInput}>
          {item.isNew ? "Create" : "Update"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(TestTypeModal);

const PATHOGEN_TABLE_STYLE = { background: "#f2f0f0", paddingLeft: "4px", paddingRight: "4px" };
