import React, { useState } from "react";
import SwitchToggle from "components/SwitchToggle/SwitchToggle";
import { dependentPermissionsList } from "constant";

const UserPermissions = (props) => {
  const { permission, setPermission, value, label, isWrite, isDelete, permissionData, width="46.5%",disabled=false } = props;

  const { subObject = [] } = permissionData;

  const handleToggleChange = (e) => {
    const access = e.target.checked;

    const newPermission = {
      ...permission,
      [value]: {
        ...permission[value],
        access: access ? true : false,
        ...(access
          ? {}
          : Object.keys(permission[value]).reduce((acc, key) => {
              acc[key] = false;
              return acc;
            }, {})),
      },
    };

    if (value === "settings" && !access) {
      dependentPermissionsList.forEach((item) => {
        newPermission[item] = { access: false, write: false, delete: false };
      });
    }
    if (dependentPermissionsList.includes(value) && access) {
      newPermission.settings = { ...permission.settings, access: true };
    }
    setPermission(newPermission);
  };

  const handelSubToggleChange = (e, keyValue) => {
    const checkedValue = e.target.checked;
    const newPermission = {
      ...permission,
      [value]: { ...permission[value], [keyValue]: checkedValue },
    };
    setPermission(newPermission);
  };

  return (
    <div>
      <div className="userPermission profile-detail">
        <div className="d-flex align-items-center justify-content-between">
          <span className="mx-1 font-bold">{label}</span>
          <SwitchToggle disabled={disabled} checked={permission[value]?.access} handleToogleChange={handleToggleChange} />
        </div>

        <>
          {isWrite && (
            <div className="d-flex mx-2 align-items-center column-gap-10">
              <span className="mx-1">Write</span>
              <input
                type="checkbox"
                checked={permission[value]?.write}
                disabled={!permission[value]?.access}
                onChange={(e) =>
                  setPermission({
                    ...permission,
                    [value]: { ...permission[value], write: e.target.checked ? true : false },
                  })
                }
              />
            </div>
          )}
          {isDelete && (
            <div className="d-flex mx-2 align-items-center column-gap-10">
              <span className="mx-1">Archive</span>
              <input
                type="checkbox"
                checked={permission[value]?.delete}
                disabled={!permission[value]?.access}
                onChange={(e) =>
                  setPermission({
                    ...permission,
                    [value]: { ...permission[value], delete: e.target.checked ? true : false },
                  })
                }
              />
            </div>
          )}
        </>
      </div>

      <div className="userPermissionChecks ">
        {subObject.map((item) => (
          <div className="d-flex mx-2 align-items-center ">
            <span className="mx-1" style={{ width: width }}>
              {item.label}
            </span>
            <SwitchToggle
              checked={permission[value]?.[item.value] || false}
              handleToogleChange={(e) => handelSubToggleChange(e, item.value)}
              disabled={!permission[value]?.access || false}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserPermissions;
