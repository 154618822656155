import React, { useEffect, useState, useContext, useMemo } from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import editIcon from "../assets/img/edit-icon.svg";
import profileImageDefault from "../assets/img/default_profile.png";
import "bootstrap-daterangepicker/daterangepicker.css";
// react-bootstrap components
import { Button, Card, Form, Container, Row, Col, Modal } from "react-bootstrap";
import { useLocation, useHistory } from "react-router-dom";
import GQAPI from "views/GraphQL/gqApi";
import API from "api";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import { AppContext } from "../context/app-context";
import { PDFViewer } from "@react-pdf/renderer";
import RequisitionPDF from "components/RequisitionPDF";
import ErrorMessage from "components/Message/ErrorMessage";
import QrCodeDownloadIcon from "assets/img/qr-code-rounded.svg";
import { Bounce } from "react-activity";
import {
  TEST_TYPE_VALUE,
  countryListAllIsoData,
  IMAGE_UPLOAD_TYPE,
  PROFILE_IMAGE_PATH,
  TEST_EMPTY_OBJECT,
  RESULT_COLOR,
  externalTypesOptions,
} from "constant";
import {
  getDemoGraphics,
  formatZip,
  formatTest,
  isValidPhone,
  setPhoneNo,
  formatPhoneNumber,
  formatDateOfBirthDOB,
  phoneFormatter,
  formatQuaratineDisplayDate,
  parseTestType,
  FormatAWSDate,
  isValidName,
  isValidPhoneWithCode,
  isValidIdNum,
  getValidName,
  convertToLower,
  isValidZipCode,
  validSpace,
  convertToUpper,
  getPhoneNo,
  isValidEmail,
  connectedIDByURL,
  ChangeObject,
  formatPhoneWithOutSpaces,
  qrResponseJOSN,
} from "../utils";
import "react-activity/dist/Bounce.css";
import { Storage } from "aws-amplify";
import ImageView from "components/Image/ImageView";
import ReactPDF from "@react-pdf/renderer";
import PDF from "components/PDF";
import moment from "moment";
import QuarantineModal from "components/Modal/QuarantineModal";
import EditTestDetailsModal from "components/Modal/EditTestDetailsModal";
import ConfirmationModal from "components/Modal/ConfirmationModal";
import SingleDatePicker from "Date/SingleDatePicker";
import PhoneNoInput from "components/Phone/PhoneNoInput";
import ImageInput from "components/Input/ImageInput";
import "react-tabs/style/react-tabs.css";
import Loader from "../components/Loader/Loader";
import TestModal from "components/Modal/TestModal";
import AutoCompleteAddress from "components/AutoCompleteAddress";
import { stringConstant } from "constant";
import { CONFIG } from "constant";
import { QRCode } from "react-qrcode-logo";
import gqApi from "./GraphQL/gqApi";
import BioLabRequisitionPDF from "components/BioLabRequisitionPDF";
import PDFLayout from "components/PDF/PDFLayout";
import RadioInput from "components/RadioInput/RadioInput";
import { SYMPTOMS_OPT } from "constant";
import CheckBoxInput from "components/CheckBoxInput/CheckBoxInput";
import { UN_RESULT } from "constant";

function Test(props) {
  const location = useLocation();
  const history = useHistory();
  const { state } = location;
  const [test, setTest] = useState();
  const [site, setSite] = useState();
  const [lab, setLab] = useState();
  const [siteContactPhone, setSiteContactPhone] = useState();
  const [demographics, setDemographics] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [releaseTo, setReleaseTo] = useState([]);
  const [showPDF, setShowPDF] = useState();
  const [testsLab, setTestsLab] = useState();
  const [testPdf, setTestPdf] = useState();
  const [showPdfResult, setShowPdfResult] = useState(false);
  const [signature, setSignature] = useState();
  const [loading, setLoading] = useState(false);
  const [hippaConsent, setHippaConsent] = useState(false);
  const [showImages, setShowImages] = useState(null);
  const [showSubmitAlert, setShowSubmitAlert] = useState(false);
  const [editButton, setEditButton] = useState(true);
  const [editInfo, setEditInfo] = useState();
  const [Employee, setEmployee] = useState({});
  const [error, setError] = useState("");
  const [openQuarantined, setOpenQuaratined] = useState(false);
  const [allowToEditQuarantine, setAllowToEditQuarantine] = useState(false);
  const [openDataChangeConfirmation, setOpenDataChangeConfirmation] = useState(false);
  const [changeBarcodeConfirmation, setChangeBarcodeConfirmation] = useState(false);
  const [patternConsent, setPatternConsent] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [vaccinationImage, setVaccinationImage] = useState("");
  const [externalTestImage, setExternalTestImage] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [changeData, setchangeData] = useState({});
  const [changeDataCheck, setchangeDataCheck] = useState(true);
  const appContext = useContext(AppContext);
  const [profileImage, setProfileImage] = useState(profileImageDefault);
  const [openTestDetail, setOpenTestDetail] = useState(false);
  const [users, setUsers] = useState([]);
  //
  const [openCreator, setOpenCreator] = useState(false);
  const [newTestCreate, setTestCreate] = useState(_.cloneDeep(TEST_EMPTY_OBJECT));
  const [selectedTest, setSelectedTest] = useState(test);
  const [labs, setLabs] = useState([]);
  const [resultTypes, setResultTypes] = useState([]);

  const hideAlert = () => {
    props.history.push("/admin/pendingrelease");
    setShowAlert(false);
  };

  const handleCloseTestDetail = () => {
    setOpenTestDetail(false);
  };

  const handleSaveTestDetail = async (test) => {
    try {
      setLoading(true);
      const testData = await API.updateTestData(test);
      setDemographics(getDemoGraphics(testData));
      setEditInfo(testData);
      setTest(testData);
      setEmployee(getDemoGraphics(testData));
      setEditButton(true);
      switch (location?.state?.type) {
        case "pendingRelease":
          appContext.resetPendingTests();
          break;
        case "inconclusiveTest":
          appContext.resetInvalidTests();
          break;
      }
      appContext.showSuccessMessage("Test updated Successfully");
      setLoading(false);
    } catch (err) {
      console.log("Error:-", err.message);
    }
  };

  const getUserFromLocalDB = async () => {
    let getUserDAta = await API.getUserFromLocalDB();
    setUsers(getUserDAta);
  };

  const usersData = useMemo(() => {
    if (!users) return;
    return users
      .filter((u) => u.roles?.includes("Tester") || u.roles?.includes("Admin"))
      .map((u) => {
        return {
          value: u.userID,
          label: `${u.firstName} ${u.lastName}`,
          phone_number: u.phone_number?.replace(/ /g, ""),
        };
      });
  }, [users]);

  const getSelectedTester = (onChangeTesterData) => {
    setTestCreate({
      ...newTestCreate,
      createdBy: onChangeTesterData.value,
      tester_name: onChangeTesterData.label,
      testerPhone: onChangeTesterData.phone_number,
    });
  };

  const getSiteOptions = useMemo(() => {
    if (!newTestCreate.clientID) return [];
    return appContext.sites
      ?.filter((s) => s.clientID === newTestCreate.clientID)
      .map((l) => {
        return { value: l.id, label: l.name };
      });
  }, [newTestCreate.clientID]);

  const triggerNewData = (data) => {
    const {
      match: { params },
      location: { state },
    } = props;
    if (data.id === state?.test?.id || data.id === params.testId) {
      if (!editButton) {
        setchangeData(data);
        setOpenDataChangeConfirmation(true);
      } else {
        setEditInfo(data);
        setDemographics(getDemoGraphics(data));
        setEmployee(getDemoGraphics(data));
        setTest(data);
      }
    } else {
      return;
    }
  };

  const downloadQRCode = () => {
    const canvas = document.getElementById("qr-gen-view");
    const pngUrl = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = Employee ? `${Employee.firstName} ${Employee.lastName}.png` : `QRCode.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  // SETTING UP LISTNER
  useEffect(() => {
    console.log(" SETTING UP LISTNER");
    if (changeDataCheck && appContext?.testObject && appContext.testObject?.opType === "UPDATE") {
      triggerNewData(appContext.testObject.element);
    }
  }, [appContext?.testObject]);

  useEffect(() => {
    getUserFromLocalDB();
  }, []);

  useEffect(() => {
    if (appContext?.clients?.length > 0) {
      const client = appContext.clients.find((f) => f.id === CONFIG.clientID);
      if (client && client.resultTypes) {
        setResultTypes([
          ...(client.resultTypes.results.map((m) => ({ value: m, label: m })) || []),
          { value: UN_RESULT, label: UN_RESULT },
        ]);
      }
    }
  }, [appContext.clients]);

  useEffect(() => {
    if (appContext.labs instanceof Array) {
      setLabs(appContext.labs);
    } else if (appContext.labs instanceof Object) {
      setLabs([appContext.labs]);
    }
    setSite(appContext.sites);
  }, [appContext.sites, appContext.labs]);

  // SETTING UP LISTNER
  useEffect(() => {
    if (changeDataCheck && appContext?.eventObject && appContext.eventObject?.opType === "UPDATE") {
      triggerNewData(appContext.eventObject.element);
    }
  }, [appContext?.eventObject]);

  const parseSrNoObjectRecviedDate = (test) => {
    try {
      const arr = JSON.parse(test.sr_no);
      let dateOfReceive = arr[0]?.date;
      if (dateOfReceive && formatDOB(dateOfReceive) === formatDOB(test.createdAt)) {
        dateOfReceive = moment(arr[0]?.date).add("30", "minutes").toISOString();
      }
      return dateOfReceive;
    } catch (error) {
      return null;
    }
  };

  const handleDataChangeConfirm = async (isConfirm) => {
    if (isConfirm) {
      setOpenDataChangeConfirmation(false);
      setEditInfo(changeData);
      setDemographics(getDemoGraphics(changeData));
      setEmployee(getDemoGraphics(changeData));
      setTestCreate(changeData);
      setTest(changeData);
    } else {
      setOpenDataChangeConfirmation(false);
    }
  };

  const handleChangeBarcodeNo = async (isConfirm) => {
    console.log("isConfirm", isConfirm);
    setChangeBarcodeConfirmation(false);
    if (isConfirm) {
      const res = await gqApi.validateAccessionNo(newTestCreate);

      if (!res) {
        appContext.showErrorMessage(`Accession No. ${newTestCreate.barcode} is already assigned. Please use the other`);
      } else {
        handleSave(true);
      }
    } else {
      handleSave(false);
    }
  };

  const getTest = async (id) => {
    try {
      setLoading(true);
      const model = await API.getRecordById(id, "test");
      setEditInfo(model[0]);
      setDemographics(getDemoGraphics(model[0]));
      setEmployee(getDemoGraphics(model[0]));
      setTest(model[0]);
      setSelectedTest(model[0]);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("Error:-", err.message);
    }
  };

  const {
    match: { params },
  } = props;

  useEffect(async () => {
    setLoading(true);
    const {
      match: { params },
      location: { state },
    } = props;

    if (state?.test?.id) {
      getTest(state?.test?.id);
    } else {
      if (params.testId) {
        getTest(params.testId);
      } else {
        //If location state is undefined then check the local stoarge for the test state and getTest from its id
        // let storagedata = JSON.parse(localStorage.getItem("state"));
        // if(storagedata != ""){
        //   await getTest(storagedata?.test?.id);
        //   localStorage.removeItem("state");
        // }
        let testID = connectedIDByURL(window.location.pathname);
        console.log("testID From getConnectedID: ", testID);
        if (testID?.testID != "") {
          await getTest(testID.testID);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (appContext.user) setAllowToEditQuarantine(appContext.user.isSite() || appContext.user.isClient());
  }, [appContext.user]);

  const getFileFromStorage = async (file, setImage) => {
    if (file) {
      try {
        const path = await Storage.get(file);
        setImage(path);
        console.log("path", path);
        return true;
      } catch {
        console.log("Image loading fail");
      }
    } else {
      return false;
    }
  };

  useEffect(async () => {
    if (test?.employeeID) {
      setLoading(true);
      let models = await API.getRecordById(test.employeeID, "employee");
      let vacinatedFilePath = models[0]?.vaccinationCardImage;
      let externalTestFilePath = models[0]?.externalTestImage;
      let profileImageFilePath = models[0]?.profileImage;
      let autoRelease = models[0]?.autoRelease || models[0]?.autoRelease == "1" ? true : false;
      // console.log("autoRelease", autoRelease);
      setEmployee({
        ...Employee,
        vaccinationCardImage: vacinatedFilePath,
        externalTestImage: externalTestFilePath,
        autoRelease,
      });
      if (externalTestFilePath) {
        let getExternalTestImage = await API.getFileExternalStorage(externalTestFilePath);
        setExternalTestImage(getExternalTestImage);
      }

      if (profileImageFilePath) {
        let getProfileImage = await API.getFileProfileStorage(profileImageFilePath);
        setProfileImage(getProfileImage);
      }

      if (vacinatedFilePath) {
        let getVacinationCardImage = await API.getFileExternalStorage(vacinatedFilePath);
        setVaccinationImage(getVacinationCardImage);
      }
      setLoading(false);
    }
  }, [test?.employeeID]);

  useEffect(() => {
    if (editInfo && editInfo.employee_demographics) {
      const emp = getDemoGraphics(editInfo);
      const list = countryListAllIsoData.find((f) => f.name === emp.country) || { code: "US" };
      setEmployee(emp);
      setTestCreate({ ...editInfo, employee_demographics: emp });
      setDemographics({ ...emp, isoCode: list.code });
    }
  }, [editInfo]);

  useEffect(() => {
    const getSite = async (id) => {
      const model = await API.findSiteByID(id);
      model.length > 0 && setSite(model[0]);
    };

    // console.log("pendingRelease", appContext.pendingTests);
    const getSignature = async (test) => {
      /* const hippaRecord = await API.findHippaConsent(test.employeeID, test.siteID);
      console.log("Hippa Record", hippaRecord);
      const isHippaConsent = true; */
      const imgObject = [];

      try {
        const siteID = test.baseSiteID || test.siteID;
        const fileName = `${siteID}&&${test.employeeID}&&sig.png`;
        const sigt = await Storage.get(fileName);
        setSignature(sigt);
        imgObject.push({ title: "HIPAA Consent", isHIPPA: true, imgSrc: sigt });

        const sig = await Storage.get(`${test.employeeID}&&${siteID}&&ins-f.jpeg`);
        if (sig) imgObject.push({ title: "Insurance Card Front", isHIPPA: false, imgSrc: sig });
        const sig1 = await Storage.get(`${test.employeeID}&&${siteID}&&ins-b.jpeg`);
        if (sig1) imgObject.push({ title: "Insurance Card Back", isHIPPA: false, imgSrc: sig1 });
      } catch (error) {
        console.log("Image Error", error);
      }

      if (imgObject.length > 0) {
        setShowImages(imgObject);
      }
      setHippaConsent(true);
    };

    if (test) {
      setSite(getSite(test.siteID));
      //appContext.getLab(test).then((resp) => setLab(resp));
      getSignature(test);
    }

    setLoading(false);
  }, [test]);

  useEffect(() => {
    site && setSiteContactPhone(site.contact_phone);
  }, [site]);

  function calculate_age(dob) {
    const val = formatDateOfBirthDOB(dob);
    const years = moment().diff(val, "years", false);
    return years;
  }

  const captalizeFirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formatIDNo = () => {
    if (calculate_age(demographics?.dob) < 18) {
      const id = demographics?.idNumber;
      if (id && id.length > 5) {
        return `${id.substring(0, 3)}-${id.substring(3, 5)}-${id.substring(5)}`;
      }
      if (id && id.length > 3) {
        return `${id.substring(0, 3)}-${id.substring(3)}`;
      }
      return demographics?.idNumber;
    }
    return demographics?.idNumber;
  };

  const showSubmitButton = () => {
    if (!test) return true;
    const st = test.status.toLowerCase();
    if (st === "new") return true;
    if (st === "pending" && !test.result) return true;
    return false;
  };
  const ImageUpload = async (fileName, image) => {
    if (image instanceof Object) {
      await Storage.put(fileName, image);
      return fileName;
    } else {
      return "";
    }
  };

  const displayPdf = async (test) => {
    if (!test.result) return;
    const empID = await appContext.getEmployeeID(test.employeeID);
    const t = test;
    t.empID = empID;
    appContext.getLab(test).then(async (resp) => {
      setTestsLab(resp);
      setShowPdfResult(true);
    });
    setTestPdf(formatTest(test));
  };

  const handleSave = async (isConfirm) => {
    let phoneNo = "";
    let countryCode = "";
    if (!Employee) return;
    if (!Employee.firstName) {
      setError("First name is required");
      return;
    }
    if (!isValidName(Employee.firstName)) {
      setError("First name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }
    if (!Employee.lastName) {
      setError("Last name is required");
      return;
    }
    if (!isValidName(Employee.lastName)) {
      setError("Last name is invalid must contains at least 2 letters and Name should be realistic");
      return;
    }

    if (!Employee.email) {
      setError("Email name is required");
      return;
    }
    if (!isValidEmail(Employee.email)) {
      setError("Invalid Email");
      return;
    }

    if (!Employee.phoneNumber) {
      setError("Phone name is required");
      return;
    }

    // FOR PHONE CHECK COUTRY CODE FOR INTERNATIONAL RECORD VERIFICATION
    const phone = formatPhoneNumberIntl(Employee.phoneNumber);
    const phoneArr = phone.split(" ");
    // check Country code exist

    if (phoneArr[0].includes("+")) {
      let [cc, pNo] = getPhoneNo(Employee.phoneNumber, phoneArr[0]);
      phoneNo = pNo;
      countryCode = cc;
    } else {
      phoneNo = Employee.phoneNumber;
      countryCode = Employee.countryCode ? Employee.countryCode : "+1";
    }

    if (phoneError || !isValidPhoneWithCode(`${countryCode}${phoneNo}`)) {
      setError("Invalid Phone Number");
      return;
    }
    if (!Employee.dob) {
      setError("DOB is required");
      return;
    }
    if (Employee.dob === "Invalid date") {
      setError("Invalid Date of Birth");
      return;
    }
    // if (!isValidZipCode(demographics.isoCode || "US", Employee.zip)) {
    //   setError("Invalid Postal Code");
    //   return;
    // }
    if (!Employee.idNumber) {
      setError(`${calculate_age(Employee.dob) < 18 ? "SSN" : "ID Number"} is required`);
      return;
    }
    if (!isValidIdNum(Employee.idNumber)) {
      setError(`Invalid ${calculate_age(Employee.dob) < 18 ? "SSN" : "ID Number"} must contains at least 5 characters`);
      return;
    }

    if (!newTestCreate.clientID) {
      setError("Client is required");
      return;
    }
    if (!newTestCreate.siteID) {
      setError("Site is required");
      return;
    }

    if (!newTestCreate.labID) {
      setError("Lab is required");
      return;
    }

    if (!newTestCreate.test_type || newTestCreate.test_type == "" || newTestCreate.test_type == "undefined") {
      setError("Test Type is required");
      return;
    }

    if (newTestCreate.barcode && newTestCreate.barcode.length !== 10) {
      setError("Barcode is Invalid e.g. (YYMMDD0001)");
      return;
    }
    if (
      newTestCreate.createdAt &&
      newTestCreate.resultDateTime &&
      moment(newTestCreate.resultDateTime).isBefore(moment(newTestCreate.createdAt))
    ) {
      setError("Result date time should be after date collected");
      return;
    }
    if (
      newTestCreate.createdAt &&
      newTestCreate.receivedDate &&
      moment(newTestCreate.receivedDate).isBefore(moment(newTestCreate.createdAt))
    ) {
      setError("Received date time should be after date collected");
      return;
    }
    setLoading(true);
    setchangeDataCheck(false);
    const testModal = await API.getRecordById(test.id, "test");
    const testVersionNo = testModal[0]._version;
    const models = await API.getRecordById(test.employeeID, "employee");
    const versionNo = models[0]?._version || null;
    const fileName = `${Employee.mdID ? Employee.mdID : test.employeeID}/${test.employeeID}-${
      Employee.phoneNumber
    }-${moment().toISOString()}`;

    // console.log("vaccinationImage", vaccinationImage);
    // console.log("externalTestImage", externalTestImage);
    // Vacination Image
    let vaccinationFileName = "";
    if (typeof vaccinationImage === "object") {
      vaccinationFileName = await API.saveFileExternalStorage(
        phoneNo,
        test.employeeID,
        IMAGE_UPLOAD_TYPE.VacinationCard,
        vaccinationImage
      );
    }

    // External Test Image
    let externalImageFileName = "";
    if (typeof externalTestImage === "object") {
      externalImageFileName = await API.saveFileExternalStorage(
        phoneNo,
        test.employeeID,
        IMAGE_UPLOAD_TYPE.ExternalTest,
        externalTestImage
      );
    }

    const emp = {
      ...Employee,
      countryCode: countryCode,
      vaccinationCardImage: vaccinationFileName ? vaccinationFileName : Employee.vaccinationCardImage,
      externalTestImage: externalImageFileName ? externalImageFileName : Employee.externalTestImage,
      _version: versionNo,
      updatedBy: appContext.user.sub,
      updatedByName: appContext.user.name,
      phoneNumber: phoneNo,
    };

    if (Employee?.autoRelease) {
      const pendingTestList = appContext.pendingTests?.filter((f) => f.employeeID === test.employeeID);
      // console.log("pendingTestList", pendingTestList);
      const paramsData = [];
      pendingTestList.forEach((element) => {
        paramsData.push({
          id: element.id,
          status: "Processed",
          timerStatus: "Processed",
          sequenceNo: element.sequenceNo,
          invalidResultStatus: 1,
        });
      });
      try {
        await GQAPI.releaseTest(paramsData);
      } catch (err) {
        console.log("Error:-", err.message);
      }
    }
    try {
      if (isConfirm && newTestCreate.barcode !== editInfo.barcode) {
        await GQAPI.addTestLogs({
          ids: [{ id: test.id, sequenceNo: test.sequenceNo }],
          oldValue: editInfo.barcode,
          newValue: newTestCreate.barcode,
          userID: appContext.user?.sub,
          userName: appContext.user?.name,
          slug: "AccessionNoChanged",
        });
      }
      const newTestObj = { ...newTestCreate, ...(!isConfirm && { barcode: editInfo.barcode }) };
      if (newTestCreate.result === UN_RESULT && CONFIG.isWhiteLabel) {
        Object.assign(newTestObj, { result: "", status: "Pending" });
      }

      const res = ChangeObject(emp, demographics, newTestObj, testModal[0]);
      let logs = null;

      if (Object.keys(res).length > 0) {
        const ids = [{ id: test.id, sequenceNo: test.sequenceNo }];
        logs = {
          ids: ids,
          changeObject: res,
          userID: appContext.user?.sub,
          userName: appContext.user?.name,
          slug: "Test Updated",
        };
      }

      if (newTestObj.receivedDate) {
        const srNo = JSON.parse(newTestObj.sr_no);
        srNo[1].date = moment(newTestObj.receivedDate).toISOString();
        console.log("srNo", srNo);
        newTestObj.sr_no = JSON.stringify(srNo);
      }

      const obj = {
        ...newTestObj,
        employee_demographics: emp,
        _version: testVersionNo,
        createdAt: moment(newTestObj.createdAt).toISOString(),
      };
      const updateTest = await API.updateCompleteCrewInfo({ crew: obj, user: appContext.user, isConfirm: isConfirm });
      if (logs) await GQAPI.addTestLogs(logs);

      setDemographics(getDemoGraphics(updateTest));
      setEditInfo(updateTest);
      setTest(updateTest);
      setEmployee(getDemoGraphics(updateTest));
      setTestCreate(updateTest);
      setEditButton(true);
      switch (location?.state?.type) {
        case "pendingRelease":
          appContext.resetPendingTests();
          break;
        case "inconclusiveTest":
          appContext.resetInvalidTests();
          break;
      }
      appContext.showSuccessMessage("Test updated Successfully");

      const {
        match: { params },
      } = props;
      if (params.employeeId) {
        getTest(params.employeeId);
      }
      if (params.testId) {
        getTest(params.testId);
      }

      setLoading(false);
    } catch (err) {
      console.log("Error in crew save", err);
      appContext.showErrorMessage(err.message);
      setLoading(false);
    }
    setTimeout(() => {
      setchangeDataCheck(true);
    }, 60000);
  };
  const handleCancelButton = async () => {
    // setEditButton(true);
    // if (!Employee) return;
    // setEmployee(getDemoGraphics(editInfo));

    if (!Employee) {
      setEditButton(true);
      return;
    }
    let models = await API.getRecordById(test.employeeID, "employee");
    let vacinatedFilePath = models[0]?.vaccinationCardImage;
    let externalTestFilePath = models[0]?.externalTestImage;
    let profileImageFilePath = models[0]?.profileImage;

    if (!profileImageFilePath) {
      setProfileImage(profileImageDefault);
    }
    if (!externalTestFilePath) {
      setExternalTestImage("");
    }
    if (!vacinatedFilePath) {
      setVaccinationImage("");
    }
    setEmployee(getDemoGraphics(editInfo));
    setTestCreate(editInfo);

    // const profile = await getFileFromStorage(Employee.profileImage, setProfileImage);

    // let getExternalTestImage = await API.getFileExternalStorage(Employee.externalTestImage);
    // if (!getExternalTestImage) setExternalTestImage("");
    // let getVacinationCardImage = await API.getFileExternalStorage(Employee.vaccinationImage);
    // if (!getVacinationCardImage) setVaccinationImage("");
    setEditButton(true);
  };
  const emailTest = async (t) => {
    setLoading(true);
    const test = formatTest(t);
    const params = [
      {
        id: test.id,
        sequenceNo: test.sequenceNo,
      },
    ];
    try {
      setLoading(true);
      await appContext.sendEmailToCrew(params, "Email");
      appContext.showSuccessMessage("Email has been sent successfully");
      setLoading(false);
    } catch (err) {
      console.log("err", err);
      setLoading(false);
    }
    // appContext.getLab(test).then(async (resp) => {
    //   ReactPDF.pdf(<PDF test={test} testsLab={resp} parseTestResultForPDF={appContext.parseTestResultForPDF} />)
    //     .toBlob()
    //     .then(async (data) => {
    //       try {
    //         const fileName = `${test.id}-result.pdf`;
    //         await Storage.put(fileName, data);
    //         await API.sendEmailToCrew([{ email: test.email, fileName: `public/${fileName}` }]);
    //         await API.updateEmailStatus(test.id);
    //         // send sms as well if email is sending first time
    //         if (!test.emailSend && test.phoneNumber) {
    //           let smsMessage = `Hello, your test results are in for your test with ${test?.site_name}, please log into Med Flow to review.`;
    //           const siteObj = appContext.sites.find((s) => s.id === test.siteID);
    //           if (siteObj && siteObj.messageResulted && result === "negative") {
    //             smsMessage = siteObj.messageResulted;
    //           } else if (siteObj && siteObj.messagePositive && result === "positive") {
    //             smsMessage = siteObj.messagePositive;
    //           }
    //           await API.sendSMSNotification(setPhoneNo(test.phoneNumber), smsMessage);
    //         }
    //         setLoading(false);
    //         appContext.showSuccessMessage("Email has been sent successfully");
    //       } catch (error) {
    //         console.log("Error");
    //         setLoading(false);
    //       }
    //     });
    // });
  };

  const handleSaveQuarantine = async (obj) => {
    try {
      await API.updateQuarantineData(test.id, obj.startDate, obj.endDate);
      getTest(test.id);
      window.location.reload();
      appContext.showSuccessMessage("Quarantine dates changed successfully");
    } catch (err) {
      appContext.showErrorMessage(err.message);
    }
  };

  const isPatternTest = () =>
    test && test.test_type === "PCR" && test.patternTestAnswer && test.patternTestAnswer.length > 5;

  const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  };
  const patternConsentFile = async () => {
    if (!test) return "";
    const siteID = test.baseSiteID || test.siteID;
    const fileName = `${siteID}-${test.employeeID}-pattern.pdf`;
    const result = await Storage.get(fileName, { download: true });
    downloadBlob(result.Body, `${test.barcode}-pattern.pdf`);
  };

  const dataOfBirthParse = (dob) => {
    if (dob && dob !== "Invalid date") {
      return formatDateOfBirthDOB(dob);
    }
    return moment().format("MM/DD/YYYY");
  };

  const handleCloseCreateTest = () => {
    setTestCreate(_.cloneDeep(TEST_EMPTY_OBJECT));
    setOpenCreator(false);
  };

  const updateTest = async (updatedTestData) => {
    updatedTestData.id = test?.id;
    updatedTestData._version = test?._version;
    let testData = {
      ...updatedTestData,
      submittedByName: appContext?.user?.name,
      createdBy: appContext?.user?.sub,
      barcode: test?.barcode,
    };

    const updateTest = await API.updateTestDataV1(testData);

    setLoading(false);

    if (updateTest) {
      appContext.showSuccessMessage("Test updated successfully");
      handleCloseCreateTest();
    } else {
      appContext.showErrorMessage("Test can't be updated");
    }
  };

  const handleOpenCreateTest = () => {
    if (test) {
      let selectedUser = test;
      const emptyObjectKeys = Object.keys(TEST_EMPTY_OBJECT);
      let newObjValue = { ...TEST_EMPTY_OBJECT };
      for (const objKey of emptyObjectKeys) {
        if (objKey == "employee_demographics") {
          let demographicObj =
            typeof selectedUser[objKey] === "string" ? JSON.parse(selectedUser[objKey]) : selectedUser[objKey];
          newObjValue[objKey] = { ...demographicObj, phoneNumber: selectedUser["phoneNumber"] };
          if (selectedUser["dob"]) {
            newObjValue[objKey] = { ...demographicObj, dob: moment(selectedUser["dob"]).format("MMDDYYYY") };
          }
        } else {
          newObjValue[objKey] = selectedUser[objKey];
        }
      }
      setTestCreate(newObjValue);
    }
    setOpenCreator(true);
  };

  const handleUpdateAddress = (val, flag) => {
    if (!flag) {
      setEmployee({ ...Employee, street: val });
    } else {
      const addressObj = {
        street: val.street,
        country: val.country,
        city: val.city,
        zipcode: val.zipcode,
        zip: val.zipcode,
        state: val.state,
        isoCode: val.isoCode,
      };
      setEmployee({ ...Employee, ...addressObj });
    }
  };

  return showPdfResult ? (
    <PDFLayout setShowPdf={setShowPdfResult} testPdf={testPdf} testsLab={testsLab} />
  ) : showPDF ? (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card style={{ padding: 20, marginLeft: "auto", marginRight: "auto" }} className="strpied-tabled-with-hover">
            <Card.Header>
              <Row style={{ alignItems: "center" }}>
                <Card.Title
                  as="h4"
                  style={{
                    marginBottom: 10,
                    fontWeight: "bolder",
                    color: "#565656",
                  }}
                >
                  Requisition Form - {demographics?.firstName} {demographics?.lastName}
                </Card.Title>
                <Button style={{ marginLeft: "auto", marginRight: "auto" }} onClick={() => setShowPDF(false)}>
                  Close
                </Button>
              </Row>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <PDFViewer className="pdfWrapper">
                <BioLabRequisitionPDF test={test} lab={lab} signature={signature} demographics={demographics} />
                {/* <SitePDF site={pdf} client={client} /> */}
              </PDFViewer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  ) : !loading ? (
    <>
      <Container fluid className="profile-container">
        <SweetAlert show={showAlert} success title="Success" onConfirm={hideAlert}>
          Test Released
        </SweetAlert>
        <SweetAlert
          show={showSubmitAlert}
          success
          title="Success"
          onConfirm={() => {
            window.history.back();
            setShowSubmitAlert(false);
          }}
        >
          Test Submitted Successfully.
        </SweetAlert>
        <Row className="crew-member-profile">
          <Col md="12">
            <Card className="member-profile-card">
              <Card.Header className="d-flex align-items-center justify-content-between">
                <Card.Title as="h4" className="w-100 d-flex justify-content-between mb-0">
                  <div>
                    Test Details
                    {calculate_age(demographics?.dob) < 18 ? <span className="crew-minor-text">- Minor</span> : null}
                    {CONFIG.isWhiteLabel
                      ? ` - ${newTestCreate?.barcode || ""}`
                      : newTestCreate.sequenceNo
                      ? ` - ${newTestCreate.sequenceNo}`
                      : ""}
                  </div>
                  {newTestCreate.barcode && (
                    <>
                      <div className="d-flex justify-content-between align-items-center position-relative  w-25">
                        <div className="d-flex align-items-center qr-code-download w-75">
                          {/* <i className="fas fa-download"></i> */}
                          <span className="mx-2">Patient QR Code:</span>
                          <img
                            src={QrCodeDownloadIcon}
                            alt="qr-code-download"
                            className="cursor-pointer"
                            width="30"
                            onClick={downloadQRCode}
                          />

                          <span style={{ display: "none" }}>
                            <QRCode
                              id="qr-gen-view"
                              ecLevel={"H"}
                              value={JSON.stringify(qrResponseJOSN(Employee, Employee?.id) || "")}
                              enableCORS={true}
                              size="250"
                              logoHeight={"70"}
                              logoWidth={"70"}
                              fgColor={"#000"}
                              bgColor={"#fff"}
                            />
                          </span>
                        </div>

                        <span className="cursor-pointer" onClick={() => history.goBack()}>
                          Go Back
                        </span>
                      </div>
                    </>
                  )}
                </Card.Title>
              </Card.Header>
              <Card.Body className="pt-3 pb-3">
                <Form className="crew-member-profile">
                  <Row>
                    <Col md="6">
                      <div className="testAdmin createClientsWrapper">
                        <div className="first-last-name-wrapper">
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">First Name</label>
                            <Form.Control
                              readOnly={editButton}
                              className={`${!editButton ? "modalInput" : ""}`}
                              onChange={(e) => setEmployee({ ...Employee, firstName: e.target.value })}
                              value={Employee?.firstName}
                              defaultValue={demographics?.firstName}
                              placeholder={`${!editButton ? "Enter First Name " : ""}`}
                              type="text"
                            />
                          </Form.Group>
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Last Name</label>
                            <Form.Control
                              readOnly={editButton}
                              className={`${!editButton ? "modalInput" : ""}`}
                              onChange={(e) => setEmployee({ ...Employee, lastName: e.target.value })}
                              value={Employee?.lastName}
                              defaultValue={demographics?.lastName}
                              placeholder={`${!editButton ? "Enter Last Name " : ""}`}
                              type="text"
                            />
                          </Form.Group>
                        </div>
                        <div className="first-last-name-wrapper">
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Date of Birth</label>
                            {editButton ? (
                              <Form.Control
                                readOnly={editButton}
                                value={formatDateOfBirthDOB(Employee?.dob)}
                                defaultValue={formatDateOfBirthDOB(demographics?.dob)}
                                placeholder="Date of Birth"
                                type="text"
                              />
                            ) : (
                              <div>
                                <SingleDatePicker
                                  onApply={(event, picker) => {
                                    setEmployee({ ...Employee, dob: moment(picker.startDate).format("YYYY-MM-DD") });
                                  }}
                                  value={dataOfBirthParse(Employee?.dob)}
                                  startDate={dataOfBirthParse(Employee?.dob)}
                                />
                              </div>
                            )}
                          </Form.Group>
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Phone Number</label>
                            {editButton ? (
                              <Form.Control
                                className={`${!editButton ? "modalInput underlinedText" : "underlinedText"}`}
                                onMouseOver={(e) => {
                                  editButton ? (e.target.style.cursor = "pointer") : (e.target.style.cursor = "auto");
                                  e.target.style.textDecoration = "underline";
                                }}
                                onClick={() =>
                                  editButton
                                    ? Employee?.phoneNumber && window.open(`tel:+1${Employee.phoneNumber}`)
                                    : false
                                }
                                onChange={(e) => setEmployee({ ...Employee, phoneNumber: setPhoneNo(e.target.value) })}
                                readOnly
                                maxLength={15}
                                value={formatPhoneNumber(Employee?.phoneNumber, Employee?.countryCode || "+1")}
                                defaultValue={formatPhoneNumber(Employee?.phoneNumber, Employee?.countryCode || "+1")}
                                placeholder={`${!editButton ? "Enter Phone Number " : ""}`}
                                type="text"
                              />
                            ) : (
                              <PhoneNoInput
                                error={setPhoneError}
                                handleChange={(e) => {
                                  setEmployee({ ...Employee, phoneNumber: e });
                                }}
                                value={formatPhoneWithOutSpaces(Employee?.phoneNumber, Employee?.countryCode || "+1")}
                                cssCode={"crewPhoneInput w-100"}
                              />
                            )}
                          </Form.Group>
                        </div>
                        <Form.Group className="form-group-wrapper">
                          <label className="modalLineHeaders mt-0 text-capitalize">Email Address</label>
                          <Form.Control
                            className={`${!editButton ? "modalInput underlinedText" : "underlinedText"}`}
                            onMouseOver={(e) => {
                              editButton ? (e.target.style.cursor = "pointer") : (e.target.style.cursor = "auto");
                              e.target.style.textDecoration = "underline";
                            }}
                            onClick={() =>
                              editButton ? Employee?.email && window.open(`mailto:${Employee.email}`) : false
                            }
                            onChange={(e) => setEmployee({ ...Employee, email: e.target.value })}
                            value={Employee?.email}
                            readOnly={editButton}
                            defaultValue={test?.email}
                            placeholder={`${!editButton ? "Enter Email " : ""}`}
                            type="email"
                          />
                        </Form.Group>
                        <div className="first-last-name-wrapper">
                          <Form.Group className="form-group-wrapper">
                            <label className="mt-0 modalLineHeaders text-capitalize">Address</label>
                            {/* <Form.Control
                            className={`${!editButton ? "modalInput" : ""}`}
                            onChange={(e) => setEmployee({ ...Employee, street: e.target.value })}
                            readOnly={editButton}
                            value={Employee?.street}
                            defaultValue={demographics?.street}
                            placeholder={`${!editButton ? "Enter Address  " : ""}`}
                            type="text"
                          /> */}
                            <AutoCompleteAddress
                              readOnly={editButton}
                              value={Employee?.street}
                              handleChange={handleUpdateAddress}
                            />
                          </Form.Group>
                          <Form.Group className="form-group-wrapper">
                            <label className="mt-0 modalLineHeaders text-capitalize">Address 2</label>
                            <Form.Control
                              className={`${!editButton ? "modalInput" : ""}`}
                              readOnly={editButton}
                              onChange={(e) => setEmployee({ ...Employee, street2: e.target.value })}
                              value={Employee?.street2 || ""}
                              defaultValue={demographics?.street2 || ""}
                              placeholder={`${!editButton ? "Enter Address 2 " : ""}`}
                              type="text"
                            />
                          </Form.Group>
                        </div>
                        <div className="first-last-name-wrapper">
                          <div className="form-group-wrapper">
                            <label className="modalLineHeaders">Barcode</label>
                            {editButton ? (
                              <input
                                className="modalInput w-100"
                                readOnly={editButton}
                                placeholder="Barcode"
                                value={newTestCreate?.barcode}
                                onChange={(e) => {
                                  setTestCreate({
                                    ...newTestCreate,
                                    barcode: e.target.value,
                                  });
                                }}
                              />
                            ) : (
                              <div className="merged-input-container">
                                <input
                                  className="merged-input"
                                  style={{ width: "22%" }}
                                  readOnly
                                  value={newTestCreate?.barcode?.slice(0, 6)}
                                />
                                <input
                                  className="merged-input"
                                  style={{ width: "78%" }}
                                  readOnly={test?.result}
                                  placeholder="Barcode"
                                  value={newTestCreate?.barcode.slice(6)}
                                  maxLength={"4"}
                                  onChange={(e) => {
                                    setTestCreate({
                                      ...newTestCreate,
                                      barcode: `${newTestCreate?.barcode?.slice(0, 6)}${e.target.value.replace(
                                        /[^0-9]/g,
                                        ""
                                      )}`,
                                    });
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          <div className="form-group-wrapper">
                            <label className="modalLineHeaders">Test Type </label>
                            <Select
                              className="w-100 siteSelector"
                              options={externalTypesOptions}
                              blurInputOnSelect={true}
                              isDisabled={editButton}
                              defaultValue={null}
                              menuPlacement="auto"
                              placeholder="Test Type"
                              value={
                                newTestCreate.test_type
                                  ? externalTypesOptions.find((f) => f.value === newTestCreate.test_type)
                                  : null
                              }
                              onChange={(e) => setTestCreate({ ...newTestCreate, test_type: e.value })}
                            />
                          </div>
                        </div>
                        <div className="first-last-name-wrapper">
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Date Collected</label>
                            <Form.Control
                              type="datetime-local"
                              readOnly={editButton}
                              placeholder="Date Collected"
                              value={
                                newTestCreate?.createdAt
                                  ? moment(newTestCreate?.createdAt).format("YYYY-MM-DDTHH:mm")
                                  : ""
                              }
                              onChange={(e) => setTestCreate({ ...newTestCreate, createdAt: e.target.value })}
                            />
                          </Form.Group>

                          {parseSrNoObjectRecviedDate(test) && (
                            <Form.Group className="form-group-wrapper">
                              <label className="modalLineHeaders mt-0 text-capitalize">Date Received</label>
                              <Form.Control
                                type="datetime-local"
                                readOnly={editButton}
                                placeholder="Date Received"
                                value={moment(newTestCreate.receivedDate || parseSrNoObjectRecviedDate(test)).format(
                                  "YYYY-MM-DDTHH:mm"
                                )}
                                onChange={(e) => setTestCreate({ ...newTestCreate, receivedDate: e.target.value })}
                              />
                            </Form.Group>
                          )}
                        </div>
                        <div className="first-last-name-wrapper">
                          {newTestCreate.result && (
                            <Form.Group className="form-group-wrapper">
                              <label className="modalLineHeaders mt-0 text-capitalize">Result Date</label>
                              <Form.Control
                                type="datetime-local"
                                readOnly={editButton}
                                placeholder="Result Date"
                                value={
                                  newTestCreate?.result
                                    ? newTestCreate?.resultDateTime
                                      ? moment(newTestCreate?.resultDateTime).format("YYYY-MM-DDTHH:mm")
                                      : newTestCreate?.resultDate
                                      ? moment(newTestCreate?.resultDate).format("YYYY-MM-DDTHH:mm")
                                      : moment(newTestCreate?.updatedAt).format("YYYY-MM-DDTHH:mm")
                                    : ""
                                }
                                onChange={(e) => setTestCreate({ ...newTestCreate, resultDateTime: e.target.value })}
                              />
                            </Form.Group>
                          )}
                          <div className="form-group-wrapper">
                            <label className="modalLineHeaders">Resulted By</label>
                            <input
                              className="modalInput"
                              readOnly={editButton}
                              placeholder="Resulted By"
                              style={{
                                width: "100%",
                              }}
                              value={newTestCreate?.StampByName}
                              onChange={(e) => {
                                setTestCreate({
                                  ...newTestCreate,
                                  StampByName: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="first-last-name-wrapper">
                          <div className="form-group-wrapper">
                            <label className="modalLineHeaders">Tester </label>
                            <Select
                              className="w-100 siteSelector"
                              options={usersData}
                              isDisabled={editButton}
                              blurInputOnSelect={true}
                              defaultValue={null}
                              menuPlacement="auto"
                              //value={getSelectedTester()}
                              placeholder="Select Tester"
                              value={
                                newTestCreate.createdBy
                                  ? usersData.find((f) => f.value === newTestCreate.createdBy)
                                  : newTestCreate.tester_name
                                  ? { label: newTestCreate.tester_name, value: newTestCreate.tester_name }
                                  : null
                              }
                              onChange={(e) => getSelectedTester(e)}
                            />
                          </div>
                          <div className="form-group-wrapper">
                            <label className="modalLineHeaders">{stringConstant.site}</label>
                            <Select
                              className="w-100 siteSelector"
                              options={getSiteOptions}
                              blurInputOnSelect={true}
                              isDisabled={editButton}
                              defaultValue={null}
                              menuPlacement="auto"
                              placeholder={`Select ${stringConstant.site}`}
                              value={
                                newTestCreate.siteID
                                  ? {
                                      label: newTestCreate.site_name,
                                      value: newTestCreate.siteID,
                                    }
                                  : null
                              }
                              onChange={(e) => setTestCreate({ ...newTestCreate, siteID: e.value, site_name: e.label })}
                            />
                          </div>
                        </div>

                        <div className="first-last-name-wrapper">
                          {test?.scanByTesterName && (
                            <div className="form-group-wrapper">
                              <label className="modalLineHeaders">Scan By Tester</label>
                              <span className="vaccination-status pl-3">{test?.scanByTesterName}</span>
                            </div>
                          )}
                          {test?.scanByLabUserName && (
                            <div className="form-group-wrapper">
                              <label className="modalLineHeaders">Scan By Lab</label>
                              <span className="vaccination-status pl-3">{test?.scanByLabUserName}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="testAdmin createClientsWrapper">
                        <div className="first-last-name-wrapper">
                          <Form.Group className="form-group-wrapper">
                            <label className="mt-0 modalLineHeaders text-capitalize">City</label>
                            <Form.Control
                              readOnly={editButton}
                              className={`${!editButton ? "modalInput" : ""}`}
                              onChange={(e) => setEmployee({ ...Employee, city: e.target.value })}
                              value={Employee?.city}
                              defaultValue={demographics?.city}
                              placeholder={`${!editButton ? "Enter City" : ""}`}
                              type="text"
                            />
                          </Form.Group>
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">State</label>
                            <Form.Control
                              className={`${!editButton ? "modalInput" : ""}`}
                              readOnly={editButton}
                              onChange={(e) => setEmployee({ ...Employee, state: e.target.value })}
                              value={Employee?.state}
                              defaultValue={demographics?.state}
                              maxLength={35}
                              placeholder={`${!editButton ? "Enter Country" : ""}`}
                              type="text"
                            />
                          </Form.Group>
                        </div>
                        <div className="first-last-name-wrapper">
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Postal Code</label>
                            <Form.Control
                              className={`${!editButton ? "modalInput" : ""}`}
                              onChange={(e) => setEmployee({ ...Employee, zip: e.target.value })}
                              readOnly={editButton}
                              value={Employee?.isoCode === "US" ? formatZip(Employee?.zip) : Employee?.zip}
                              defaultValue={Employee?.isoCode === "US" ? formatZip(Employee?.zip) : Employee?.zip}
                              placeholder={`${!editButton ? "Enter Postal Code " : ""}`}
                              maxLength={10}
                              type="text"
                            />
                          </Form.Group>
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Insurance Carrier</label>
                            <Form.Control
                              className={`${!editButton ? "modalInput" : ""}`}
                              readOnly={editButton}
                              onChange={(e) => setEmployee({ ...Employee, insurance_name: e.target.value })}
                              value={Employee?.insurance_name}
                              defaultValue={demographics?.insurance_name}
                              placeholder={`${!editButton ? "Enter Insurance Carrier" : ""}`}
                              type="text"
                            />
                          </Form.Group>
                        </div>
                        <div className="first-last-name-wrapper">
                          <Form.Group className="form-group-wrapper">
                            <label className="modalLineHeaders mt-0 text-capitalize">Insurance ID</label>
                            <Form.Control
                              className={`${!editButton ? "modalInput" : ""}`}
                              readOnly={editButton}
                              onChange={(e) => setEmployee({ ...Employee, insurance_number: e.target.value })}
                              value={Employee?.insurance_number}
                              defaultValue={demographics?.insurance_number}
                              placeholder={`${!editButton ? "Enter Insurance Id" : ""}`}
                              type="text"
                            />
                          </Form.Group>
                          <Form.Group className="form-group-wrapper" readOnly={editButton} plaintext>
                            {calculate_age(demographics?.dob) > 18 ? (
                              <label className="modalLineHeaders mt-0 text-capitalize">ID Number</label>
                            ) : (
                              <label>SSN</label>
                            )}
                            <Form.Control
                              readOnly={editButton}
                              className={`${!editButton ? "modalInput" : ""}`}
                              value={Employee?.idNumber}
                              onChange={(e) => setEmployee({ ...Employee, idNumber: e.target.value })}
                              defaultValue={formatIDNo()}
                              placeholder="ID Number"
                              type="text"
                            />
                          </Form.Group>
                        </div>
                        <div className="first-last-name-wrapper">
                          {labs.length > 1 && (
                            <div className="form-group-wrapper">
                              <label className="modalLineHeaders">Lab </label>
                              <Select
                                className="w-100 siteSelector"
                                options={labs?.map((l) => {
                                  return { value: l.id, label: l.name };
                                })}
                                blurInputOnSelect={true}
                                defaultValue={null}
                                isDisabled={editButton}
                                menuPlacement="auto"
                                placeholder="Select Lab"
                                value={
                                  newTestCreate.labID
                                    ? {
                                        label: newTestCreate.labName,
                                        value: newTestCreate.labID,
                                      }
                                    : null
                                }
                                onChange={(e) => setTestCreate({ ...newTestCreate, labID: e.value, labName: e.label })}
                              />
                            </div>
                          )}
                          {appContext?.clients.length > 1 && (
                            <div className="form-group-wrapper">
                              <label className="modalLineHeaders">Client </label>
                              <Select
                                className="w-100 siteSelector"
                                options={appContext.clients?.map((l) => {
                                  return { value: l.id, label: l.name };
                                })}
                                blurInputOnSelect={true}
                                defaultValue={null}
                                isDisabled={editButton}
                                menuPlacement="auto"
                                value={
                                  newTestCreate.clientID
                                    ? {
                                        label: newTestCreate.clientName,
                                        value: newTestCreate.clientID,
                                      }
                                    : null
                                }
                                placeholder="Select Client"
                                onChange={(e) =>
                                  setTestCreate({
                                    ...newTestCreate,
                                    clientID: e.value,
                                    clientName: e.label,
                                    siteID: null,
                                    site_name: null,
                                  })
                                }
                              />
                            </div>
                          )}
                        </div>
                        <div className="first-last-name-wrapper">
                          {/* <div className="form-group-wrapper">
                            <label className="text-capitalize mt-0 modalLineHeaders">Vaccination Status</label> <br />
                            {editButton ? (
                              <span className="vaccination-status pl-3">{Employee?.isVaccinated ? "Yes" : "No"}</span>
                            ) : (
                              <div className="form-group-wrapper d-flex flex-basis-1 max-w-100 pl-3 testProfilePt">
                                <div className="radioBox-wrapper">
                                  <input
                                    className="radioButton"
                                    type="radio"
                                    defaultChecked={Employee?.isVaccinated}
                                    name="isVaccinated"
                                    onChange={(e) =>
                                      setEmployee({ ...Employee, isVaccinated: e.target.checked && true })
                                    }
                                  />
                                  <span className="radioButton">Yes</span>
                                </div>
                                <div className="radioBox-wrapper">
                                  <input
                                    className="radioButton"
                                    type="radio"
                                    defaultChecked={!Employee?.isVaccinated}
                                    name="isVaccinated"
                                    onChange={(e) =>
                                      setEmployee({ ...Employee, isVaccinated: e.target.checked && false })
                                    }
                                  />
                                  <span className="radioButton">No</span>
                                </div>
                              </div>
                            )}
                          </div> */}
                          <div className="form-group-wrapper">
                            <label className="text-capitalize mt-0 modalLineHeaders">HIPAA Consent</label> <br />
                            <span className="vaccination-status pl-3">{hippaConsent ? "True" : "False"}</span>
                          </div>
                        </div>

                        {/* <div className="first-last-name-wrapper">
                          <div className="form-group-wrapper">
                            <label className="modalLineHeaders">White Glove</label>
                            {editButton ? (
                              <span className="vaccination-status pl-3">{Employee?.whiteGlove ? "True" : "False"}</span>
                            ) : (
                              <div className="d-flex pl-3 testProfilePt form-group-wrapper">
                                <span className="radioBox-wrapper">
                                  <input
                                    type="radio"
                                    className="radioButton"
                                    name="whiteGlove"
                                    checked={Employee?.whiteGlove}
                                    onChange={(e) =>
                                      setEmployee({
                                        ...Employee,
                                        whiteGlove: e.target.checked && true,
                                      })
                                    }
                                  />
                                  <span className="radioButton">Yes</span>
                                </span>
                                <span className="radioBox-wrapper">
                                  <input
                                    type="radio"
                                    className="radioButton"
                                    name="whiteGlove"
                                    checked={!Employee?.whiteGlove}
                                    onChange={(e) =>
                                      setEmployee({
                                        ...Employee,
                                        whiteGlove: e.target.checked && false,
                                      })
                                    }
                                  />
                                  <span className="radioButton">No</span>
                                </span>
                              </div>
                            )}
                          </div>

                          <div className="form-group-wrapper">
                            <label className="modalLineHeaders">Tester Designation: </label>
                            {editButton ? (
                              <span className="vaccination-status pl-3">
                                {Employee?.employeeType ? Employee?.employeeType : ""}
                              </span>
                            ) : (
                              <div className="d-flex pl-3 testProfilePt form-group-wrapper">
                                <span className="radioBox-wrapper">
                                  <input
                                    type="radio"
                                    className="radioButton"
                                    name="employeeType"
                                    checked={Employee?.employeeType == "Staff"}
                                    onChange={(e) =>
                                      setEmployee({
                                        ...Employee,
                                        employeeType: e.target.checked && "Staff",
                                      })
                                    }
                                  />
                                  <span className="radioButton">Staff</span>
                                </span>
                                <span className="radioBox-wrapper">
                                  <input
                                    type="radio"
                                    className="radioButton"
                                    name="employeeType"
                                    checked={Employee?.employeeType == "Guest"}
                                    onChange={(e) =>
                                      setEmployee({
                                        ...Employee,
                                        employeeType: e.target.checked && "Guest",
                                      })
                                    }
                                  />
                                  <span className="radioButton">Guest</span>
                                </span>
                              </div>
                            )}
                          </div>
                        </div> */}
                        <div className="first-last-name-wrapper">
                          <div className="form-group-wrapper">
                            <label className="modalLineHeaders">Result</label>
                            {editButton ? (
                              <span
                                className="vaccination-status pl-3 cursor-pointer"
                                style={{
                                  color: RESULT_COLOR[newTestCreate.result?.toLowerCase()],
                                }}
                                onClick={() => newTestCreate.result && displayPdf(newTestCreate)}
                              >
                                {newTestCreate?.result || "-"}
                              </span>
                            ) : (
                              <>
                                {CONFIG.isWhiteLabel ? (
                                  <Select
                                    className="w-100 siteSelector"
                                    options={resultTypes}
                                    blurInputOnSelect={true}
                                    defaultValue={null}
                                    menuPlacement="auto"
                                    value={
                                      newTestCreate.result
                                        ? { value: newTestCreate.result, label: newTestCreate.result }
                                        : null
                                    }
                                    placeholder="Select Result"
                                    onChange={(e) =>
                                      setTestCreate({
                                        ...newTestCreate,
                                        result: e.value,
                                        status: "Processed",
                                      })
                                    }
                                  />
                                ) : (
                                  <div className="d-flex  flex-wrap  pl-3 testProfilePt">
                                    <span className="radioBox-wrapper mb-1">
                                      <input
                                        type="radio"
                                        className="radioButton"
                                        name="result"
                                        value="Positive"
                                        checked={newTestCreate?.result == "Positive"}
                                        onChange={(e) =>
                                          setTestCreate({
                                            ...newTestCreate,
                                            result: e.target.value,
                                            status: "Pending",
                                          })
                                        }
                                      />
                                      <span className="radioButton">Positive</span>
                                    </span>
                                    <span className="radioBox-wrapper mb-1">
                                      <input
                                        type="radio"
                                        className="radioButton"
                                        name="result"
                                        value="Negative"
                                        checked={newTestCreate?.result == "Negative"}
                                        onChange={(e) =>
                                          setTestCreate({
                                            ...newTestCreate,
                                            result: e.target.value,
                                            status: "Processed",
                                          })
                                        }
                                      />
                                      <span className="radioButton">Negative</span>
                                    </span>
                                    <span className="radioBox-wrapper mb-1">
                                      <input
                                        type="radio"
                                        className="radioButton"
                                        name="result"
                                        value={UN_RESULT}
                                        checked={newTestCreate?.result == UN_RESULT}
                                        onChange={(e) =>
                                          setTestCreate({
                                            ...newTestCreate,
                                            result: e.target.value,
                                            status: "Processed",
                                          })
                                        }
                                      />
                                      <span className="radioButton">{UN_RESULT}</span>
                                    </span>
                                    <span className="radioBox-wrapper mb-1" style={{ display: "flex" }}>
                                      <input
                                        type="radio"
                                        className="radioButton"
                                        name="result"
                                        value="Inconclusive"
                                        checked={newTestCreate?.result == "Inconclusive"}
                                        onChange={(e) =>
                                          setTestCreate({
                                            ...newTestCreate,
                                            result: e.target.value,
                                            status: "Processed",
                                          })
                                        }
                                      />
                                      <span className="radioButton">Inconclusive</span>
                                    </span>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                          {appContext.user["custom:role"] === "Admins" && (
                            <>
                              {!CONFIG.isWhiteLabel && (
                                <div className="first-last-name-wrapper">
                                  <div className="form-group-wrapper">
                                    <label className="text-capitalize mt-0 modalLineHeaders">Pattern Testing</label>{" "}
                                    <br />
                                    <span className="vaccination-status pl-3">
                                      {isPatternTest() ? "True" : "False"}
                                    </span>
                                  </div>
                                </div>
                              )}
                              {isPatternTest() && (
                                <div className="form-group-wrapper">
                                  <label className="vaccination-status-name">Pattern Consent</label> <br />
                                  <span
                                    className="vaccination-status linkedText"
                                    onClick={() => {
                                      patternConsentFile();
                                    }}
                                  >
                                    Download
                                  </span>
                                </div>
                              )}
                            </>
                          )}
                        </div>

                        <div className="first-last-name-wrapper">
                          {/* {newTestCreate.result && (
                            <Form.Group className="form-group-wrapper">
                              <label className="modalLineHeaders mt-0 text-capitalize">Result Date</label>
                              <Form.Control
                                type="datetime-local"
                                readOnly={editButton}
                                placeholder="Date of Birth"
                                value={
                                  newTestCreate?.result
                                    ? newTestCreate?.resultDateTime
                                      ? moment(newTestCreate?.resultDateTime).format("YYYY-MM-DDTHH:mm")
                                      : newTestCreate?.resultDate
                                      ? moment(newTestCreate?.resultDate).format("YYYY-MM-DDTHH:mm")
                                      : moment(newTestCreate?.updatedAt).format("YYYY-MM-DDTHH:mm")
                                    : ""
                                }
                                onChange={(e) => setTestCreate({ ...newTestCreate, resultDateTime: e.target.value })}
                              />
                            </Form.Group>
                          )} */}
                        </div>

                        <div className="first-last-name-wrapper">
                          {allowToEditQuarantine && (
                            <>
                              <div className="form-group-wrapper">
                                <label className="text-capitalize mt-0 modalLineHeaders">Quarantined Start</label>{" "}
                                <br />
                                <span className="vaccination-status pl-3">
                                  {formatQuaratineDisplayDate(test?.quarantinedStart)}
                                </span>
                              </div>
                              <div className="form-group-wrapper">
                                <label className="text-capitalize mt-0 modalLineHeaders">Quarantined End</label> <br />
                                <span className="vaccination-status pl-3">
                                  {formatQuaratineDisplayDate(test?.quarantinedEnd)}
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col md="12" className="testAdmin createClientsWrapper">
                      <Form.Group className="form-group-wrapper">
                        <label className="mt-0 modalLineHeaders text-capitalize">Notes</label>
                        <textarea defaultValue="" placeholder="" className="w-100 textAreaWrapper" rows="5" />
                      </Form.Group>
                    </Col>
                  </Row> */}
                  {test && (
                    <div className="text-right w-100">
                      {error && <ErrorMessage error={error} handleChange={() => setError("")} />}
                      {location.state?.pendingRelease && test?.result !== "negative" && (
                        <Button
                          className="profileButton btn-fill createButtons"
                          variant="primary"
                          style={{
                            marginRight: 10,
                            marginTop: 10,
                          }}
                          onClick={async () => {
                            // const result = await API.releaseTest(test);
                            // if (result) {
                            //   setShowAlert(true);
                            // }
                            setModalShow(true);
                          }}
                        >
                          Release Test Results
                        </Button>
                      )}
                      {location?.state?.pendingRelease && (
                        <>
                          <Button
                            className="profileButton btn-fill createButtons"
                            style={{
                              marginRight: 10,
                              marginTop: 10,
                            }}
                            variant="secondary"
                            onClick={async () => {
                              setLoading(true);
                              if (test?.result.toLowerCase() === "positive") {
                                const result = await API.sendSMSNotification(
                                  test.phoneNumber,
                                  "Hello, can you please report back to your testing site for further information regarding your test."
                                );
                                if (result) {
                                  appContext.showSuccessMessage(
                                    "The crew member has been sent a text message to be retested."
                                  );
                                }
                                setLoading(false);
                              } else {
                                const params = [
                                  {
                                    id: test.id,
                                    phoneNumber: test.phoneNumber,
                                    email: test.email,
                                    test_type: TEST_TYPE_VALUE[test.test_type],
                                    site_name: test.site_name,
                                    firstName: Employee.firstName,
                                    siteID: test.siteID,
                                    lastName: Employee.lastName,
                                    userName: appContext.user.name,
                                    type: "invalid",
                                  },
                                ];
                                try {
                                  await GQAPI.sendSmsToCrews(params);
                                  appContext.showSuccessMessage(
                                    "The crew member has been sent a message to be retested."
                                  );
                                  setLoading(false);
                                } catch (err) {
                                  console.log(err.message);
                                  appContext.showErrorMessage(err.message);
                                  setLoading(false);
                                }
                              }
                            }}
                          >
                            Retest
                          </Button>
                          <Button
                            className="profileButton btn-fill createButtons"
                            style={{
                              marginRight: 10,
                              marginTop: 10,
                            }}
                            disabled={!test?.sequenceNo}
                            variant="primary"
                            onClick={async () => setShowPDF(true)}
                          >
                            Requisition Form
                          </Button>
                        </>
                      )}
                      {location?.state?.unprocessed && (
                        <>
                          {showSubmitButton() && (
                            <Button
                              className="profileButton btn-fill createButtons"
                              style={{
                                marginRight: 10,
                                marginTop: 10,
                              }}
                              variant="primary"
                              onClick={async () => {
                                await API.submitUnprocessedTests(
                                  [test],
                                  appContext.user,
                                  CONFIG.isLive ? "LIVE" : "DEV"
                                );
                                setShowSubmitAlert(true);
                                await appContext.resetUnprocessedTests();
                              }}
                            >
                              Submit Test
                            </Button>
                          )}

                          <Button
                            className="profileButton btn-fill createButtons"
                            style={{
                              marginRight: 10,
                              marginTop: 10,
                            }}
                            variant="secondary"
                            onClick={async () => {
                              if (test?.createdAt) {
                                setShowPDF(true);
                              }
                            }}
                          >
                            Requisition Form
                          </Button>
                        </>
                      )}
                      {location.state?.labPortal && (
                        <Button
                          className="profileButton btn-fill createButtons"
                          style={{
                            marginRight: 10,
                            marginTop: 10,
                          }}
                          variant="primary"
                          onClick={async () => {
                            if (test?.createdAt) {
                              setShowPDF(true);
                            }
                          }}
                        >
                          Requisition Form
                        </Button>
                      )}
                      {location?.state?.admin && (
                        <Button
                          className="profileButton btn-fill createButtons"
                          style={{
                            marginRight: 10,
                            marginTop: 10,
                          }}
                          variant="secondary"
                          onClick={async () => {
                            if (test?.createdAt) {
                              setShowPDF(true);
                            }
                          }}
                        >
                          Requisition Form
                        </Button>
                      )}
                      {test?.result && (
                        <Button
                          className="profileButton btn-fill createButtons"
                          style={{
                            marginRight: 10,
                            marginTop: 10,
                          }}
                          variant="primary"
                          onClick={async () => emailTest(test)}
                        >
                          Resend Results
                        </Button>
                      )}

                      {(test?.result === "Positive" || test?.result === "Fail") && allowToEditQuarantine && (
                        <Button
                          className="profileButton btn-fill createButtons"
                          style={{
                            marginRight: 10,
                            marginTop: 10,
                          }}
                          variant="secondary"
                          onClick={() => setOpenQuaratined(true)}
                        >
                          Quarantined
                        </Button>
                      )}

                      {editButton && (
                        <Button
                          className="profileButton btn-fill createButtons"
                          style={{
                            marginRight: 10,
                            marginTop: 10,
                          }}
                          variant="primary"
                          onClick={() => {
                            setEditButton(false);
                          }}
                        >
                          Edit Info
                        </Button>
                      )}
                      {!editButton && (
                        <Button
                          className="profileButton btn-fill createButtons"
                          style={{
                            marginRight: 10,
                            marginTop: 10,
                          }}
                          variant="secondary"
                          onClick={() => {
                            if (newTestCreate.barcode !== editInfo.barcode) {
                              setChangeBarcodeConfirmation(true);
                            } else {
                              handleSave();
                            }
                          }}
                        >
                          Save
                        </Button>
                      )}
                      {!editButton && (
                        <Button
                          className="profileButton btn-fill createButtons"
                          style={{
                            marginRight: 10,
                            marginTop: 10,
                          }}
                          variant="primary"
                          onClick={handleCancelButton}
                        >
                          Cancel
                        </Button>
                      )}
                    </div>
                  )}
                  <div className="clearfix" />
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        show={modalShow}
        // style={{ transform: "translate(0, -30%)" }}
        animation={true}
        onHide={() => setModalShow(false)}
        centered
        size={"md"}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Release Test Results</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ paddingTop: 0, marginRight: "auto", marginLeft: "auto" }}>
          <p>Select all that apply: </p>
          <div className="checkboxRow">
            <label className="checkboxLabel">
              <input
                disabled={!siteContactPhone}
                value="site"
                onChange={(e) => {
                  if (e.target.checked) {
                    setReleaseTo({
                      ...releaseTo,
                      [e.target.value]: siteContactPhone,
                    });
                  } else {
                    const newReleaseTo = releaseTo;
                    delete newReleaseTo[e.target.value];
                    setReleaseTo(newReleaseTo);
                  }
                }}
                className="releaseCheckbox"
                type="checkbox"
              />
              Health and Safety Officer
            </label>
            <label>
              <input
                value="crew"
                onChange={(e) => {
                  if (e.target.checked) {
                    setReleaseTo({
                      ...releaseTo,
                      [e.target.value]: setPhoneNo(test?.phoneNumber),
                    });
                  } else {
                    const newReleaseTo = releaseTo;
                    delete newReleaseTo[e.target.value];
                    setReleaseTo(newReleaseTo);
                  }
                }}
                className="releaseCheckbox"
                type="checkbox"
              />
              Crew Member
            </label>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button className="modalButtons" variant="secondary" onClick={() => setModalShow(false)}>
            Cancel
          </Button>
          <Button
            className="modalButtons"
            variant="primary"
            onClick={async () => {
              if (releaseTo.length === 0) return;
              setLoading(true);
              const params = [];
              params.push({
                id: test.id,
                status: "Processed",
                timerStatus: "Processed",
                sequenceNo: test.sequenceNo,
                invalidResultStatus: 1,
              });
              const ids = [{ id: test.id, sequenceNo: test.sequenceNo }];
              await GQAPI.releaseTest(params);
              if (releaseTo.crew) {
                await GQAPI.sendEmailToPendingRelease(params);
              }

              const logs = {
                ids: ids,
                userID: appContext.user?.sub,
                userName: appContext.user?.name,
                slug: test.result.toLowerCase() !== "positive" ? "Inconclusive Release" : "Pending Release",
              };

              await GQAPI.addTestLogs(logs);

              releaseTo.site &&
                (await API.sendSMSNotification(
                  releaseTo.site,
                  `Hello, there was a positive (${TEST_TYPE_VALUE[test.test_type]}) test result for ${
                    site.name
                  }. please login to your Health & Safety Portal to review.`
                ));

              await appContext.resetPendingTests([test.id]);
              props.history.push("/admin/pendingrelease");
              appContext.showSuccessMessage(`Success! You have released test`);
              setLoading(false);
            }}
          >
            Release Results
          </Button>
        </Modal.Footer>
      </Modal>
      {openQuarantined && (
        <QuarantineModal test={test} handleSave={handleSaveQuarantine} handleClose={() => setOpenQuaratined(false)} />
      )}

      {/* {openTestDetail && (
        // <EditTestDetailsModal
        //   testDetails={{
        //     ...test,
        //     createdAt: moment(test.createdAt).format("YYYY-MM-DDTHH:mm"),
        //     employee_demographics: getDemoGraphics(test),
        //   }}
        //   users={users}
        //   handleSave={handleSaveTestDetail}
        //   handleClose={() => handleCloseTestDetail(false)}
        // />
      )} */}

      {openCreator && (
        <TestModal
          newTestCreate={newTestCreate}
          handleCloseCreateTest={handleCloseCreateTest}
          createTest={updateTest}
          setTestCreate={setTestCreate}
          clients={appContext.clients}
          sites={appContext.sites}
          labs={appContext.labs}
          isNew={false}
          users={users}
        />
      )}

      {openDataChangeConfirmation && (
        <ConfirmationModal
          show={openDataChangeConfirmation}
          title="Test Update Alert"
          message={`New Changes occures, are you want to update your edit info!`}
          handleConfirm={handleDataChangeConfirm}
        />
      )}
      {changeBarcodeConfirmation && (
        <ConfirmationModal
          show={changeBarcodeConfirmation}
          title={`Change ${stringConstant.barcode} Confirmation`}
          message={`Are you sure you want to edit the ${stringConstant.barcode}. from ${editInfo.barcode} to ${newTestCreate.barcode} for this test?`}
          handleConfirm={handleChangeBarcodeNo}
          isPreRegister={true}
          handleClose={() => setChangeBarcodeConfirmation(false)}
        />
      )}
    </>
  ) : (
    <Loader />
  );
}

export default Test;
