import React, { useState, useMemo } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import { ReactSelectCustomContainer } from "components/CustomReactSelect/ReactSelectCustomOptionContainer";
// import { CANCEL_REASON_OPTIONS } from "constant";
import "../../../src/tableStyling.css";
import { useSelector } from "react-redux";
import { generalSettings } from "store/features/testDictionary/testDictionarySelectors";

const ConfirmationModal = (props) => {
  const {
    title,
    message,
    handleConfirm,
    isPreRegister,
    handleClose,
    cssClass,
    note,
    selectedOptions,
    options,
    handleOptions,
    isSelectOptions,
    reasonObj = { cancelTestData: { actionType: "Default" } },
  } = props;
  const [value, setValue] = useState("");

  const generalSettingsData = useSelector(generalSettings);

  const CANCEL_REASON_OPTIONS = useMemo(() => {
    return generalSettingsData.filter((item) => item.settingType === "cancelled");
  }, [generalSettingsData]);
  
  return (
    <Modal
      show
      // style={{ transform: "translate(0, -30%)" }}
      animation={true}
      onHide={() => {
        handleClose ? handleClose(false) : handleConfirm(false);
      }}
      centered
      size={"md"}
      className="second-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 modal-title h4" id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0, marginRight: "auto", marginLeft: "auto" }}>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {reasonObj?.cancelTestData?.message || message}
        </Modal.Title>
        {note && note.label && (
          <div className="w-100 mt-2">
            <label className="profile-title">{note.label}</label>
            <div className="mb-3">
              <textarea
                className="form-control reason-textarea w-100"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              ></textarea>
            </div>
          </div>
        )}

        {reasonObj?.cancelTestData?.actionType === "Cancelled" && (
          <div className="form-group-wrapper">
            <label className="modalLineHeaders">Select Reason: </label>
            <Select
              options={CANCEL_REASON_OPTIONS}
              blurInputOnSelect={true}
              menuPlacement="auto"
              className="w-100 siteSelector site-select-test"
              placeholder="Select"
              value={selectedOptions}
              onChange={(e) =>
                reasonObj.setCancelTestsData({
                  ...reasonObj?.cancelTestData,
                  preDefineReason: `${e.label}: ${e.title}`,
                })
              }
              components={{ ValueContainer: ReactSelectCustomContainer }}
            />
          </div>
        )}

        {(reasonObj?.cancelTestData?.actionType === "Cancelled" ||
          reasonObj.cancelTestData.actionType === "Cancel") && (
          <div className="w-100">
            <div className="mb-3">
              <label className="profile-title">Please add note below</label>
              <textarea
                className="form-control reason-textarea w-100"
                value={reasonObj?.cancelTestData.reason}
                onChange={(e) => reasonObj.setCancelTestsData({ ...reasonObj?.cancelTestData, reason: e.target.value })}
              ></textarea>
            </div>
          </div>
        )}

        {isSelectOptions && (
          <Select
            options={options}
            blurInputOnSelect={true}
            menuPlacement="auto"
            className="w-100 siteSelector site-select-test"
            placeholder="Select Source"
            value={selectedOptions}
            onChange={(e) => handleOptions(e)}
            components={{ ValueContainer: ReactSelectCustomContainer }}
          />
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          className="modalButtons"
          variant="secondary"
          onClick={() => {
            handleConfirm(false);
          }}
        >
          {isPreRegister ? "No" : "Go back"}
        </Button>
        <Button
          className="modalButtons"
          variant="primary"
          onClick={() => {
            handleConfirm(true, value);
          }}
        >
          {isPreRegister ? "Yes" : " Confirm"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
