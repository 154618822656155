import React from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { InputGroup } from "react-bootstrap";

const renderFilters = ({
  categories,
  triggerFilter,
  setFilter,
  filterContent,
  isGrouped,
  filterTerms,
  setFilterTabIndex,
  filterTabIndex,
}) => {
  if (isGrouped) {
    return (
      <Tabs className="custom-filter-tabs" selectedIndex={filterTabIndex} onSelect={(index) => setFilterTabIndex(index)}>
        <TabList>
          {Object.keys(categories).map((category) => (
            <Tab key={category}>{category}</Tab>
          ))}
        </TabList>
        {Object.entries(categories).map(([category, filters]) => (
          <TabPanel key={category}>
            <div className="mt-t custom-filters-wrapper" style={{ background: "#fff" }}>
              {filters.map((filterTerm) => (
                <InputGroup key={filterTerm} className="mb-3">
                  {triggerFilter && (setFilter((prev) => ({ ...prev, [filterTerm]: "" })), setFilter(false))}
                  <InputGroup.Text style={{ width: "175px" }}>{filterTerm}</InputGroup.Text>
                  {filterContent(filterTerm)}
                </InputGroup>
              ))}
            </div>
          </TabPanel>
        ))}
      </Tabs>
    );
  }

  return (
    <div className="mt-t custom-filters-wrapper">
      {filterTerms.map((filterTerm) => (
        <InputGroup key={filterTerm} className="mb-3">
          {triggerFilter && (setFilter((prev) => ({ ...prev, [filterTerm]: "" })), setFilter(false))}
          <InputGroup.Text style={{ width: "175px" }}>{filterTerm}</InputGroup.Text>
          {filterContent(filterTerm)}
        </InputGroup>
      ))}
    </div>
  );
};

export default renderFilters;
