import { MESSAGE_MODES } from "constant";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { CSVReader } from "react-papaparse";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { setMessage } from "store/features/general/generalAction";
import { userCompanyID } from "utils";

const LoadTestPanels = (props) => {
  const { handleClose, handleImport, handleDownloadSample } = props;
  const dispatch = useDispatch();

  const handleOnDrop = (data) => {
    handleImport(data);
  };
  const handleOnError = (err) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    console.log("Remove Data", data);
  };

  return (
    <Modal show animation={true} backdrop="static" onHide={() => handleClose()} centered size={"lg"}>
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Load Panel Elements
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="form-group-wrapper d-block">
          <div className="d-flex align-items-center justify-content-around mb-3">
            {/* <div
              style={{
                textAlign: "right",
                color: "#42cef5",
                textAlign: "center",
                cursor: "Pointer",
              }}
              onClick={() => handleDownloadSample(1)}
            >
              Download Template Info
            </div> */}
            <div
              style={{
                textAlign: "right",
                color: "#42cef5",
                textAlign: "center",
                cursor: "Pointer",
              }}
              onClick={() => handleDownloadSample(2)}
            >
              Download Upload Template
            </div>
          </div>
          <CSVReader
            onDrop={handleOnDrop}
            onError={handleOnError}
            addRemoveButton
            config={{
              header: true,
              skipEmptyLines: true,
            }}
            onRemoveFile={handleOnRemoveFile}
          >
            <span>Drop CSV file here or click to upload.</span>
          </CSVReader>
        </div>
      </Modal.Body>

      <Modal.Footer className="flex-row-reverse">
        <Button variant="secondary" className="headerButton btn-fill" onClick={() => handleClose()}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LoadTestPanels;
