import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import UserPermissions from "./userPermissions";
import { PERMISSIONS_LIST, DEFAULT_WHITE_LABEL_USRERS_PERMISSIONS } from "constant";
import Loader from "components/Loader/Loader";

const CreateUserRolesOptions = ({
  handleClose,
  title,
  label,
  handleSaveChange,
  selectedValue,
  editObj,
  loading = false,
  disabled = false,
}) => {
  const [option, setOption] = useState("");

  const [permission, setPermission] = useState(DEFAULT_WHITE_LABEL_USRERS_PERMISSIONS);

  useEffect(() => {
    if (selectedValue) {
      setOption(selectedValue);
    }
  }, [selectedValue]);

  useEffect(() => {
    if (editObj) {
      setOption(editObj?.label || "");
      let newPermission = JSON.parse(editObj.code);
      setPermission(newPermission);
    }
  }, [editObj]);

  const handleSaveOptions = () => {
    if (option) {
      handleSaveChange({ option, permission });
    }
  };

  return (
    <Modal
      show
      animation={true}
      onHide={() => handleClose()}
      backdrop="static"
      centered
      size={"lg"}
      keyboard={true}
      className="second-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0}}>
        <div className="row mb-3">
          <div className="col-lg-12 col-12 mb-3">
            <label className="modalLineHeaders mt-0">{label}:</label>
            <input
              value={option}
              className="modalInput"
              placeholder={"Create User Role"}
              style={{
                width: "100%",
              }}
              onChange={(e) => setOption(e.target.value)}
              disabled={!!editObj}
            />
          </div>
        </div>

        {loading && <Loader />}

        <div className="row mb-3 permissionBoxRoles">
          <div className="manage-permissions-heading">Manage Permissions</div>
          <div className="cool-scrollbar">
            {PERMISSIONS_LIST.map((m) => (
              <UserPermissions
                setPermission={setPermission}
                permission={permission}
                permissionData={m}
                value={m.value}
                key={m.value}
                label={m.label}
                isDelete={m.delete}
                isWrite={m.write}
                disabled={m.disabled && disabled}
              />
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="headerButton" onClick={() => handleClose()}>
          Close
        </Button>
        <Button variant="primary" className="modalButtons" onClick={handleSaveOptions}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateUserRolesOptions;
