import { stringConstant } from "constant";

export const filterMapping = {
  // Demographic Information
  "First Name": "Demographic",
  "Last Name": "Demographic",
  "FirstName": "Demographic",
  "LastName": "Demographic",
  "Date of Birth": "Demographic",
  "Gender": "Demographic",
  "Email": "Demographic",
  "Phone": "Demographic",
  "ID Number": "Demographic",
  "City": "Demographic",
  "Street": "Demographic",
  "Vaccinated": "Demographic",
  "isVaccinated": "Demographic",

  // Contact Information
  "Contact Name": "Contact Information",
  "Primary Contact": "Contact Information",
  "Contact Phone": "Contact Information",
  "Contact Email": "Contact Information",

  // Test Information
  "Test Type": "Test Information",
  "Tester": "Test Information",
  "Test Status": "Test Information",
  "Status": "Test Information",
  "Result": "Test Information",
  "Associated": "Test Information",
  "Quarantined": "Test Information",
  "Result Sent": "Test Information",
  "External Test": "Test Information",
  "External Test Admit": "Test Information",
  "Pattern Testing": "Test Information",
  "Automate Rapid Test": "Test Information",
  "Antigen": "Test Information",
  "Molecular": "Test Information",
  "Accula": "Test Information",
  "BarCodes": "Test Information",
  "Result Type": "Test Information",
  "White Label": "Test Information",
  "False Positive": "Test Information",
  "Status Step": "Test Information",
  "Show Status": "Test Information",
  "Ord Status": "Test Information",
  "White Glove": "Test Information",
  "Pattern": "Test Information",
  "Transition Status": "Test Information",

  // Administrative Details
  "Accession No": "Administrative",
  "Barcode": "Administrative",
  "Role": "Administrative",
  "ReferenceID": "Administrative",
  "Reason": "Administrative",
  "Pre-Registration": "Administrative",
  "HR Registration": "Administrative",
  "Print Labels": "Administrative",
  "Send Quantity": "Administrative",
  "Order Status": "Administrative",
  "Eligibility Status": "Administrative",
  "Capcity": "Administrative",
  "Capacity": "Administrative",
  "Cancelled Reason": "Administrative",
  "Date Created": "Administrative",
  "Date End": "Administrative",
  "Submitted to H&S": "Administrative",
  "Shipment": "Administrative",
  "Eligibility": "Administrative",
  "Last Updated": "Administrative",

  // Medical Details
  "Referring Physician": "Medical Details",
  "NPI": "Medical Details",
  "Insurance Company": "Medical Details",
  "Custody Status": "Medical Details",

  // Insurance Details
  "Insurance Info": "Insurance Details",
  "Insurance Card": "Insurance Details",
  "Vaccination Card": "Insurance Details",
  "Vaccination Admit": "Insurance Details",
  "Send Insurance": "Insurance Details",
  "Vaccination Type": "Insurance Details",
  "Payer": "Insurance Details",
  "Primary Payer": "Insurance Details",
  "Additional Payer": "Insurance Details",
  "Additional Payer Number": "Insurance Details",
  "Additional Member ID": "Insurance Details",
  "Primary Payer Number": "Insurance Details",
  "Member ID": "Insurance Details",
  "Ins Name": "Insurance Details",
  "Ins Alias": "Insurance Details",
  "Electronic Claims": "Insurance Details",
  "Electronic Eligibility": "Insurance Details",
  "Electronic ERA": "Insurance Details",

  // User Details
  "Crew ID": "User Details",
  "Crew Id": "User Details",
  "Name": "User Details",
  "Panel Name": "User Details",
  "Arkstone Status": "User Details",
  "Release Status": "User Details",

  // Others
  "Note": "Others",

  // Dynamic Keys (Assuming string constants are defined elsewhere)
  [stringConstant.sequenceNo]: "Administrative",
  [stringConstant.show]: "Administrative",
};



